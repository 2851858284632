import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import PropTypes from "prop-types";

class BomDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bom_obj_arr : [],
            bom_del_array: [],
            quantity: [],
        }
        this.checkAvailability = this.checkAvailability.bind(this)
    }  
    deleteBom = (idx,idx2) =>() =>{
        if (typeof this.props.deleteBom === 'function') {
            this.props.deleteBom(idx,idx2)
        }
    }
    checkAvailability= (item,idx) => e =>{
        axios({
            method: 'post',
            url: Url+'/material/checkMaterial.php',
            headers: { 'content-type': 'application/json' }, 
            data: item.msku
        })
        .then(result => {
            var obj = [... this.state.quantity]
            obj[idx] = {
                quantity : result.data
            }
            this.setState({
                quantity : obj
            })
        })
    }
    render(){
        return(
            <div className="p-3" id="bom-detail">
                <Table className="table table-hover mt-3">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td><strong>MSKU</strong></td>
                            <td><strong>Title</strong></td>
                            <td><strong>Rate</strong></td>
                            <td><strong>Usage Quantity</strong></td>
                            <td><strong>Cost</strong></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.bomDetailsArray.length > 0 ? this.props.bomDetailsArray.map((item, idx) => (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td>{item.msku}</td>
                                    <td>{item.material}</td>
                                    <td>{item.rate}</td>
                                    <td>{item.usage}</td>
                                    <td>{item.amount}</td>
                                    <td>{ this.state.quantity[idx] !== undefined ?  <div>{this.state.quantity[idx].quantity}<a href="/home/material"> Purchase</a></div> : <button className="btn btn-outline-info btn-sm" onClick={this.checkAvailability(item,idx)}>Check availability</button>}</td>
                                    <td><button className="btn btn-outline-danger btn-sm"  onClick={this.deleteBom(idx, item.bom_id)} >
                                            X </button>
                                    </td>
                                </tr>
                            )) : null
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}
BomDetail.propTypes = {
    deleteBom : PropTypes.func,
}
export default BomDetail