import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Label, Input, Col, Button } from 'reactstrap';
import axios from 'axios';
import {Url} from './UrlComponent';

class Settings extends Component{
    constructor(props) {
        super(props);
        this.state = { 
              collapsed: false,
              new_password: '',
              old_password: '',
              confirm_password: '',
              error_msg: '',
              showNewPasswordField: false,
              success_msg : '',
        }
        this.toggleNavbar = this.toggleNavbar.bind(this)
        this.changePassword = this.changePassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.savePassword = this.savePassword.bind(this)
    }
    toggleNavbar() {
        this.setState({
          collapsed: !this.state.collapsed
        })
    }
    changePassword = (e)=>{
        const {name, value} = e.target
        this.setState({
            [name] : value
        })
    }
    handleSubmit(){
        alert('hi')
        if(this.state.old_password === this.state.confirm_password){
            const form_data = {
                "email" : sessionStorage.getItem('email'),
                "password" : this.state.old_password,
                "type" : 'check'
            }
            axios({
                method: 'post',
                url: Url+'/settings/changePassword.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
            }).then(res=>{
                if(res.data === 'confirmed'){
                    this.setState({
                        new_password : '',
                        confirm_password : '',
                    })
                }
                else{
                    this.setState({
                        error_msg : 'Invalid password!'
                    })
                }
            })
        }
        else{
            this.setState({
                error_msg: 'Password does not match!',
            })
        }
    }
    savePassword = () => {
        if(this.state.new_password === this.state.confirm_password){
            const form_data = {
                "email" : sessionStorage.getItem('email'),
                "password" : this.state.new_password,
                "type" : 'change'
            }
            axios({
                method: 'post',
                url: Url+'/settings/changePassword.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
            }).then(res=>{
                if(res.data === 'success'){
                    this.setState({
                        success_msg : "Password changed"
                    })
                }
                else{
                    this.setState({
                        error_msg : 'Invalid password!'
                    })
                }
            })
        }
        else{
            this.setState({
                error_msg : 'Password does not match!'
            })
        }
    }
    render(){
        const NewPassword = ({changePassword, new_password, confirm_password, savePassword}) => {
            return(
                <div className="col-12 col-sm-7 offset-sm-3 mt-5">
                    <Col md={8}>
                        <Label htmlFor="password" >New Password:</Label>
                        <Input type="password" id="new_pwd" name="new_password" value={new_password} onChange={changePassword} />
                    </Col>
                    <Col md={8}>
                        <Label htmlFor="password" >Confirm Password:</Label>
                        <Input type="password" id="new_pwd" name="confirm_password" value={confirm_password} onChange={changePassword} />
                    </Col>
                    <Col>
                        {this.state.error_msg ? <p id="error_msg">{this.state.error_msg}</p> : null}
                        {this.state.success_msg ? <p id="success_msg">{this.state.success_msg}</p> : null}
                    </Col>
                    <Col>
                        <Button type="button" color="primary" className="mt-3" onClick={savePassword}>Save </Button>
                    </Col>
                </div>
            )
        }
        const ChangePassword = () =>{
            return(
                <div className="col-12 col-sm-7 offset-sm-3 mt-5">
                    <Col md={8}>
                        <Label htmlFor="password">Old Password:</Label>
                        <Input type="password" id="old_pwd" name="old_password" value={this.props.old_password} onChange={this.props.changePassword} />
                    </Col>
                    <Col md={8}>
                        <Label htmlFor="password">Confirm Password:</Label>
                        <Input type="password" id="old_pwd" name="confirm_password" value={this.props.confirm_password} onChange={this.props.changePassword} />
                    </Col>
                    <Col>
                        {this.props.error_msg ? <p id="error_msg">{this.props.error_msg}</p> : null}
                    </Col>
                    <Col>
                        <Button type="button" color="primary" className="mt-3" onClick={()=>this.props.handleSubmit}>Verfiy </Button>
                    </Col>
                </div> 
            )
        }
        const RenderTab = () =>{
            if(this.props.code == 'change-password')
                return(this.state.showNewPasswordField ? <NewPassword changePassword={this.changePassword} new_password={this.state.new_password} 
                    confirm_password={this.state.confirm_password} savePassword={this.savePassword} /> :<ChangePassword changePassword={this.changePassword} old_password={this.state.old_password} 
                    confirm_password={this.state.confirm_password} handleSubmit={this.handleSubmit} />)
            else
                return(null)
        }
        return (
            <div className='row' id="settings-div">
                <div className='col-12 col-sm-2 mt-3' id="settings-left">
                    <Navbar color="faded" light>
                          <NavbarBrand href="/" className="mr-auto"><strong>Settings</strong></NavbarBrand>
                          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                            <Collapse isOpen={!this.state.collapsed} navbar>
                                <Nav navbar>
                                    <NavItem>
                                        <NavLink href={`/home/settings/change-password`}>Change Password</NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                    </Navbar>
                </div>
                <div className='col-12 col-sm-10 pt-3' id="all-materials-right">
                    <RenderTab />
                </div> 
            </div>
        );
    }
}


export default Settings; 