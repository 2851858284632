import React, { Component } from 'react';
import axios from "axios";
import {Url} from './UrlComponent';
import { Button } from 'reactstrap';

class AllVendors extends Component {
    constructor(props){
       super(props)
       this.state = {
           
       }
    }
  handleInput = (event) => {
      this.props.handleInput(event.target)
  }
  
  addVendor=() =>{
     this.props.addVendor()
  }
  openEditor = () =>{
    this.props.openEditor()
  }
  
  render(){
    const {vendors} = this.props
    const ShowAllVendors = () =>{
        return(<div className="product-divs">
        <div className="row product-row">
            <div className="row product_div_heading" style={{marginLeft:'17px'}}>
              <div className= "col-sm p-3 product-cell"><strong>Vendor Name</strong></div>
              <div className= "col-sm p-3 product-cell"><strong>Vendor Type</strong></div>
              <div className= "col-sm p-3 product-cell"><strong>Vendor Work</strong></div>
              <div className= "col-sm p-3 product-cell"><strong>Contact Person</strong></div>
              <div className= "col-sm p-3 product-cell"><strong>Mobile No.</strong></div>
              <div className= "col-sm p-3 product-cell"><strong>Whatsapp No.</strong></div>
              <div className= "col-sm p-3 product-cell"><strong>Location</strong></div>
            </div>
        </div>
            {vendors.map((item, key) =>{
              return (
                <div key={key} className="row product-row-data">
                          <div className="row product_div pr-3" style={{marginLeft:'13px'}}>
                            <div className="col-sm p-2 product-cell">{item.vendor_name}</div>
                            <div className="col-sm p-2 product-cell">{item.selected_vendor_type}</div> 
                            <div className="col-sm p-2 product-cell">{item.selected_vendor_work}</div>
                            <div className="col-sm p-2 product-cell">{item.contact_person} </div>  
                            <div className="col-sm p-2 product-cell">{item.mobile_no}</div>
                            <div className="col-sm p-2 product-cell">{item.whatsapp_no}</div>
                            <div className="col-sm p-2 product-cell">{item.vendor_loc}</div>
                          </div>
                    </div>
              )
          })}
      </div>
    ) 
    }
    return(
      <div>
          <h5><span className="count-display">Vendors</span> &nbsp;
              <span className="show-count-span">&nbsp; {vendors.length} results</span>
              <span><Button className="new-product-btn" id="new-product-btn" onClick={this.addVendor}><i class="fa fa-plus" aria-hidden="true"></i> Add</Button></span>
          </h5>
          <div className="container-fluid mt-3" id="all-row-container">
            <div className="row pl-2 product-button-div">
                  <div className="col-12 col-sm-4 p-2">
                      <Button className="bulk-editor ml-2" onClick={this.openEditor}> <i class="fa fa-edit"></i>Bulk Editor</Button>
                  </div>
                  <div className="col-12 col-sm-3 p-2">
                      <div className="form-group has-search">
                          <span className="fa fa-search form-control-feedback"></span>
                          <input className="form-control" type="text" placeholder="Search by vendor name.." id="search-bar" name="searchItem" value={this.props.searchItem} onChange={this.handleInput}/>
                      </div>
                      
                  </div>
                  <div className="col-12 offset-sm-2 col-sm-3 p-2" style={{textAlign:'right'}}>
                      
                  </div>
              </div>
              <ShowAllVendors />
          </div>
      </div>
    )
  }
}

export default AllVendors; 