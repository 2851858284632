import React, { Component } from 'react';
import {Card, CardImg} from 'reactstrap';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import {Url} from './UrlComponent';
import PropTypes from "prop-types";

const imageMaxSize = 1000000000 //bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item)=> {return item.trim()})
var temp = []
var image = []
var path = Url+'/product/'
class Image extends Component{
    constructor(props) {
        super(props)
        this.state = {
            image : null,
            isDragActive: false,
            color_array:[],
        }
        this.handleOnDrop = this.handleOnDrop.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    
    handleOnDrop = (acceptedFiles, rejectedFiles) => {
        if(rejectedFiles && rejectedFiles.length > 0){
          this.verifyFile(rejectedFiles)
        }
        if(acceptedFiles && acceptedFiles.length > 0){
           const isVerified = this.verifyFile(acceptedFiles)
           if(isVerified){
              // imageBase64Data
              const currentFile = acceptedFiles[0]
              image.push(acceptedFiles[0])
              this.setState({
                 image 
              })
              const myFileItemReader = new FileReader()
              var img_array = [...this.props.img_array]
              myFileItemReader.addEventListener("load", ()=>{
                  img_array.push({
                    imgSrc: myFileItemReader.result,
                    imgName: currentFile.name,
                  })
                    if (typeof this.props.getImage === 'function') {
                        this.props.getImage(img_array, image)
                    }
               
              }, false)
              myFileItemReader.readAsDataURL(currentFile)
              
           }
        }
    }
    
    handleClose= (idx,idx2) =>() =>{
        if (typeof this.props.deleteImage === 'function') {
            this.props.deleteImage(idx,idx2)
        }
    }
    verifyFile = (files) => {
        if(files && files.length > 0){
          const currentFile = files[0]
          const currentFileType = currentFile.type
          const currentFileSize = currentFile.size
          if(currentFileSize > imageMaxSize){
            alert("This file is not allowed. " + currentFileSize + " bytes is too large")
          }
          if(!acceptedFileTypesArray.includes(currentFileType)){
            alert("This file is not allowed. Only images are allowed.")
            return false
          }
          return true
        }
    }
    
    render(){
        const img_array = this.props.img_array
        return(
            <div id="images" className="pt-1">
              <h4>Images</h4>
                    <div className="row mt-3">
                        { img_array.length > 0 ? img_array.map((image,key)=>{
                            return(<div className="col-12 col-sm-3 m-3">
                                <button type="button" className="close-btn-img" aria-label="Close" onClick={this.handleClose(key,image.id)}>X</button>
                                <Card className="image-card">
                                    {/* <input type="checkbox"/> */}
                                    <CardImg top width="100%" height="100%" src={image.imgSrc} alt="Card image cap" />
                                </Card>
                            </div>)
                        }) : null }
                        <div className="col-12 col-sm-3 image-div m-3">
                            <Dropzone onDrop={this.handleOnDrop} accept={acceptedFileTypes} maxSize={imageMaxSize}>
                                {({getRootProps, getInputProps, isDragActive}) => {
                                        return (
                                            <div
                                                {...getRootProps()}
                                                className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                                >
                                                <input {...getInputProps()} name="file[]" />
                                                {
                                                    isDragActive ?
                                                    '':
                                                    <p>+</p>
                                                }
                                            </div>
                                        )
                                }}
                            </Dropzone>
                        </div>
                       
                    </div>
            </div>
        );
    }
}
Image.propTypes = {
    img_array : PropTypes.array,
    deleteImage: PropTypes.func,
    getImage: PropTypes.func,
  }
export default Image;