import React , { Component } from 'react';
// import Analytics from './AnalyticsComponent'

class FirstPage extends Component {
    render() {
        return (
            <div>
                {/* <Analytics /> */}
            </div>
        );
    }
}

export default FirstPage;