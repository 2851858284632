import React, { Component } from 'react';
import { Button, Table, Input} from 'reactstrap';
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";
import axios from "axios";
import {Url} from './UrlComponent';

class EditMaterial extends Component {
    constructor(props) {
      super(props);
      this.state = {
         vendor_arr : this.props.vendor_arr,
         materials : this.props.materials,
         material_map : new Map(),
         material_size_array: [],
         size_bool: true, 
         searchItem : ''
      }
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.filterList = this.filterList.bind(this)
      this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this)
    }
    handleInputChange= (item,idx) => (event) =>{
        const {name, value} = event.target
        if(name === 'material'){
            axios.post(Url+'/material/read_material_size.php',{"material" : value}).then(res => {
                this.setState({ 
                    material_size_array:res.data['sizes']
                    })
                })
        }
        var materials = [...this.state.materials]
        var arr=this.state.material_map
        var map1 = new Map()
        if(arr.has(idx)){
            map1= arr.get(idx)
        }
        map1.set("msku",item.msku)
        Object.keys(materials[idx]).forEach(function (item) {
            if(item === name && name === 'material'){
                materials[idx][item] = value
                map1.set(name,value)
                map1.set('material_size',materials[idx]['material_size'])
                map1.set('unit',materials[idx]['unit'])
            }
            else if(item === name && name === 'material_size'){
                materials[idx][item] = value
                map1.set(name,value)
                map1.set('material',materials[idx]['material'])
                map1.set('unit',materials[idx]['unit'])
            }
            else if(item === name){
                materials[idx][item] = value
                map1.set(name,value)
            }
        })
        this.setState({
            materials
        }) 

        arr.set(idx,map1)
        this.setState({
            material_map : arr,
        })
    }
    filterList(event){
        var arr = [...JSON.parse(this.baseState)]
        arr = arr.filter(function(item){
        return item.msku.toLowerCase().search(
            event.target.value.toLowerCase()) !== -1;
        })
        this.setState({materials: arr})
    }
    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log(fromRow)
        var keyName = Object.keys(updated)
        var keyValue = Object.values(updated)
        // console.log(keyNames)
        const materials = this.props.materials.slice()
        var arr=this.state.material_map
        var map1 = new Map()
        this.setState(state => {
          for (let i = fromRow; i <= toRow; i++) {
            materials[i] = { ...materials[i], ...updated }
            
            if(arr.has(i)){
                map1= arr.get(i)
            }
            map1.set("msku",materials[i].msku)
            map1.set(keyName[0],keyValue[0])
            arr.set(i,map1)
          }
          
          return { materials }
        },()=>{
            this.setState({
                material_map : arr,
              })
        })
    }
    handleSubmit(event) {
        var materials_arr = []
        if(this.state.material_map.size > 0){
            for (let [key, value] of this.state.material_map){
                materials_arr.push({
                    msku : value.get("msku"),
                    title : value.get("title") ? value.get("title") : '',
                    description : value.get("description") ? value.get("description"):'',
                    material : value.get("material") ? value.get("material"):'',
                    color : value.get("color") ? value.get("color"):'',
                    rate : value.get("rate") ? parseInt(value.get("rate")):0,
                })
            }
            const form_data = {
                "materials_arr" : materials_arr,
            }
            console.log(form_data)
            axios({
                method: 'post',
                url: Url+'/material/updateMaterial.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
              }).then(res =>{
                  alert(res.data)
                  if(res.data === 'Updated'){
                      this.setState({
                          material_map : new Map()
                      })
                      axios.get(Url+'/material/read_all_materials.php').then(result => {
                        var i;
                        var arr = new Array();
                        for(i=0; i<result.data.length; i++){  // iterate over response and assign to array                 
                              arr.push({
                                  msku:result.data[i].msku,
                                  title:result.data[i].title,
                                  description:result.data[i].description,
                                  material:result.data[i].material,
                                  material_size:result.data[i].material_size,
                                  unit:result.data[i].unit,
                                  color:result.data[i].color,
                                  rate:result.data[i].rate,
                                  opening_quantity:result.data[i].opening_quantity,
                                  quantity:result.data[i].quantity,
                                  lifetime_quantity:result.data[i].lifetime_quantity,
                              })
                         }
                      //set state of object array 
                      this.setState({ 
                      materials: arr,
                      })
                    })
                  }
              })
        }
        else{
            alert("No changes detected!")
        }
    }
    goBack=()=>{
        this.props.showAll()
    }
    render(){
        const {materials} = this.props
        const defaultColumnProperties = {
            resizable: true,
            width: 120
        }
        const { DropDownEditor } = Editors;
        const issueTypes = [...this.props.vendor_arr]
        const IssueTypeEditor = <DropDownEditor options={issueTypes} />
        const columns = [
            { key: "msku", name: "MSKU", editable: false },
            { key: "title", name: "Title", editable: true },
            { key: "description", name: "Description", editable: true },
            { key: "width", name: "Width", editable: true },
            { key: "color", name: "Color", editable: true },
            { key: "rate", name: "Rate", editable: true },
            { key: "opening_quantity", name: "Opening Quantity", editable: false },
            { key: "quantity", name: "Quantity", editable: false },
            { key: "vendor_name", name: "Vendor Name", editable: true, editor: IssueTypeEditor, isExpanded : true },
            { key: "lifetime_quantity", name: "Lifetime Quantity", editable: false },
          ].map(c => ({ ...c, ...defaultColumnProperties }))
          
        return(
           <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-3">
                      <span className="count-display">Materials</span> &nbsp;
                      <span className="show-count-span">&nbsp; {materials.length} results</span>
                  </div>
                  <div className="col-12 col-sm-4">
                      <input className="form-control" type="text" placeholder="Search by materials name.." id="search-bar" name="searchItem"
                        value={this.state.searchItem} onChange={this.filterList}/>
                    </div>
                  <div className="col-12 offset-sm-2 col-sm-3 ">
                      <Button type="button" className="ml-2 edit-btns bulk-editor" onClick={this.handleSubmit}> Save</Button>
                      <Button type="button" className="ml-2 edit-btns back"  onClick={this.goBack}> Back</Button>
                  </div>               
              </div>
                <div className="row mt-2" style={{height : '80vh'}}>
                <ReactDataGrid columns={columns}  rowGetter={i => materials[i]}  rowsCount={ materials.length} 
                    enableCellSelect={true} minHeight={500} onColumnResize onGridRowsUpdated={this.onGridRowsUpdated} />
                </div>
           </div>
        );
      }
    }
export default EditMaterial; 