import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";

class Process extends Component {
  constructor(props) {
    super(props)
    this.state = { 
        sku: [],
        process: [],
        vendors: [],
        vendor_name:'',
        cost:'',
        process_row: [{}],
        new_process_row : [],
        process_array : new Map(),
        new_process_map : new Map(),
        process_obj_array : [],
        process_del_array:[],
        row : [{}],
     }
     this.handleInputChange = this.handleInputChange.bind(this)
     this.removeFromUI = this.removeFromUI.bind(this)
     this.addProcess = this.addProcess.bind(this)
   }
   componentDidMount() {
        var new_process_row = []
        axios.get(Url+'/vendor/readAllVendors.php').then(res => { // get all vendors 
            var j;
            var arrs = new Array();
            for(j=0; j<res.data.length; j++){  // iterate over response and assign to array                 
                arrs.push({
                    name:res.data[j].vendor_name,
                })
            }
            this.setState({ 
                vendors: arrs,
            })
            
        })
        axios.get(Url+'/process/readProcessType.php').then(res => {   // get all process types
            var i;
            for(i=0; i<res.data.length; i++){  // iterate over response and assign to array                                     
                new_process_row.push(res.data[i].process_name)
            }
            this.setState({
                new_process_row : new_process_row
            })
        })
   }
   handleInputChange = (idx) => e => {
    const { name, value } = e.target
    var rows = [...this.state.row]
    rows[idx][[name]] = value
    this.setState({
        row : rows,
    },() => {
        if(rows[idx]['process_name'] !== undefined && rows[idx]['vendor_name'] !== undefined && rows[idx]['cost'] !== undefined){
            if (typeof this.props.getProcess === 'function') {
                this.props.getProcess(rows)
            }
        }
     })
   }
   removeFromUI = (idx) => () => {               // remove existing process row from UI and then delete it        
        var row = [...this.state.row]
        row.splice(idx,1)
        this.setState({
            row
        })
   }
   addProcess(){
     const item = {
        process_name: '',
        vendor_name: '',
        cost: '',
      }
      this.setState({
        row: [...this.state.row, item]
      })
   }
   deleteProcess =(idx, idx2)=> ()=>{
        if (typeof this.props.deleteProcess === 'function') {
            this.props.deleteProcess(idx, idx2)
        }
   }
   render (){
       return(
           <div className="p-3" id="process"> 
               <Button outline color="info" onClick={this.addProcess}> + </Button>          
               {this.props.existingProcess.length > 0 ?
                    <Table className="table table-hover mt-3">
                        <thead>
                            <tr>
                                <td><strong>S.No</strong></td>
                                <td><strong>Process</strong></td>
                                <td><strong>Vendor</strong></td>
                                <td><strong>Cost</strong></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.existingProcess.map((item, idx) => (
                                <tr>
                                    <td>{idx+1}</td>
                                    <td>{item.process_name}</td>
                                    <td>{item.vendor_name}</td>
                                    <td>{item.cost}</td>
                                    <td><button className="btn btn-outline-danger btn-sm"  onClick={this.deleteProcess(idx, item.process_id)} >
                                        X </button></td>
                                </tr>
                            ))}
                            {this.state.row.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                 <td>{this.props.existingProcess.length + 1}</td>
                                <td>
                                    <Select value={this.state.row[idx].process_name} name="process_name" onChange={this.handleInputChange(idx)}>
                                        {this.state.new_process_row.map(function(item1, key){  return (<MenuItem value={item1}>{item1}</MenuItem>)})}
                                    </Select>
                                </td>
                                <td>
                                    <Select value={this.state.row[idx].vendor_name} name="vendor_name" onChange={this.handleInputChange(idx)}>
                                        {this.state.vendors.map(function(item1, key){  return (<MenuItem value={item1.name}>{item1.name}</MenuItem>)})}
                                    </Select>
                                </td>
                                <td>
                                    <input type="text" name="cost" value={this.state.row[idx].cost} onChange={this.handleInputChange(idx)}
                                    className="form-control"/>
                                </td>
                                <td>
                                    <button className="btn btn-outline-danger btn-sm"  onClick={this.removeFromUI(idx)} >
                                    X
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
               :
               <Table className="table table-hover mt-3">
                   <thead>
                       <tr>
                            <td><strong>S.No</strong></td>
                            <td><strong>Process</strong></td>
                            <td><strong>Vendor</strong></td>
                            <td><strong>Cost</strong></td>
                            <td></td>
                       </tr>
                   </thead>
                   <tbody>
                      {this.state.row.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                 <td>{this.props.existingProcess.length + 1}</td>
                                <td>
                                    <Select value={this.state.row[idx].process_name} name="process_name" onChange={this.handleInputChange(idx)}>
                                        {this.state.new_process_row.map(function(item1, key){  return (<MenuItem value={item1}>{item1}</MenuItem>)})}
                                    </Select>
                                </td>
                                <td>
                                    <Select value={this.state.row[idx].vendor_name} name="vendor_name" onChange={this.handleInputChange(idx)}>
                                        {this.state.vendors.map(function(item1, key){  return (<MenuItem value={item1.name}>{item1.name}</MenuItem>)})}
                                    </Select>
                                </td>
                                <td>
                                    <input type="text" name="cost" value={this.state.row[idx].cost} onChange={this.handleInputChange(idx)}
                                    className="form-control"/>
                                </td>
                                <td>
                                    <button className="btn btn-outline-danger btn-sm"  onClick={this.removeFromUI(idx)} >
                                    X
                                    </button>
                                </td>
                            </tr>
                        ))}
                          
                   </tbody>
               </Table>
   }
           </div>
       );
   }
  
}
Process.propTypes = {
    process_row : PropTypes.array,
    handleInputChange: PropTypes.func,
    deleteProcess: PropTypes.func,
  }
export default Process;