import React, { Component } from 'react';
import axios, { post } from "axios";
import {Url} from './UrlComponent';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { Button, Form, FormGroup, Col, Input, Label } from 'reactstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Test from './Test';

const imageMaxSize = 1000000000 //bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item)=> {return item.trim()})
var temp = ''
var image = ''
var path = Url+'/material/'

class MaterialPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            msku : this.props.msku,
            title: '',
            description: '',
            material_type: '',
            color: '',
            rate: '',
            quantity: '',
            vendors: [],
            selected_vendor: '',
            material: [],
            material_size: '',
            unit: '',
            material_size_array: {
                sizes : [],
                unit: '',
            },
            image : null,
            img : null,
            isDragActive: false,
            material_img_src: null,
            bom_array : [],
            inventory_array : [],
        }
    }
    componentDidMount(){
        axios({
            method: 'post',
            url: Url+'/material/material_page.php',
            headers: { 'content-type': 'application/json' },
            data: this.props.msku
        }).then(res=>{
                axios({
                    method: 'get',
                    url : Url+'/material/readMskuImage.php',
                    headers: { 'content-type': 'application/json' },
                    data: this.props.msku
                }).then(result => {
                    this.setState({
                        material_img_src : path +result.data.image_loc,
                        msku : res.data.msku,
                        title : res.data.title,
                        description : res.data.description,
                        material_type : res.data.material,
                        material_size: res.data.material_size,
                        unit: res.data.unit,
                        color : res.data.color,
                        rate : res.data.rate,
                        opening_quantity : res.data.opening_quantity,
                        quantity : res.data.quantity,
                        lifetime_quantity : res.data.lifetime_quantity,
                        selected_vendor : res.data.vendor_name,
                    },()=>console.log(this.state.selected_vendor))
                })
        })
        axios.get(Url+'/material/read_material.php').then(res => {
            var arr=[]
                    for(var i=0; i<res.data.length; i++){
                        arr.push(res.data[i])
                    }
                  this.setState({ material: arr })
        });
        axios.get(Url+'/vendor/readAllVendors.php').then(res => {
            var j;
            var arrs = new Array();
            for(j=0; j<res.data.length; j++){  // iterate over response and assign to array                 
                arrs.push({
                    id : res.data[j].vendor_id,
                    name:res.data[j].vendor_name,
                })
            }
            this.setState({ 
            vendors: arrs,
        })
        })
        axios({
            method: 'post',
            url: Url+'/bom/readBomForMsku.php',
            headers: { 'content-type': 'application/json' },
            data: this.props.msku
        }).then(res=>{
            var arr = []
            for(let i=0; i < res.data.length; i++){
                arr.push({
                    color_sku : res.data[i].color_sku,
                    sku : res.data[i].sku,
                    usage_quantity : res.data[i].usage_quantity,
                    cost : res.data[i].cost
                })
            }
            this.setState({
                bom_array : arr
            })
        })
        axios({
            method: 'post',
            url: Url+'/inventory/readMsku.php',
            headers: { 'content-type': 'application/json' },
            data: this.props.msku 
        }).then(res=>{
            var arr = []
            for(let i=0; i<res.data.length; i++){
                arr.push({
                    bin_location : res.data[i].bin_location,
                    bin_type : res.data[i].bin_type,
                    capacity : res.data[i].capacity,
                    inventory : res.data[i].inventory
                })
            }
            this.setState({
                inventory_array : arr
            })
        })
    }
    render(){
        const img = this.state.img
        const AllSkues = () =>{
            return(
                <div className="pr-3 pl-3 materials-divs">
                <div className="row mat_div_heading pr-3">
                      <div className= "col-sm p-3 materials-cell"><strong>Image</strong></div>
                      <div className= "col-sm p-3 materials-cell"><strong>Color Sku</strong></div>
                      <div className= "col-sm p-3 materials-cell"><strong>Sku</strong></div>
                      <div className= "col-sm p-3 materials-cell"><strong>Usage Quantity</strong></div>
                      <div className= "col-sm p-3 materials-cell"><strong>Cost</strong></div>
                  </div>
                  {this.state.bom_array.map((item, key)=>
                    (
                          <div key={key} className=" row mat_div pr-3">
                              <div className="col-sm p-2 materials-cell"><img class="img-thumbnail"/></div> 
                              <div className="col-sm p-2 materials-cell">{item.color_sku}</div> 
                              <div className="col-sm p-2 materials-cell">{item.sku}</div>
                              <div className="col-sm p-2 materials-cell">{item.usage_quantity} </div>  
                              <div className="col-sm p-2 materials-cell">{item.cost}</div>
                          </div>
                    ))}
            </div>
            )
        }
        const Inventory = () => {
            return(
                <div className="pr-3 pl-3 materials-divs">
                    <div className="row mat_div_heading pr-3">
                        <div className= "col-sm p-3 materials-cell"><strong>Bin Location</strong></div>
                        <div className= "col-sm p-3 materials-cell"><strong>Bin Type</strong></div>
                        <div className= "col-sm p-3 materials-cell"><strong>Capacity</strong></div>
                        <div className= "col-sm p-3 materials-cell"><strong>Inventory</strong></div>
                    </div>
                    {this.state.inventory_array.map((item, key)=>
                    (
                          <div key={key} className=" row mat_div pr-3">
                              <div className="col-sm p-2 materials-cell">{item.bin_location}</div> 
                              <div className="col-sm p-2 materials-cell">{item.bin_type}</div> 
                              <div className="col-sm p-2 materials-cell">{item.capacity}</div>
                              <div className="col-sm p-2 materials-cell">{item.inventory} </div> 
                          </div>
                    ))}
                </div>
            )
        } 
        return (
            <div className="container mt-3 material-page">
                <div className="row" id="material-page-row1">
                    <div className="col-12 col-sm-3 ml-5 msku_image_div">
                        {this.state.material_img_src !== undefined ? <button type="button" class="close" aria-label="Close" onClick={this.handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button> : null }
                            {this.state.material_img_src !== undefined ? <a className="msku_image" ><img src={this.state.material_img_src} width="100%" height="90%"/></a> :
                                <Dropzone onDrop={this.handleOnDrop} accept={acceptedFileTypes} maxSize={imageMaxSize} >
                                {({getRootProps, getInputProps, isDragActive}) => {
                                    return (
                                        <div
                                            {...getRootProps()}
                                            className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                            >
                                            <input {...getInputProps()} name="file" />
                                            {
                                                isDragActive ?
                                                '':
                                                <p>+</p>
                                            }
                                        </div>)
                               }}
                                </Dropzone>}
                    </div>
                    <div className="col-12 offset-sm-2 col-sm-6" id="material-page-details">
                        <Form>
                            <FormGroup row>
                                <Label htmlFor="msku" md={3}>MSKU</Label>
                                <Col md={5}>
                                    <Input type="text" id="msku" name="msku" value={this.state.msku} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label htmlFor="title" md={3}>Title</Label>
                                <Col md={5}>
                                    <Input type="text" id="title" name="title" value={this.state.title} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="material_type" md={3}>Material Type</Label>
                                <Col md={5}>
                                    <Select value={this.state.material_type} name="material_type" >
                                        {this.state.material.map(function(item, key){  return (<MenuItem value={item}>{item}</MenuItem>)})}
                                    </Select>
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="material_size" md={3}>Material Size</Label>
                                <Col md={5}>
                                    <Select value={this.state.material_size} name="material_size" >
                                        {this.state.material_size_array.sizes.map(function(item, key){  return (<MenuItem value={item}>{item}</MenuItem>)})}
                                    </Select>
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="unit" md={3}>Unit</Label>
                                <Col md={5}>
                                    {this.state.unit}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="color" md={3}>Color</Label>
                                <Col md={5}>
                                    <Input type="text" id="color" name="color" value={this.state.color}  />
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="rate" md={3}> Rate</Label>
                                <Col md={5}>
                                    <Input type="number" id="rate" name="rate" value={this.state.rate} />
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="quantity" md={3}> Quantity</Label>
                                <Col md={5}>
                                    <Input type="number" id="quantity" name="quantity" value={this.state.quantity} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label htmlFor="vendor" md={3}> Vendor Name</Label>
                                <Col md={5}>
                                    <Select value={this.state.selected_vendor} name="selected_vendor" >
                                        {this.state.vendors.map(function(item, key){  return (<MenuItem value={item.id}>{item.name}</MenuItem>)})}
                                    </Select>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                    </div>
                <div className="row" id="material-page-row2">
                    <div className="container">
                        <Tabs className="mt-4" defaultActiveKey="all_skues">
                            <Tab eventKey="all_skues" title="In Use">
                                <AllSkues />
                            </Tab>
                            <Tab eventKey="inventory" title="Inventory">
                                <Inventory />
                            </Tab>
                        </Tabs>
                    </div>
                    
                    
                </div>
            </div>
          );
    }
}
 
export default MaterialPage;