import React, { Component } from 'react';
import axios, { post } from "axios";
import { Url } from './UrlComponent';
import ReactDataGrid from "react-data-grid";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import { CSVLink } from "react-csv";

class UploadExcel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            rows: [],
            cols: [],
            row2: [],
            cols2: [],
            excel:'',
            excel2:''
        }
        this.fileHandler = this.fileHandler.bind(this)
        this.fileHandler2 = this.fileHandler2.bind(this)
        this.submitData = this.submitData.bind(this)
    }
    fileHandler = (event) => {
        let fileObj = event.target.files[0]
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err)           
          }
          else{
              var rows = resp.rows
              var new_row = []
              for(let i=1; i<rows.length; i++){
                  new_row.push({
                      msku : rows[i][1],
                      title : rows[i][2],
                      description : rows[i][3],
                      material_type : rows[i][4],
                      material_size : rows[i][5],
                      unit : rows[i][6],
                      color : rows[i][7],
                      rate : rows[i][8],
                      quantity : rows[i][9],
                      vendor_name : rows[i][10],
                  })
              }
            this.setState({
              cols: resp.cols,
              rows: new_row,
              excel: 'material'
            })
          }
        })      
      }
    fileHandler2 = (event) => {
        let fileObj = event.target.files[0]
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err)           
          }
          else{
            var rows = resp.rows
              var new_row = []
              console.log(rows)
              for(let i=1; i<rows.length; i++){
                  new_row.push({
                      msku : rows[i][1],
                      date : rows[i][2],
                      quantity : rows[i][3],
                      rate : rows[i][4],
                      status : rows[i][5],
                  })
              }  
            this.setState({
              rows2: new_row,
              excel2: 'purchased'
            })
          }
        })      
    }
    submitData(){
        var material_array = []
        if(this.state.excel === 'material'){
            const form_data ={
                "material" : this.state.rows
            }
            axios({
              method: 'post',
              url: Url+'/material/uploadExcel.php',
              headers: { 'content-type': 'application/json' }, 
              data: form_data
              })
              .then(result => {
                  alert(result.data)
                  this.setState({
                    excel : ''
                  })
              })
              .catch(error => this.setState({ error: error.message }))
        
        }
        else if(this.state.excel2 === 'purchased'){
            
            if(this.state.rows2.length > 0){
                const form_data ={
                    "purchase_arr" : this.state.rows2
                }
                axios({
                method: 'post',
                url: Url+'/purchase/uploadExcel.php',
                headers: { 'content-type': 'application/json' }, 
                data: form_data
                })
                .then(result => {
                    alert(result.data)
                    this.setState({
                        excel2 : ''
                    })
                })
                .catch(error => this.setState({ error: error.message }))
            }
            else{
              alert("File is empty!")
          }
        }
    }

    render(){
        const defaultColumnProperties = {
            resizable: true,
            width: 120
        }
        const column = [['msku', 'title', 'description', 'material type', 'material size', 'unit', 'color', 'rate', 'quantity', 'vendor_name']]
        const column2 = [['msku', 'date', 'quantity', 'rate', 'status']]
        const csvData = [
            { key: "msku", name: "MSKU", editable: true },
            { key: "title", name: "Title", editable: true },
            { key: "description", name: "Description", editable: true },
            { key: "material_type", name: "Material Type", editable: true },
            { key: "material_size", name: "Material Size", editable: true },
            { key: "unit", name: "Unit", editable: false },
            { key: "color", name: "Color", editable: true },
            { key: "rate", name: "Rate", editable: true },
            { key: "quantity", name: "Quantity", editable: true },
            { key: "vendor_name", name: "Vendor Name", editable: true},
            ].map(c => ({ ...c, ...defaultColumnProperties }))
        const csvData2 = [
            { key: "msku", name: "MSKU", editable: true },
            { key: "date", name: "Date", editable: true },
            { key: "quantity", name: "Quantity", editable: true },
            { key: "rate", name: "Rate", editable: true },
            { key: "status", name: "Status", editable: true },
            ].map(c => ({ ...c, ...defaultColumnProperties }))
        return(
            <Tabs id="controlled-tab-example" activeKey={this.state.key} onSelect={key => this.setState({ key })}>
                <Tab eventKey="new-material" title="Material Excel">
                    <CSVLink data={column}>Download Sample CSV file </CSVLink><input type="file" onChange={this.fileHandler} style={{"padding":"10px"}} placeholder="Upload Excel"/>
                    {this.state.excel == 'material' ?<ReactDataGrid columns={csvData}  rowGetter={i => this.state.rows[i]}  rowsCount={ this.state.rows.length} 
                    enableCellSelect={true} minHeight={500} onColumnResize /> : null}
                </Tab>
                <Tab eventKey="purchased" title="Purchased Excel">
                    <CSVLink data={column2}>Download Sample CSV file </CSVLink><input type="file" onChange={this.fileHandler2} style={{"padding":"10px"}} placeholder="Upload Excel"/>
                    {this.state.excel2 == 'purchased' ?<ReactDataGrid columns={csvData2}  rowGetter={i => this.state.rows2[i]}  rowsCount={ this.state.rows2.length} 
                    enableCellSelect={true} minHeight={500} onColumnResize /> : null}                    
                </Tab>
            </Tabs>
        ); 
    }

}

export default UploadExcel