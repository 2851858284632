import React, { Component } from 'react';
import './App.css';
import Front from './components/FrontComponent'; 
import { BrowserRouter } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      // <Provider store = {store}>
            <BrowserRouter>
               <div className="App">
                  {/* <Main /> */}
                  <Front />
               </div>
            </BrowserRouter>
        //  </Provider>
    );
  }
}

export default App;
