import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Matrix from './MatrixComponent';
import OldMatrix from './OldMatrixComponent';
import Process from './ProcessComponent';
import Bom from './BillOfMaterialComponent';
import Inventory from './InventoryComponent';
import Image from './ImageComponent';
import TotalCost from './TotalCostComponent';
import BomDetail from './BomDetailComponent';
import { Button, Table } from 'reactstrap';
import {Url} from './UrlComponent';
import axios, { post } from "axios";

class ProductTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colorCode: '',
            inputColor: '',
            selectedSizes: [],
            checkStatusArray: [],
            selectedSizes: [],
            stateChangedVariables: [],
            size_type : '',
            bom_array : [],
            bomDetailsArray : [],
            process_row : [],
            product_details_array: [],
            img_array : [],
            changeInImage : false,
            imageFiles : [],
            imagesId : [],
            processId : [],
            existingProcessArray: [],
            vendors : [],
            processTypes : [],
            bomIdArray: [],
            doneMsg : 'Saved',
            total_process_cost: 0,
            total_bom_cost: 0,
            cost : 0,
            mrp: 0,
            b2b_prices: 0,
            standard_selling_price: 0,
            other_cost: 0,
            factory_overhead: 0,
        }
        this.getInputColor = this.getInputColor.bind(this)
        this.getColorCode = this.getColorCode.bind(this)
        this.getSelectedSizes = this.getSelectedSizes.bind(this)
        this.getProcess = this.getProcess.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getImage = this.getImage.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
        this.deleteBom = this.deleteBom.bind(this)
        this.deleteProcess = this.deleteProcess.bind(this)
        this.getCost = this.getCost.bind(this)
        this.setAllCost = this.setAllCost.bind(this)
    }
    getInputColor = (childDataObject)=>{
        const {name, value} = childDataObject
        var stateChangedVariables = [...this.state.stateChangedVariables]
        this.setState(prevState =>({
                ...prevState,
                [name] : value
        }),()=>{
            stateChangedVariables.push([name])
            this.setState({
                stateChangedVariables
            })
        })
    }
    getColorCode= (childDataObject)=>{
        const {name, value} = childDataObject
        var stateChangedVariables = [...this.state.stateChangedVariables]
        this.setState(prevState =>({
                ...prevState,
                [name] : value
        }),()=>{
            stateChangedVariables.push([name])
            this.setState({
                stateChangedVariables
            })
        })
    }
    getSelectedSizes = (sizes,size_type)=>{
        this.setState(prevState=>({
            ...prevState,
            selectedSizes : sizes,
            size_type : size_type
        }))
    }
    getBomArray = childData =>{
        this.setState({
            bom_array : childData
        })
    }
    getProcess = childData =>{
        this.setState({
            process_row : childData
        },()=>{
            var temp_arr = [...childData]
            
        })
    }
    handleSubmit(){
         var form_data = {}
         var path = ''                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
         
        if(this.props.code !== undefined){
            form_data = {
                "color_sku": this.props.code,
                "group_id": this.props.productDetails.group_id,
                "collection": this.props.productDetails.collection,
                "title": this.props.productDetails.title,
                "description": this.props.productDetails.description,
                "launch_date": this.props.productDetails.launch_date,
                "website_link": this.props.productDetails.website_link,
                "inspiration_link": this.props.productDetails.inspiration_link,
                "product_image_inspiration": this.props.productDetails.product_image_inspiration,
            }
            path = Url + '/product/updateProduct.php'
            this.sendAxiosRequest(form_data, path)
        }
        else if(this.props.code === undefined){
            form_data = {
                "color_sku": this.props.productDetails.selected_color_sku,
                "group_id": this.props.productDetails.group_id,
                "color": this.state.inputColor,
                "color_code": this.state.colorCode,
                "collection": this.props.productDetails.collection,
                "title": this.props.productDetails.title,
                "description": this.props.productDetails.description,
                "launch_date": this.props.productDetails.launch_date,
                "website_link": this.props.productDetails.website_link,
                "inspiration_link": this.props.productDetails.inspiration_link,
                "product_image_inspiration": this.props.productDetails.product_image_inspiration,
                "product_type": this.props.productDetails.val_product_type,
                "size_type": this.state.size_type,
            }
            path = Url + '/product/createProduct.php'
            this.sendAxiosRequest(form_data, path)
        }
        // create variants
        if(this.state.selectedSizes.length > 0){
            form_data = {
                "color_sku": this.props.productDetails.selected_color_sku,
                "selectedSizes": this.state.selectedSizes,
            }
            path = Url + '/product/createSku.php'
            this.sendAxiosRequest(form_data, path)
        }

        //save bom details
        if(this.state.bom_array.length > 0){
            form_data = {
                "color_sku" : this.props.code,
                "bom" : this.state.bom_array
            }
            path = Url + "/bom/createBom.php"
            this.sendAxiosRequest(form_data, path)

        }
         // save process
        if (this.state.process_row.length > 0){
            form_data = {
                "color_sku" : this.props.code,
                "process" : this.state.process_row
            }
            path = Url + '/process/createProcess.php'
            this.sendAxiosRequest(form_data, path)
        }
         
        // delete process 
        if(this.state.processId.length > 0){
            form_data = {
                "color_sku" : this.props.code,
                "process_delete" : this.state.processId
            }
            path = Url + '/process/createProcess.php'
            this.sendAxiosRequest(form_data, path)
        }
        //save Image
        if(this.state.changeInImage === true){
            this.fileUpload(this.state.imageFiles, this.props.code).then((response)=>{
                console.log(response.data)
                if(response.data === 'success'){
                    this.setState({
                        changeInImage : false
                    })
                }
            }) 
            
        }

        //delete image
        if(this.state.imagesId.length > 0){
            const form_data = {
                'color_sku' : this.props.code,
                'imagesId' : this.state.imagesId
            }
            path = Url + '/product/deleteImage.php'
            this.sendAxiosRequest(form_data, path)
        }

        //delete bom details
        if(this.state.bomIdArray.length > 0){
            const form_data = {
                'color_sku' : this.props.code,
                'bomId' : this.state.bomIdArray
            }
            path = Url+'/bom/deleteBom.php'
            this.sendAxiosRequest(form_data, path)
        }
        
    }
    fileUpload(files,code){
        const url = Url+'/product/saveImage.php'
        const formData = new FormData()
        for(var i=0; i < files.length; i++){
            formData.append('file['+i+']',files[i])
        }
        formData.append('color_sku',code)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return  post(url, formData,config)
        
    }
    componentDidMount(){
        if(this.props.code !== undefined)
            {   
              //get variants 
              axios({                                                   // get all sku details of a product 
                  method: 'post',
                  url: Url+'/product/readskuDetails.php',
                  headers: { 'content-type': 'application/json' },
                  data: this.props.productDetails.selected_color_sku
                })
                .then(result => {
                    var i;
                    var arr = [];
                  for(i=0; i<result.data.length; i++){                  // iterate over response and assign to array
                      arr.push({
                        sku:result.data[i].sku,
                        color:result.data[i].color,
                        size:result.data[i].size,
                      })
                  }
                   this.setState({                                          //set state of object array 
                      product_details_array: arr,
                   })
                })
                .catch(error => this.setState({ error: error.message }))

                //get all images
                axios({
                    method: 'post',
                    url: Url+'/product/readImage.php',
                    headers: { 'content-type': 'application/json' },
                    data: this.props.code
                  }).then(result => {
                      if(result.data !== "empty"){
                           var arr1 =[]
                           for(var i=0; i<result.data.length; i++){
                                arr1.push({
                                    id : result.data[i].image_id,
                                    imgSrc : result.data[i].image_loc
                                })
                           }
                           this.setState({
                               img_array : arr1
                           })
                       }
                  })

                  //get all processes
                  axios({
                    method: 'post',
                    url: Url+'/process/readProcess.php',
                    headers: { 'content-type': 'application/json' },
                    data: this.props.code
                  }).then(res => {
                        var j;
                        var arrs = new Array();
                        for(j=0; j<res.data.length; j++){  // iterate over response and assign to array                 
                            arrs.push({
                                process_id : res.data[j].process_id,
                                process_name : res.data[j].process_name,
                                vendor_name : res.data[j].vendor_name,
                                cost:res.data[j].cost,
                            })
                        }
                        this.setState({ 
                            existingProcessArray: arrs,
                        })
                   })

                   //get bom details
                   axios({
                    method: 'post',
                    url: Url+'/bom/viewBomDetails.php',
                    headers: { 'content-type': 'application/json' },
                    data: this.props.code
                  }).then(res => {
                    var arr =[]
                     for(var i=0; i < res.data.length; i++){
                        arr.push({
                            bom_id: res.data[i].bom_id,
                            msku : res.data[i].msku,
                            material : res.data[i].title,
                            rate : res.data[i].rate,
                            usage : res.data[i].usage_quantity,
                            amount : res.data[i].cost,
                        })
                     }
                     this.setState({
                         bomDetailsArray : arr
                     })
                  })
                  
                  // get process cost
                  axios({                                       // get all process cost
                        method: 'post',
                        url: Url+'/process/viewProcessCost.php',
                        headers: { 'content-type': 'application/json' },
                        data: this.props.code
                    })
                    .then(res => {
                        this.setState({
                            total_process_cost : res.data
                        })
                    })
                    .catch(error => this.setState({ error: error.message }))

                   // get bom cost
                   axios({                                       // get all process cost
                        method: 'post',
                        url: Url+'/bom/viewBomCost.php',
                        headers: { 'content-type': 'application/json' },
                        data: this.props.code
                    })
                    .then(res => {
                        this.setState({
                            total_bom_cost : res.data,
                        })
                        // this.baseState =  JSON.stringify(this.state.bom_obj)
                    })
                    .catch(error => this.setState({ error: error.message }))

                    // get total cost of product
                    axios({
                        method: 'post',
                        url: Url+'/product/showAllCosts.php',
                        headers: { 'content-type': 'application/json' },
                        data: this.props.code
                    }).then(res=>{
                        console.log(res.data)
                        if(res.data['cost']== undefined || res.data['cost'] == 0 || res.data['cost'] == NaN){
                            this.setAllCost(this.state.total_process_cost, this.state.total_bom_cost)
                        }
                        else{
                            this.setState({
                                cost : parseFloat(res.data['cost']),
                                mrp : parseFloat(res.data['mrp']),
                                b2b_prices : parseFloat(res.data['b2b_prices']),
                                standard_selling_price : parseFloat(res.data['standard_selling_price']),
                            })
                        }
                        
                    })
                    
            }
            
    }
    sendAxiosRequest(form_data, path){
        axios({
            method: 'post',
            url: path,
            headers: { 'content-type': 'application/json' },
            data: form_data
        }).then(res=>{
            console.log(res.data)
            if(res.data.type === 'success-product'){
                window.location = "/home/product/"+this.props.productDetails.selected_color_sku
            }
            else if(res.data.type === 'success-variant'){
                window.location = "/home/product/"+this.props.productDetails.selected_color_sku
            }
            else if(res.data.type === 'success-product-update'){
                alert("Saved")
            }
            else if(res.data.type === 'success-bom'){
                //get bom cost
                var bomDetailsArray = [...this.state.bomDetailsArray, ...res.data.bom]
                var total_cost = 0
                for(let i=0; i < bomDetailsArray.length; i++){
                    total_cost = total_cost + parseFloat(bomDetailsArray[i].amount)
                }
                var bom_array = []
                this.setState({
                    bomDetailsArray : bomDetailsArray,
                    bom_array : bom_array,
                    total_bom_cost : total_cost
                },()=>{
                    this.setAllCost(this.state.total_process_cost, this.state.total_bom_cost)
                })
                
            }
            else if(res.data.type=== 'success-bom-delete'){
                var bomIdArray = []
                var bomDetailsArray = [...this.state.bomDetailsArray]
                var total_cost = 0
                for(let i=0; i < bomDetailsArray.length; i++){
                    total_cost = total_cost + parseFloat(bomDetailsArray[i].amount)
                }
                this.setState({
                    bomIdArray: bomIdArray,
                    total_bom_cost : total_cost
                },()=>{
                    this.setAllCost(this.state.total_process_cost, this.state.total_bom_cost)
                })
                
            }
            else if(res.data.type === 'success-process'){
                //get process cost
                var existingProcessArray = [...this.state.existingProcessArray, ...res.data.process]
                var total_cost = 0
                for(let i=0; i < existingProcessArray.length; i++){
                    total_cost = total_cost + parseFloat(existingProcessArray[i].cost)
                }
                var process_row = []
                this.setState({
                    existingProcessArray : existingProcessArray,
                    process_row : process_row,
                    total_process_cost : total_cost
                },()=>{
                    this.setAllCost(this.state.total_process_cost, this.state.total_bom_cost)
                })
               
            }
            else if(res.data.type === 'success-process-delete'){
                var processId = []
                var existingProcessArray = [...this.state.existingProcessArray]
                var total_cost = 0
                for(let i=0; i < existingProcessArray.length; i++){
                    total_cost = total_cost + parseFloat(existingProcessArray[i].cost)
                }
                this.setState({ 
                    processId : processId,
                    total_process_cost : total_cost
                },()=>{
                    this.setAllCost(this.state.total_process_cost, this.state.total_bom_cost)
                })
            }
            else{
                // alert(res.data)
                // console.log(res.data)
            }
        })
    }
    getImage = (img_array, files) =>{
        this.setState({
            img_array : img_array,
            imageFiles : files,
            changeInImage : true
        },()=>console.log(files))
    }
    deleteBom = (idx, idx2) => {
        var bomDetailsArray = [...this.state.bomDetailsArray]
        var bomIdArray = [...this.state.bomIdArray]
        bomDetailsArray.splice(idx,1)
        bomIdArray.push(idx2)
        this.setState({
            bomDetailsArray : bomDetailsArray,
            bomIdArray : bomIdArray
        })
    }
    deleteImage = (idx,idx2) =>{
        var img_array = [...this.state.img_array]
        var imagesId = [...this.state.imagesId]
        img_array.splice(idx,1)
        imagesId.push(idx2)
        this.setState({
            img_array : img_array,
            imagesId : imagesId
        })

    }
    deleteProcess = (idx, idx2) =>{
        var existingProcessArray = [...this.state.existingProcessArray]
        var processId = [...this.state.processId]
        existingProcessArray.splice(idx,1)
        processId.push(idx2)
        this.setState({
            existingProcessArray : existingProcessArray,
            processId : processId
        })
    }
    getCost =(childData) =>{

    }
    setAllCost(process_cost, bom_cost){
        var cost = process_cost + bom_cost
        this.setState({
            cost : cost,
            mrp : 7*cost,
            b2b_prices : 1.4*cost,
            standard_selling_price : 3.5*cost
        })
    }
    render() { 
        return ( <React.Fragment>
                        <Tabs className="mt-4" defaultActiveKey="matrix" id="mid-tabs">
                            <Tab eventKey="matrix" title="Matrix">
                                {this.state.product_details_array.length > 0  ? <OldMatrix productDetails={this.props.productDetails} code={this.props.code} productArray= {this.state.product_details_array}/>
                                 : <Matrix productDetails={this.props.productDetails} getSelectedSizes={this.getSelectedSizes} 
                                 getInputColor={this.getInputColor} getColorCode = {this.getColorCode} inputColor={this.state.inputColor}
                                  colorCode={this.state.colorCode} selectedSizes={this.state.selectedSizes} checkStatusArray={this.state.checkStatusArray} /> }                                
                            </Tab>
                            <Tab eventKey="images" title="Images">
                                <Image img_array = {this.state.img_array} getImage={this.getImage} deleteImage={this.deleteImage} code={this.props.code} />
                            </Tab>
                            <Tab eventKey="process" title="Process">
                                <Process code={this.props.code} existingProcess= {this.state.existingProcessArray} getProcess = {this.getProcess} 
                                deleteProcess= {this.deleteProcess} />
                            </Tab>
                            <Tab eventKey="bom" title="BOM">
                                <Bom code={this.props.code} getBomArray = {this.getBomArray}/>
                            </Tab>
                            <Tab eventKey="bomDetail" title="BOM details">
                                <BomDetail code={this.props.code} bomDetailsArray={this.state.bomDetailsArray} deleteBom = {this.deleteBom}/>
                            </Tab>
                            <Tab eventKey="totalcost" title="Cost/Price">
                                <TotalCost code={this.props.code} total_bom_cost={this.state.total_bom_cost} total_process_cost={this.state.total_process_cost}
                                cost={this.state.cost} mrp={this.state.mrp} b2b_prices={this.state.b2b_prices} standard_selling_price={this.state.standard_selling_price}
                                other_cost ={this.state.other_cost} factory_overhead={this.state.factory_overhead} getCost={this.getCost}/>
                            </Tab>
                            <Tab eventKey="inventory" title="Inventory">
                                <Inventory code={this.props.code}/>
                            </Tab>                              
                        </Tabs> 
                        <Button style={{float: 'right', background: '#17a2b8', border: 'none'}} onClick={this.handleSubmit}>Save</Button>
                </React.Fragment>
            
                );
    }
}
 
export default ProductTab;