import React, { Component } from 'react';
import { Button } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import { Link } from 'react-router-dom';
import EditMaterial from './EditMaterialComponent';
var path = Url+'/material/';

class AllMaterials extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            
            editor_mode : false,
            material_arr : [],
            searchItem : ''
         }
         this.openEditor = this.openEditor.bind(this)
         this.archiveMaterial = this.archiveMaterial.bind(this)
         this.handleCheckbox = this.handleCheckbox.bind(this)
    }
    openEditor(){
        this.props.openEditor()
    }
    
    handleCheckbox= (item, key) => event =>{
        var materials = this.state.materials
        for (let [name, value] of Object.entries(materials[key])) {
            if(name === 'checked'){
                materials[key][name]= !value
            }
        }
        this.setState({
            materials : materials,
        },()=>{
            if(item.checked === true){
                this.setState({
                    archive : false,
                })
            }
            else{
                var flag =0
                for(let i=0; i < materials.length; i++){
                    for (let [name, value] of Object.entries(materials[i])) {
                        if(name === 'checked' && value === true){
                        flag =1
                        break
                        }
                    }
                    if(flag === 1){
                        break
                    }
                }
                if(flag === 0){
                    this.setState({
                        archive : true,
                    })
                }
            }
        })
    }
    archiveMaterial(){
        var temp_arr = []
        var materials = this.state.materials
        for(let i=0; i < materials.length; i++){
            for (let [name, value] of Object.entries(materials[i])) {
              if(name === 'checked' && value === true){
                 temp_arr.push(materials[i].msku)
              }
            }
         }
         const form_data = {
            "material_arr" : temp_arr,
            "action" : "archive",
         }
         axios({
            method: 'post',
            url: Url+'/material/bulkEdit.php',
            headers: { 'content-type': 'application/json' },
            data: form_data
         }).then(res=>{
             console.log(res)
             console.log(res.data)
         })
    }
    addMaterial=()=>{
        this.props.addMaterial()
    }
    handleInput = (event) => {
        this.props.handleInput(event.target)
    }
    render() { 
        const {materials} = this.props
        const ShowAllMaterials = () =>{
            return(
                <div className="materials-divs">
                <div className="row product-row">
                      <div className= "p-3"></div>
                      <div className="row mat_div_heading">
                            <div className= "col-sm p-3 materials-cell"><strong>Image</strong></div>
                            <div className= "col-sm p-3 materials-cell"><strong>MSKU</strong></div>
                            <div className= "col-sm p-3 materials-cell"><strong>Title</strong></div>
                            <div className= "col-sm p-3 materials-cell"><strong>Width</strong></div>
                            <div className= "col-sm p-3 materials-cell"><strong>Rate</strong></div>
                            <div className= "col-sm p-3 materials-cell"><strong>Vendor</strong></div>
                      </div>
                  </div>
                  {materials.map((item, key)=>
                    (
                        <div key={key} className=" row">
                              <div className="p-2">
                                  <label className="custom-checkbox">
                                      <input type="checkbox" onChange={this.handleCheckbox(item,key)} defaultChecked={item.checked} />
                                      <span className="checkmark"></span>
                                 </label>
                            </div>
                               <Link className="all-material-a" to={`/home/material/${item.msku}`} params={{ msku: item.msku }}>
                                   <div className="row mat_div pr-3">
                                        <div className="col-sm p-2 materials-cell"><img class="img-thumbnail" src={item.imgSrc}/></div> 
                                        <div className="col-sm p-2 materials-cell">{item.msku}</div> 
                                        <div className="col-sm p-2 materials-cell">{item.title}</div>
                                        <div className="col-sm p-2 materials-cell">{item.width} </div> 
                                        <div className="col-sm p-2 materials-cell">{item.rate}</div>
                                        <div className="col-sm p-2 materials-cell">{item.vendor_name}</div>
                                   </div>
                                </Link>
                          </div>
                      
                      
                    ))}
            </div>
            )
        }
        
        return (
            <div>
                <h5><span className="count-display">Material</span> &nbsp;
                    <span className="show-count-span">&nbsp; {materials.length} results</span>
                    <span><Button className="new-product-btn" id="new-product-btn" onClick={this.addMaterial}><i class="fa fa-plus" aria-hidden="true"></i> Add</Button></span>
                </h5>
                <div className="container-fluid mt-3" id="all-row-container">
                    <div className="row pl-2 product-button-div">
                        <div className="col-12 col-sm-4 p-2">
                            <Button className="bulk-editor ml-2" onClick={this.openEditor}> <i class="fa fa-edit"></i>Bulk Editor</Button>
                        </div>
                        <div className="col-12 col-sm-3 p-2">
                            <div className="form-group has-search">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input className="form-control" type="text" placeholder="Search by msku.." id="search-bar" name="searchItem" value={this.props.searchItem} onChange={this.handleInput}/>
                            </div>
                            
                        </div>
                        <div className="col-12 offset-sm-2 col-sm-3 p-2" style={{textAlign:'right'}}>
                            
                        </div>
                    </div>
                    <ShowAllMaterials />
                </div>
                {/* <div className="row mt-2" id="all-materials-row">
                    <div className="container-fluid">
                        <div className="row pt-2 pl-2 mat-button-div pb-2">
                                
                                <Button className="new-material-btn ml-2"  onClick={this.handleEditor} disabled={this.state.bulk_editor}> <i class="fa fa-edit"></i>Bulk Editor</Button>
                                
                                
                        </div>
                        <div className="row">
                            {this.state.editor_mode ?<EditMaterial vendor_arr={this.state.vendor_arr} materials={this.state.materials} material_arr={this.state.material_arr} submitData={this.submitData}/> :<ShowAllMaterials />}
                        </div>
                    </div>
                </div> */}
            </div>
            
          );
    }
}
 
export default AllMaterials;