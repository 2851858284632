import React , { Component } from 'react';
import { Button, Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent'; 
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'; 

class OldMatrix extends Component {
    constructor(props) {
        super(props);
        this.state = {
           code: this.props.code,
           selected_color_sku : this.props.productDetails.selected_color_sku,
           product_details_array : []
        }
    }
    
    render(){
        return(
            <div className="pt-3" id="old-matrix">
                    <Table className="table table-hover pt-2">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td><strong>SKU</strong></td>
                                <td><strong>Size</strong> </td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.productArray.map((product, idx) => (
                                    <tr id="addr0" key={idx}>
                                            <td>{idx+1}</td>
                                            <td> {product.sku} </td>
                                            <td> {product.size} </td>
                                            <td></td>         
                                    </tr>
                                ))}
                            </tbody>
                    </Table>
                </div>
        );
    }
}

export default OldMatrix;