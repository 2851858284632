import React, { Component } from 'react';
import { Button, Table, Input } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";

class EditVendor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        vendors :[],
        vendor_arr : [],
        supplier_work:[],
        issueTypes1: [...this.props.vendor_arr],
        issueTypes2 : ['Supplier', 'Job Work'],
        issueTypes3: [...this.props.poc],
        loc: ['Internal', 'External'],
        poc : [],
        vendor_map : new Map(),
        vendor_process : [],
        search_item : '',
      }
      this.handleSubmit = this.handleSubmit.bind(this)
      this.filterList = this.filterList.bind(this)
    }
    componentDidMount(){
        axios.get(Url+'/vendor/read_all_vendors.php').then(result => {
          var i;
          var arr = [];
          for(i=0; i<result.data.length; i++){  // iterate over response and assign to array
            arr.push({
                vendor_id:result.data[i].vendor_id,
                vendor_name:result.data[i].vendor_name,
                selected_vendor_type:result.data[i].vendor_type,
                selected_vendor_work:result.data[i].vendor_work,
                company:result.data[i].company,
                address:result.data[i].address,
                contact_person:result.data[i].contact_person,
                mobile_no:result.data[i].mobile_no,
                whatsapp_no:result.data[i].whatsapp_no,
                poc:result.data[i].poc,
                vendor_loc:result.data[i].vendor_loc,
              })
            }
            this.setState({ 
              vendors: arr,
            })
            this.baseState =  JSON.stringify(this.state.vendors)
          })
          axios.get(Url+'/vendor/readPocType.php').then(res => {
            var k;
            var arr2 = []
            for(k=0; k<res.data.length; k++){  // iterate over response and assign to array                 
                 arr2.push(res.data[k].poc_name)
            }
            this.setState({ 
                poc: arr2,
             })
           })
          axios.get(Url+'/vendor/readVendorWork.php').then(res => {
            var j;
            var arrs = []
            for(j=0; j<res.data.length; j++){  // iterate over response and assign to array                 
                arrs.push(res.data[j].value)
            }
            this.setState({ 
                vendor_arr: arrs,
             })
           })
    }
    handleSubmit(event) {
       var vendor_arr = []
       if(this.state.vendor_map.size > 0){
          for (let [key, value] of this.state.vendor_map){
            vendor_arr.push({
                  vendor_id : value.get("vendor_id"),
                  vendor_name : value.get("vendor_name") ? value.get("vendor_name"):'',
                  vendor_type : value.get("selected_vendor_type") ? value.get("selected_vendor_type"):'',
                  vendor_work : value.get("selected_vendor_work") ? value.get("selected_vendor_work"):'',
                  company : value.get("company") ? value.get("company"):'',
                  address : value.get("address") ? value.get("address"):'',
                  contact_person : value.get("contact_person") ? value.get("contact_person"):'',
                  mobile_no : value.get("mobile_no") ? value.get("mobile_no"):'',
                  whatsapp_no : value.get("whatsapp_no") ? value.get("whatsapp_no"): '',
                  poc : value.get("poc") ? value.get("poc"): '',
                  vendor_location : value.get("vendor_loc") ? value.get("vendor_loc"): '',
              })
          }
          console.log(vendor_arr)
        //   const form_data = {
        //       "vendor_arr" : vendor_arr,
        //   }
        //   axios({
        //       method: 'post',
        //       url: Url+'/vendor/updateVendor.php',
        //       headers: { 'content-type': 'application/json' },
        //       data: form_data
        //     }).then(res =>{
        //         alert(res.data)
        //         if(res.data === 'Updated'){
        //           this.setState({
        //               vendor_map : new Map()
        //           })
        //         }
        //     })
        }
        else{
            alert("No changes detected!")
        }
  }
    filterList=(event)=>{
        const {name,value} = event.target
        var arr = [...JSON.parse(this.baseState)]
        arr = arr.filter(function(item){
        return item.vendor_name.toLowerCase().search(
            event.target.value.toLowerCase()) !== -1;
        })
        if(arr.length === 0){
          this.setState({
              vendors: [...JSON.parse(this.baseState)]
          })
        }
        else{
          this.setState({vendors: arr})
        }
        
        this.setState({
            [name] : value
        })
    }
    onGridRowsUpdated = ({ fromRow, toRow, updated }) =>{
        // console.log(fromRow)
        var keyName = Object.keys(updated)
        var keyValue = Object.values(updated)
        const vendors = this.state.vendors.slice()
        console.log(updated, fromRow, toRow)
        var arr=this.state.vendor_map
        var map1 = new Map()
        this.setState(state => {
            for (let i = fromRow; i <= toRow; i++) {
                vendors[i] = { ...vendors[i], ...updated }
                if(arr.has(i)){
                    map1= arr.get(i)
                }
                map1.set("vendor_id",vendors[i].vendor_id)
                map1.set(keyName[0],keyValue[0])
                arr.set(i,map1)
            }
            return { vendors }
          },()=>{
            this.setState({
                vendor_map : arr,
              },()=>console.log(arr))
        })
    }
    goBack =()=>{
       this.props.showAll()
    }
    render(){
      const {issueTypes1, issueTypes2, issueTypes3} = this.state
      const defaultColumnProperties = {
          resizable: true,
          width: 120
      }
      const { DropDownEditor : DropDownEditor1 } = Editors;
      const { DropDownEditor : DropDownEditor2 } = Editors;
      const { DropDownEditor : DropDownEditor3 } = Editors;
      const IssueTypeEditor1 = <DropDownEditor1 options={issueTypes1} />
      const IssueTypeEditor2 = <DropDownEditor2 options={issueTypes2} />
      const IssueTypeEditor3 = <DropDownEditor3 options={issueTypes3} />

      const columns = [
          { key: "vendor_id", name: "Vendor ID", editable: false },
          { key: "vendor_name", name: "Vendor Name", editable: true },
          { key: "selected_vendor_type", name: "Vendor Type", editable: true, editor: IssueTypeEditor2, isExpanded : true },
          { key: "selected_vendor_work", name: "Vendor Work", editable: true, editor: IssueTypeEditor1, isExpanded : true },
          { key: "company", name: "Company", editable: true },
          { key: "address", name: "Address", editable: true},
          { key: "contact_person", name: "Contact Person", editable: false },
          { key: "mobile_no", name: "Mobile No", editable: true },
          { key: "whatsapp_no", name: "Whatsapp No", editable: true },
          { key: "poc", name: "POC", editable: true, editor: IssueTypeEditor3, isExpanded : true },
          { key: "vendor_loc", name: "Location", editable: true },
        ].map(c => ({ ...c, ...defaultColumnProperties }))
      return(
          <div className="container-fluid">
              <div className="row">
                  <div className="col-12 col-sm-3">
                      <span className="count-display">Vendors</span> &nbsp;
                      <span className="show-count-span">&nbsp; {this.state.vendors.length} results</span>
                  </div>
                  <div className="col-12 col-sm-4">
                      <input className="form-control" type="text" placeholder="Search by vendor name.." id="search-bar" name="searchItem"
                        value={this.state.searchItem} onChange={this.filterList}/>
                    </div>
                  <div className="col-12 offset-sm-2 col-sm-3 ">
                      <Button type="button" className="ml-2 edit-btns bulk-editor" onClick={this.handleSubmit}> Save</Button>
                      <Button type="button" className="ml-2 edit-btns back"  onClick={this.goBack}> Back</Button>
                  </div>               
              </div>
              <div className="row mt-2" style={{height : '80vh'}}>
              <ReactDataGrid columns={columns}  rowGetter={i => this.state.vendors[i]}  rowsCount={ this.state.vendors.length} 
                  enableCellSelect={true} minHeight={488} onColumnResize onGridRowsUpdated={this.onGridRowsUpdated} />       
              </div>
          </div>
      );
    }
    }
export default EditVendor; 