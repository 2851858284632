import React, { Component } from 'react';
import { Button, Table, Input, Label,  Col } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
class EditJob extends Component {
    constructor(props) {
      super(props);
      this.state = {
        color_sku: [],
        selected_color_sku :'',
        status: '',
        job_array: [],
        job_id: '',
        job_variant_array : [],
        showAllJobs: true,
        selected_job: {},
        selected_job_map: new Map(),
        selected_job_varient_map : new Map(),
        cut_quantity:[],
        fabrication_quantity: [],
        packing_quantity: [],
      }
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleInputChangeII = this.handleInputChangeII.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleBack = this.handleBack.bind(this)
      this.changeStatus = this.changeStatus.bind(this)
      this.handleSelectChange = this.handleSelectChange.bind(this)
    }
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
        this.setState({
          selected_job:{
              [name]: value
          }
        })
        var arr=this.state.selected_job_map
        arr.set(name,value)
        arr.set("job_id",this.state.job_id)
        this.setState({
            selected_job_map : arr,
        })
    }
    componentDidMount(){
      axios.get(Url+'/job/readColorSku.php').then(res => {
        this.setState({ 
          color_sku: res.data
        })
      })
    }
    handleSubmit(event) {
        event.preventDefault()
        var form_data = {}
        var selected_job_arr = []
        if(this.state.selected_job_map.size > 0){
          selected_job_arr.push({
              job_id : this.state.selected_job_map.get("job_id"),
              setup_date : this.state.selected_job_map.get("setup_date") ? this.state.selected_job_map.get("setup_date"):0,
              ship_date : this.state.selected_job_map.get("ship_date") ? this.state.selected_job_map.get("ship_date"):0,
              job_title : this.state.selected_job_map.get("job_title") ? this.state.selected_job_map.get("job_title"):'',
          })
        }
        
        var selected_job_varient_arr = []
        if(this.state.selected_job_varient_map.size > 0){
            for (let [key, value] of this.state.selected_job_varient_map){
                selected_job_varient_arr.push({
                    id : value.get("id"),
                    order_quantity : value.get("order_quantity"),
                    cut_quantity : value.get("cut_quantity") ? value.get("cut_quantity"):0,
                    fabrication_quantity : value.get("fabrication_quantity") ? value.get("fabrication_quantity"):0,
                    packing_quantity : value.get("packing_quantity") ? value.get("packing_quantity"):0,
                })
            }
        }
        if(selected_job_arr.length === 0 && selected_job_varient_arr.length === 0){
           alert("No changes detected!")
        }
        else if(selected_job_arr.length > 0 && selected_job_varient_arr.length === 0){
            form_data = {
              "method" : "edit-job",
              "edit_job" : selected_job_arr,
            }
        }
        else if(selected_job_arr.length === 0 && selected_job_varient_arr.length > 0){
            form_data = {
              "method" : "edit-job",
              "edit_job_variants" : selected_job_varient_arr,
            }
        }
        else{
            form_data = {
              "method" : "edit-job",
              "edit_job" : selected_job_arr,
              "edit_job_variants" : selected_job_varient_arr,
            }
        }
        console.log(form_data)
        if(Object.entries(form_data).length > 0){
            axios({
              method: 'post',
              url: Url+'/job/create_job.php',
              headers: { 'content-type': 'application/json' },
              data: form_data
            }).then(res =>{
                alert(res.data)
                const form_data1 = {
                  "job_id" : this.state.job_id,
                  "type" : "read-job-variants"
                }
                axios({
                  method: 'post',
                  url: Url+'/job/readJobs.php',
                  headers: { 'content-type': 'application/json' },
                  data: form_data1
                }).then(res =>{
                    var arr = []
                    for(var i=0; i<res.data.length; i++){
                       arr.push({
                          id : res.data[i].id,
                          size : res.data[i].size,
                          order_quantity : res.data[i].order_quantity,
                          cut_quantity : res.data[i].cut_quantity,
                          fabrication_quantity : res.data[i].fabrication_quantity,
                          packing_quantity : res.data[i].packing_quantity,
                          status : res.data[i].status,
                       })
                    }
                    this.setState({
                       job_variant_array : arr
                    },()=>{
                      this.setState({
                         showAllJobs : false
                      })
                    })
                })
           })
        }
    }
    handleSelectChange(e){
      let {name, value} = e.target;
      this.setState({
        [name]: value,
      },()=>{
          const form_data = {
              "color_sku" : value,
              "type": "read-job"
          }
          axios({
            method: 'post',
            url: Url+'/job/readJobs.php',
            headers: { 'content-type': 'application/json' },
            data: form_data
          }).then(res =>{
              var arr =[]
              for(var i=0; i<res.data.length; i++){
                  arr.push({
                     job_id : res.data[i].job_id,
                     job_title : res.data[i].job_title,
                     setup_date : res.data[i].setup_date,
                     ship_date : res.data[i].ship_date,
                  })
              }
              this.setState({
                 job_array : arr
              })
          })
      })
    }
    validate(cut_q, fab_q, pack_q){
       
    }
    handleInputChangeII= (item,idx) => (event) => {
        const {name, value} = event.target
        var new_value = 0
        var arr=this.state.selected_job_varient_map
        if(name === 'cut_quantity'){
           if(parseInt(value) + parseInt(item.cut_quantity)  > parseInt(item.order_quantity)){
             alert("Cut quantity cannot be greater than Order quantity!")
           }
           else{
              new_value = parseInt(value) + parseInt(item.cut_quantity)
              var cut_quantity = [...this.state.cut_quantity]
              cut_quantity[idx] = value
              this.setState({
                  cut_quantity
              })
           }
        }
        else if(name === 'fabrication_quantity'){
          var map1 = new Map();
          map1 = arr.get(idx)
          if(parseInt(value) + parseInt(item.fabrication_quantity) > parseInt(item.cut_quantity) + parseInt(this.state.cut_quantity[idx])){
            alert("Fabrication quantity cannot be greater than Cut quantity or Order quantity!")
          }
          else{
            new_value = parseInt(value) + parseInt(item.fabrication_quantity)
            var fabrication_quantity = [...this.state.fabrication_quantity]
            fabrication_quantity[idx] = value
            this.setState({
              fabrication_quantity
            })
          }
       }
       else if(name === 'packing_quantity'){
          var map1 = new Map()
          map1 = arr.get(idx) 
          if(parseInt(value) + parseInt(item.packing_quantity) > parseInt(item.fabrication_quantity) + parseInt(this.state.fabrication_quantity[idx])){
            alert("Packing quantity cannot be greater than Cut quantity or Order quantity or Fabrication quantity!")
          }
          else{
            new_value = parseInt(value) + parseInt(item.packing_quantity)
            var packing_quantity = [...this.state.packing_quantity]
            packing_quantity[idx] = value
            this.setState({
              packing_quantity
            },()=>console.log(this.state.packing_quantity))
          } 
      }
        
        var map1 = new Map();
        if(arr.has(idx)){
            map1= arr.get(idx)
            map1.set(name,new_value)
            map1.set("id",parseInt(item.id))
            map1.set("order_quantity",parseInt(item.order_quantity))
            arr.set(idx,map1)
        }
        else{
            map1.set(name,new_value)
            map1.set("id",parseInt(item.id))
            map1.set("order_quantity",parseInt(item.order_quantity))
            arr.set(idx,map1)
        }
        this.setState({
            selected_job_varient_map : arr,
        })
    }
    changeStatus= (item) => (event) => {
        this.setState({
            selected_job : {
               job_id : item.job_id,
               job_title : item.job_title,
               setup_date : item.setup_date,
               ship_date: item.ship_date,
            },
            job_id : item.job_id
        })
        const form_data = {
            "job_id" : item.job_id,
            "type" : "read-job-variants"
        }
        axios({
          method: 'post',
          url: Url+'/job/readJobs.php',
          headers: { 'content-type': 'application/json' },
          data: form_data
        }).then(res =>{
            var arr = []
            for(var i=0; i<res.data.length; i++){
               arr.push({
                  id : res.data[i].id,
                  size : res.data[i].size,
                  order_quantity : res.data[i].order_quantity,
                  cut_quantity : res.data[i].cut_quantity,
                  fabrication_quantity : res.data[i].fabrication_quantity,
                  packing_quantity : res.data[i].packing_quantity,
                  status : res.data[i].status,
               })
            }
            this.setState({
               job_variant_array : arr
            },()=>{
              this.setState({
                 showAllJobs : false
              })
            })
        })
    }
    handleBack(e){
       this.setState({
          showAllJobs : true
       })
    }
      render(){
        return(
           <div className="container">
               {this.state.showAllJobs?<div className="row">
                    <div className="col-12 col-sm-5">
                        <Label htmlFor="color_sku" md={4}><strong>Color SKU</strong></Label>
                        <select id="color_sku" name="selected_color_sku" value={this.state.selected_color_sku} onChange={this.handleSelectChange}>
                            <option >Select Color SKU</option>      
                            {this.state.color_sku.map(function(sku, key){  return (<option key={sku} value={sku}>{sku}</option>)})} 
                        </select>
                    </div>
               </div> : null}
               <div className="row">
                    {this.state.showAllJobs ?<Table className="mt-3">
                        <thead>
                           <tr>
                               <td><strong>S.No.</strong></td>
                               <td><strong>Job Id</strong></td>
                               <td><strong>Setup date</strong></td>
                               <td><strong>Ship date</strong></td>
                               <td><strong>Remarks</strong></td>
                               <td></td>
                           </tr>
                        </thead>
                        <tbody>
                            {this.state.job_array.map((item,key)=>(
                              <tr>
                                 <td>{key+1}</td>
                                 <td>{item.job_id}</td>
                                 <td>{item.setup_date}</td>
                                 <td>{item.ship_date}</td>
                                 <td>{item.job_title}</td>
                                 <td><Button type="submit" color="primary" onClick={this.changeStatus(item)}> Change Status</Button></td>
                              </tr>
                            ))}
                                    
                        </tbody>
                    </Table> : <div className="row">
                                  <div className="col-12 col-sm-2 pt-4">
                                      <h5>Job Id : {this.state.job_id}</h5>
                                  </div>
                                  <div className="col-12 col-sm-3">
                                      <Label htmlFor="setup_date" md={12}><strong>Setup Date</strong></Label><br/>
                                      <Input type="date" id="setup_date" name="setup_date" value={this.state.selected_job.setup_date} onChange={this.handleInputChange}/> 
                                  </div>
                                  <div className="col-12 col-sm-3">
                                      <Label htmlFor="ship_date" md={12}><strong>Ship Date</strong></Label><br/>
                                      <Input type="date" id="ship_date" name="ship_date" value={this.state.selected_job.ship_date} onChange={this.handleInputChange}/> 
                                  </div>
                                  <div className="col-12 col-sm-3">
                                      <Label htmlFor="job_title" md={12}><strong>Remarks</strong></Label><br/>
                                      <Input type="text" id="job_title" name="job_title" value={this.state.selected_job.job_title} onChange={this.handleInputChange}/>
                                  </div> 
                                    <Table className="mt-3">
                                      <thead>
                                          <tr>
                                              <td>Size</td>
                                              <td>Order Quantity</td>
                                              <td>Cut Quantity</td>
                                              <td>Fabrication Quantity</td>
                                              <td>Packing Quantity</td>
                                              <td>Status</td>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.job_variant_array.map((item,key)=>(
                                          <tr>
                                              <td>{item.size}</td>
                                              <td>{item.order_quantity}</td>
                                              <td>Cut: {item.cut_quantity} {item.status === "In Progress" ?<Input type="number" id="cut_quantity" name="cut_quantity" value={this.state.cut_quantity[key]} onChange={this.handleInputChangeII(item,key)} /> : null }</td>
                                              <td>Fabricated: {item.fabrication_quantity}{item.status === "In Progress" ?<Input type="number" id="fabrication_quantity" name="fabrication_quantity" value={this.state.fabrication_quantity[key]} onChange={this.handleInputChangeII(item,key)}/> : null }</td>
                                              <td>Packed: {item.packing_quantity}{item.status === "In Progress" ? <Input type="number" id="packing_quantity" name="packing_quantity" value={this.state.packing_quantity[key]} onChange={this.handleInputChangeII(item,key)}/> : null }</td>
                                              <td>{item.status}</td>
                                          </tr>
                                        ))} 
                                      </tbody>
                                  </Table>
                                  <Button type="button" color="primary" onClick={this.handleBack}> Back</Button>
                                  <Button type="button" className="ml-2" color="primary" onClick={this.handleSubmit}> Save</Button>
                              </div>}
                      </div>
               </div>
        );
      }
    }
export default EditJob 
