import React, { Component } from 'react';
import axios, { post } from "axios";
import { Url } from './UrlComponent';
import ReactDataGrid from "react-data-grid";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ExcelRenderer} from 'react-excel-renderer';
import { CSVLink } from "react-csv";

class UploadJob extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            rows: [],
            cols: [],
            excel:'',
        }
        this.fileHandler = this.fileHandler.bind(this)
        this.submitData = this.submitData.bind(this)
    }
    fileHandler = (event) => {
        let fileObj = event.target.files[0]
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err)           
          }
          else{
              var rows = resp.rows
              var new_row = []
              for(let i=1; i<rows.length; i++){
                  new_row.push({
                      vendor_name : rows[i][0],
                      vendor_type : rows[i][1],
                      vendor_work : rows[i][2],
                      company : rows[i][3],
                      address : rows[i][4],
                      contact_person : rows[i][5],
                      mobile_no : rows[i][6],
                      whatsapp_no : rows[i][7],
                      poc : rows[i][8],
                      vendor_loc : rows[i][9],
                      vendor_process : rows[i][10],
                  })
              }
            this.setState({
              cols: resp.cols,
              rows: new_row,
              excel: 'vendors'
            })
          }
        })      
    }
    submitData(){
        if(this.state.rows.length > 1){
          const form_data = {
             "vendor_arr" : this.state.rows,
             "method" : "excel"
          }
          axios({
            method: 'post',
            url: Url+'/vendor/createVendor.php',
            headers: { 'content-type': 'application/json' }, 
            data: form_data
          }).then(result=>{
              alert(result.data)
          })
        }
    }
    render(){
        const column = [
            ["Vendor name", "Vendor type", "Vendor Work", "Company", "Address", "Contact person", "Mobile No","Whatsapp No", "Poc", "Vendor Location", "Process"]];
        const defaultColumnProperties = {
            resizable: true,
            width: 120
        }
        const csvData = [
            { key: "vendor_name", name: "Vendor Name", editable: true },
            { key: "vendor_type", name: "Vendor Type", editable: true },
            { key: "vendor_work", name: "Vendor Work", editable: true },
            { key: "company", name: "Company", editable: true },
            { key: "address", name: "Address", editable: true },
            { key: "contact_person", name: "Contact Person", editable: true },
            { key: "mobile_no", name: "Mobile No", editable: true },
            { key: "whatsapp_no", name: "Whatsapp No.", editable: true },
            { key: "poc", name: "POC", editable: true },
            { key: "vendor_loc", name: "Vendor Location", editable: true },
            { key: "Process", name: "Process", editable: true },
            ].map(c => ({ ...c, ...defaultColumnProperties }))
        return(
            <div>
                <CSVLink data={column}>Download Sample CSV file </CSVLink><input type="file" onChange={this.fileHandler} style={{"padding":"10px"}} placeholder="Upload Excel"/>
                    {this.state.excel == 'vendors' ?<ReactDataGrid columns={csvData}  rowGetter={i => this.state.rows[i]}  rowsCount={ this.state.rows.length} 
                    enableCellSelect={true} minHeight={500} onColumnResize /> : null}
            </div>
        )
    }
}

export default UploadJob