import React, { Component } from 'react';
import { Button, Form, FormGroup, Col, Input,  Label, FormFeedback } from 'reactstrap';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from "axios";
import { Url } from './UrlComponent';

class CreateVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
        vendor_name: '',
        vendor_type: ['Supplier', 'Job Work'],
        selected_vendor_type : '',
        vendor_work: '',
        vendors: [],
        var_poc:'',
        poc: [],
        company: '',
        address: '',
        contact_person: '',
        mobile_no: '',
        whatsapp_mob_no: '',
        vendor_loc: '',
        poc_contact_no: '',
        message : '',
    }
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount(){
      axios.get(Url+'/vendor/readPocType.php').then(res => {
        var k;
        var arr2 = new Array();
        for(k=0; k<res.data.length; k++){  // iterate over response and assign to array                 
             arr2.push({
                  id:res.data[k].poc_id,
                  name:res.data[k].poc_name,
            })
        }
        this.setState({ 
            poc: arr2,
         })
       })
    }
    handleInputChange(event) {
      const target = event.target;
      const value =  target.value;
      const name = target.name;
      this.setState({
        [name]: value,
        message : ''
      },()=>{
        if(name === 'selected_vendor_type'){
              axios({
                method: 'post',
                url: Url+'/vendor/readVendorType.php',
                headers: { 'content-type': 'application/json' },
                data: value
            }).then(res=>{
                var arr2 = []
                for(var k=0; k<res.data.length; k++){  // iterate over response and assign to array                 
                    arr2.push({
                          id:res.data[k].id,
                          value:res.data[k].value,
                    })
                }
                this.setState({ 
                  vendors: arr2,
                })
              })
        }
        else if(name === 'var_poc'){
            axios({
              method: 'post',
              url: Url+'/vendor/readPocNo.php',
              headers: { 'content-type': 'application/json' },
              data: parseInt(value)
            }).then(res => {
                this.setState({
                    poc_contact_no : res.data
                })
            })
        } 
      })
    }
    handleSubmit(event) {
        event.preventDefault()
        this.baseState =  JSON.stringify(this.state.poc)
         const  form_data = {
            "method" : "form",
            "vendor_name": this.state.vendor_name,
            "vendor_type" : this.state.selected_vendor_type,
            "vendor_work" : this.state.vendor_work,
            "company" : this.state.company,
            "address" : this.state.address,
            "contact_person" : this.state.contact_person,
            "mobile_no" : this.state.mobile_no,
            "whatsapp_no" : this.state.whatsapp_mob_no,
            "poc" : this.state.var_poc,
            "vendor_loc" : this.state.vendor_loc,
          }
        axios({
            method: 'post',
            url: Url+'/vendor/createVendor.php',
            headers: { 'content-type': 'application/json' },
            data: form_data
          })
          .then(result => {
            // alert(result.data)
            if(result.data === 'success'){
              this.setState({
                vendor_name: '',
                vendor_type: ['Supplier', 'Job Work'],
                selected_vendor_type : '',
                vendor_work: '',
                poc: [...JSON.parse(this.baseState)],
                var_poc:'',
                poc_contact_no: '',
                company: '',
                address: '',
                contact_person: '',
                mobile_no: '',
                whatsapp_mob_no: '',
                vendor_loc: '',
                message : 'Created'
            })
            }
            
          })
          .catch(error => this.setState({ error: error.message }))
    }
    goBack =()=>{
      this.props.showAll()
   }
    render(){
      return(
        <div className="container p-3 create">
          <h5 className="p-2">Add Vendor Details</h5>
            <Form className="p-2" onSubmit={this.handleSubmit}>
                <FormGroup row>
                  <Label htmlFor="vendor_name" md={2}>Vendor Name</Label>
                      <Col md={8}>
                          <Input type="text" id="vendor_name" name="vendor_name" value={this.state.vendor_name} onChange={this.handleInputChange} placeholder="Enter Vendor Name" />
                      </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="vendor_type" md={2}>Vendor Type</Label>
                      <Col md={8}>
                          <Select value={this.state.selected_vendor_type} name="selected_vendor_type" onChange={this.handleInputChange}>
                                  {this.state.vendor_type.map(function(item, key){  return (<MenuItem value={item}>{item}</MenuItem>)})}
                          </Select>
                      </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="vendor_work" md={2}>Vendor Work</Label>
                      <Col md={8}>
                          <Select value={this.state.vendor_work} name="vendor_work" onChange={this.handleInputChange}>
                                  {this.state.vendors.map(function(item, key){  return (<MenuItem value={item.id}>{item.value}</MenuItem>)})}
                          </Select>
                      </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="company" md={2}>Company</Label>
                      <Col md={8}>
                          <Input type="text" id="company" name="company" value={this.state.company} onChange={this.handleInputChange} />
                        </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="address" md={2}>Address</Label>
                      <Col md={8}>
                          <Input type="text" id="address" name="address" value={this.state.address} onChange={this.handleInputChange} />
                        </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="contact_person" md={2}>Contact Person</Label>
                      <Col md={8}>
                          <Input type="text" id="contact_person" name="contact_person" value={this.state.contact_person} onChange={this.handleInputChange} />
                        </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="mobile_no" md={2}>Mobile No.</Label>
                      <Col md={8}>
                          <Input type="tel" id="mobile_no" name="mobile_no" value={this.state.mobile_no} onChange={this.handleInputChange} />
                      </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="whatsapp_mob_no" md={2}>Whatsapp Mobile No.</Label>
                      <Col md={8}>
                          <Input type="tel" id="whatsapp_mob_no" name="whatsapp_mob_no" value={this.state.whatsapp_mob_no} onChange={this.handleInputChange} />
                        </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="poc" md={2}>POC</Label>
                      <Col md={8}>
                          <Select value={this.state.var_poc} name="var_poc" onChange={this.handleInputChange}>
                                  {this.state.poc.map(function(item, key){  return (<MenuItem value={item.id}>{item.name}</MenuItem>)})}
                          </Select>
                          {this.state.poc_contact_no ? <p>Contact no.  {this.state.poc_contact_no} </p> : null}
                        </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="vendor_loc" md={2}>Vendor Location</Label>
                      <Col md={8}>
                          <Input type="text" id="vendor_loc" name="vendor_loc" value={this.state.vendor_loc} onChange={this.handleInputChange} />
                        </Col>
                </FormGroup>
                <FormGroup row>
                      <Col md={{size: 2, offset: 2}}><span style={{color : 'green', fontWeight:'600'}}>{this.state.message}</span></Col>
                      <Col md={{size: 1, offset: 3}}>
                          <Button type="button" className="back" onClick={this.goBack}>
                              Back
                          </Button>
                      </Col>
                      <Col md={{size: 1}}>
                          <Button type="submit" className="save-vendor ml-3">
                              Submit
                          </Button>
                      </Col>
                </FormGroup>
        </Form>
      </div>
      );
   }
}

export default CreateVendor;  