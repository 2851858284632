import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import PropTypes from "prop-types";


class Bom extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        bom: '',
        row: [{}],
        materials: [{}],
        bom_array : new Map(),
        bom_title : '',
     }
     this.AddBomRow = this.AddBomRow.bind(this)
     this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this)
     this.handleChange = this.handleChange.bind(this)
    
   }
   AddBomRow = () => { //add row to color bom
    const item = {
        msku: '',
        material: '',
        usage: '',
        rate: '',
        amount: '',
      };
      this.setState({
        row: [...this.state.row, item]
      });
   }
   handleChange = (idx) => e => {  // get usage value and set total amount
        const { name, value } = e.target
        var rows = [...this.state.row]
        if(name === 'msku'){
            var materials  = this.state.materials
            var title = ''
            var rate = ''
            for(let i=0; i<materials.length; i++){
                if(materials[i].id === value){
                    title = materials[i].name
                    rate = materials[i].rate
                }
            }
            rows[idx]['msku'] = value
            rows[idx]['material'] = title
            rows[idx]['rate'] = rate
        }
        else if(name === 'usage') {
            rows[idx][[name]] = value
            rows[idx]['amount'] = rows[idx]['rate'] === undefined ? 0 : parseInt(rows[idx]['rate']) * parseInt(value)  
        }
        if(rows[idx]['msku'] !== undefined && rows[idx]['usage'] !== undefined){
            if (typeof this.props.getBomArray === 'function') {
                this.props.getBomArray(rows)
            }
        }
        this.setState({
            row : rows
        })
   }
   handleRemoveSpecificRow = (idx) => () => {
        const row = [...this.state.row]
        row.splice(idx, 1)
        this.setState({ row })
       
   }
   componentDidMount(){
        axios({
            method: 'post',
            url: Url+'/material/readMaterial.php',
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            var i;
            var arr = new Array();
            for(i=0; i<res.data.length; i++){  // iterate over response and assign to array                                
                arr.push({
                    id:res.data[i].msku,
                    name:res.data[i].title,
                    rate: res.data[i].rate,
                })
            }
            this.setState({ 
                materials: arr,
             },()=>console.log(this.state.materials))
        })
        
   }
  render(){
      var flag=''
      return(
          <div className="p-3" id="bom">
              <h6>BOM <Button outline color="info" onClick={this.AddBomRow}> + </Button>{' '}</h6>
              <Table className="mt-3">
                  <thead>
                      <tr>
                        <td>#</td>
                        <td><strong>Msku </strong></td>
                        <td><strong>Material</strong></td>
                        <td><strong>Usage</strong></td>
                        <td><strong>Cost</strong></td>
                        <td><strong>Amount</strong></td>
                      </tr>
                  </thead>
                  <tbody>
                      {this.state.row.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                            <td>{idx+1} </td>
                            <td>
                                <select name="msku" value={this.state.row[idx].msku} onChange={this.handleChange(idx)}>
                                    <option >Select material</option>
                                    {this.state.materials.map(function(item, key){  return (<option key={key} value={item.id}>{item.id}</option>)})} 
                                </select><br/>
                                <a href="/home/material"> New Material?</a>
                            </td>
                            <td>{this.state.row[idx].material}</td>
                            <td>
                                <input
                                type="text"
                                name="usage"
                                value={this.state.row[idx].usage}
                                onChange={this.handleChange(idx)}
                                className="form-control"
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                name="cost"
                                value={this.state.row[idx].rate}
                                className="form-control" readOnly
                                />
                            </td>
                            <td>
                                <input type="text" name="amount"
                                value={this.state.row[idx].amount}
                                className="form-control" readOnly
                                />
                            </td>
                            <td>
                                <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={this.handleRemoveSpecificRow(idx)}
                                >
                                 X
                                </button>
                            </td>
                            </tr>
                        ))}
                  </tbody>
              </Table>
          </div>
      );
  }
}
Bom.propTypes = {
    bom_array : PropTypes.array,
    handleChange : PropTypes.func
  }
export default Bom;