import React, { Component } from 'react';
import { Button, Table, Input, Label} from 'reactstrap';
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";
import axios from "axios";
import {Url} from './UrlComponent';

class EditSales extends Component {
    constructor(props) {
      super(props)
      this.state = {
          orders :[],
          order_map: new Map(),
          sales: [],
          sales_map: new Map(),
          platform_array: ['Amazon', 'Myntra', 'Berrylush', 'Jabong'],
          showSales: false,
          order_id: '',
          name : '',
          platform: '',
          total_quantity: '',
          total_price: '',
      }
    
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleInputChangeII = this.handleInputChangeII.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleBack = this.handleBack.bind(this)
      this.filterList = this.filterList.bind(this)
      this.viewProducts = this.viewProducts.bind(this)
    }
    componentDidMount(){
       axios.get(Url+'/sales/readAllOrders.php').then(result => {
           var arr = []
           for(var i=0; i<result.data.length; i++){  // iterate over response and assign to array
              arr.push({
                  order_id: result.data[i].order_id,
                  channel_name: result.data[i].channel_name,
                  web_order_no: result.data[i].web_order_no,
                  transporter_name: result.data[i].transporter_name,
                  invoice_no: result.data[i].invoice_no,
                  invoice_date:result.data[i].invoice_date,
                  tracking_no:result.data[i].tracking_no,
                  name:result.data[i].name,
                  address:result.data[i].address,
                  city:result.data[i].city,
                  state:result.data[i].state,
                  pin_code:result.data[i].pin_code,
                  platform:result.data[i].platform,
                  total_quantity:result.data[i].total_quantity,
                  total_price:result.data[i].total_price,
                  date:result.data[i].date,
                  phone_no:result.data[i].phone_no,
                })
            }
            this.setState({ 
              orders: arr,
            })
            this.baseState =  JSON.stringify(this.state.orders)
       })
    }
    handleInputChange= (item,idx) => (event) => {
        const {name, value} = event.target
        var orders = [...this.state.orders]
        var arr=this.state.order_map
        var map1 = new Map()
        if(arr.has(idx)){
            map1= arr.get(idx)
        }
        map1.set("order_id",item.order_id)
        Object.keys(orders[idx]).forEach(function (item) {
            if(item === name){
                orders[idx][item] = value
                map1.set(name,value)
            }
        })
        this.setState({
            orders
        }) 
        arr.set(idx,map1)
        this.setState({
            order_map : arr,
        })
    }
    handleInputChangeII= (item,idx) => (event) => {
        const {name, value} = event.target
        var sales = [...this.state.sales]
        var arr=this.state.sales_map
        var map1 = new Map()
        if(arr.has(idx)){
            map1= arr.get(idx)
        }
        map1.set("sale_id",item.sale_id)
        map1.set("order_id",this.state.order_id)
        if(name === 'quantity' && value !== ''){
            map1.set("order_id",this.state.order_id)
            this.setState({
                total_quantity : parseInt(this.state.total_quantity) - parseInt(sales[idx]['quantity']) + parseInt(value),
                total_price : parseInt(this.state.total_price) - parseInt(sales[idx]['amount']) + (parseInt(value)*parseInt(sales[idx]['rate']))
            },()=>{
                map1.set("total_quantity",this.state.total_quantity)
                map1.set("total_price",this.state.total_price)
            })
            
        }
        else if(name === 'quantity' && value === ''){
            map1.set("order_id",this.state.order_id)
            this.setState({
                total_quantity : parseInt([...JSON.parse(this.baseState2)]) - parseInt(sales[idx]['quantity']),
                total_price : parseInt(this.state.total_price) - parseInt(sales[idx]['amount'])
            })
        }
        else if(name === 'rate' && value !== ''){
            map1.set("order_id",this.state.order_id)
            this.setState({
                total_price : parseInt(this.state.total_price) - parseInt(sales[idx]['amount']) + (parseInt(value)*parseInt(sales[idx]['quantity']))
            },()=>{
                map1.set("total_quantity",this.state.total_quantity)
                map1.set("total_price",this.state.total_price)
            })
        }
        else if(name === 'rate' && value === ''){
            map1.set("order_id",this.state.order_id)
            this.setState({
                total_price : parseInt(this.state.total_price) - parseInt(sales[idx]['amount'])
            })
        }
        Object.keys(sales[idx]).forEach(function (item) {
            if(item === name && name === 'quantity'){
                sales[idx][item] = value
                map1.set(name,value)
                sales[idx]['amount'] = sales[idx][item] * sales[idx]['rate']
                map1.set('amount',sales[idx]['amount'])
                
            }
            else if(item === name && name === 'rate'){
                sales[idx][item] = value
                map1.set(name,value)
                sales[idx]['amount'] = sales[idx][item] * sales[idx]['quantity']
                map1.set('amount',sales[idx]['amount'])
            }
            // else{
            //     sales[idx][item] = value
            //     map1.set(name,value)
            // }
        })
        this.setState({
            sales
        }) 
        arr.set(idx,map1)
        this.setState({
            sales_map : arr,
        },()=>console.log(arr))
        
    }
    viewProducts= (item) =>(event)=> {
        this.setState({
            order_id : item.order_id,
            name : item.name,
            platform : item.platform,
            total_quantity: item.total_quantity,
            total_price : item.total_price,
        })
        axios({
          method: 'post',
          url: Url+'/sales/readAllSales.php',
          headers: { 'content-type': 'application/json' },
          data: item.order_id
        }).then(result =>{
            var arr = []
           for(var i=0; i<result.data.length; i++){  // iterate over response and assign to array
              arr.push({
                  order_id:result.data[i].order_id,
                  sale_id:result.data[i].sale_id,
                  sku:result.data[i].sku,
                  rate:result.data[i].rate,
                  quantity:result.data[i].quantity,
                  amount:result.data[i].amount,
                })
            }
            this.setState({ 
              sales: arr,
              showSales : true,
            })
            this.baseState2 = JSON.stringify(this.state.total_quantity)
            this.baseState3 = JSON.stringify(this.state.total_price)
        })
    }
    handleSubmit(event) {
        if(this.state.order_map.size > 0){
            var order_arr = []
            for (let [key, value] of this.state.order_map){
                order_arr.push({
                    order_id : value.get("order_id"),
                    name : value.get("name") ? value.get("name") : '',
                    address : value.get("address") ? value.get("address"):'',
                    platform : value.get("platform") ? value.get("platform"):'',
                    date : value.get("date") ? value.get("date"):'',
                })
            }
            const form_data = {
                "order_arr" : order_arr,
            }
            axios({
                method: 'post',
                url: Url+'/sales/updateOrder.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
              }).then(res =>{
                  alert(res.data)
                  if(res.data === 'Updated'){
                      this.setState({
                          order_map : new Map()
                      })
                      axios.get(Url+'/sales/readAllOrders.php').then(result => {
                        var i;
                        var arr = new Array();
                        for(i=0; i<result.data.length; i++){  // iterate over response and assign to array                 
                            arr.push({
                                order_id:result.data[i].order_id,
                                name:result.data[i].name,
                                address:result.data[i].address,
                                platform:result.data[i].platform,
                                total_quantity:result.data[i].total_quantity,
                                total_price:result.data[i].total_price,
                                date:result.data[i].date,
                              })
                         }
                      //set state of object array 
                      this.setState({ 
                        orders: arr,
                      })
                    })
                  }
              })
        }
        else if(this.state.sales_map.size > 0){
            var sales_arr = []
            for (let [key, value] of this.state.sales_map){
                sales_arr.push({
                    sale_id : value.get("sale_id"),
                    order_id : value.get("order_id")? value.get("order_id") : 0,
                    quantity : value.get("quantity") ? value.get("quantity") : 0,
                    rate : value.get("rate") ? value.get("rate"):0,
                    amount : value.get("amount") ? value.get("amount"):0,
                    total_quantity : value.get("total_quantity") ? value.get("total_quantity"):0,
                    total_price : value.get("total_price") ? value.get("total_price"):0,
                })
            }
            const form_data = {
                "sales_arr" : sales_arr,
            }
            console.log(form_data)
            axios({
                method: 'post',
                url: Url+'/sales/updateSales.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
              }).then(res =>{
                  alert(res.data)
                  if(res.data === 'Updated'){
                      this.setState({
                          order_map : new Map()
                      })
                      axios({method: 'post',
                            url: Url+'/sales/readAllSales.php',
                            headers: { 'content-type': 'application/json' },
                            data: this.state.order_id
                      }).then(result => {
                        var i;
                        var arr = new Array();
                        for(i=0; i<result.data.length; i++){  // iterate over response and assign to array                 
                            arr.push({
                                    order_id:result.data[i].order_id,
                                    sale_id:result.data[i].sale_id,
                                    sku:result.data[i].sku,
                                    rate:result.data[i].rate,
                                    quantity:result.data[i].quantity,
                                    amount:result.data[i].amount,
                              })
                         }
                      //set state of object array 
                      this.setState({ 
                        sales: arr,
                      })
                    })
                  }
              })
        }
        else{
            alert("No changes detected!")
        }
    }
    filterList(event){
        var arr = [...JSON.parse(this.baseState)]
        console.group(arr)
        arr = arr.filter(function(item){
        return item.name.toLowerCase().search(
            event.target.value.toLowerCase()) !== -1;
        });
        this.setState({orders: arr});
    }
    handleBack(e){
        this.setState({
           showSales : false
        })
    }
    render(){
        const defaultColumnProperties = {
            resizable: true,
            width: 120
        }
        const columns = [
            { key: "order_id", name: "Order ID", editable: false },
            { key: "channel_name", name: "Channel Name", editable: true },
            { key: "web_order_no", name: "Web Order No", editable: true },
            { key: "transporter_name", name: "Transporter Name", editable: true },
            { key: "invoice_no", name: "Invoice No.", editable: true},
            { key: "invoice_date", name: "Invoice Date", editable: false },
            { key: "tracking_no", name: "Tracking No.", editable: false },
            { key: "name", name: "Customer Name", editable: true },
            { key: "address", name: "Address", editable: false },
            { key: "city", name: "City", editable: false },
            { key: "state", name: "State", editable: false },
            { key: "pin_code", name: "Pin Code", editable: false },
            { key: "platform", name: "Platform", editable: true },
            { key: "total_quantity", name: "Total Quantity", editable: false },
            { key: "total_price", name: "Total Price", editable: false },
            { key: "date", name: "Date", editable: true},
            { key: "phone_no", name: "Phone No.", editable: false },
          ].map(c => ({ ...c, ...defaultColumnProperties }))
        return(
           <div className="container-fluid">
                <div className="row">
                    <div className="col-12 offset-sm-1 col-sm-3"><input type="text" placeholder="Search" onChange={this.filterList}/></div>
                    <div className="col-12 offset-sm-4 col-sm-3 "><Button type="button" className="ml-2 edit-btns" color="primary" onClick={this.handleSubmit}> Save</Button></div>               
                </div>
                <div className="row mt-3">
                    <ReactDataGrid columns={columns}  rowGetter={i => this.state.orders[i]}  rowsCount={ this.state.orders.length} 
                    enableCellSelect={true} minHeight={500} onColumnResize onGridRowsUpdated={this.onGridRowsUpdated} />        
                </div>
           </div>
        );
        
      }
    }
export default EditSales; 