import React , { Component } from 'react';
import { Card, CardBody, CardText, Form, Label, FormGroup, Col, Input, FormFeedback, Button} from 'reactstrap';
import ProductTab from './ProductTabComponent';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class ProductMatrix extends Component {
    constructor(props) {
        super(props);
        this.state = {
              selected_color_sku: this.props.code,
              color_array: [{}],
              group_id: '',
              collection: '',
              start_date: '0000-00-00',
              launch_date: null,
              title: '',
              description: '',
              cost: '',
              mrp: '',
              b2b_prices: '',
              standard_selling_price: '',
              image_link:'',
              website_link:'',
              inspiration_link:'',
              product_image_inspiration:'',
              all_skues: [],
              product_type: [],
              product_id : '',
              val_product_type:'',
              dropdownOpen: false,
              sizes_id: null,
              sizes: [],
              size_array: [],
              bom_status: false,
         };
          this.handleInputChange = this.handleInputChange.bind(this)
          this.toggle = this.toggle.bind(this)
        }
        componentDidMount()
        {
          if(this.state.selected_color_sku === undefined){
              axios({                                                      //read product type from db
                method: 'post',
                url: Url+'/product/readProductType.php',
                headers: { 'content-type': 'application/json' },
              })
              .then(result => {
                  var arr = new Array();
                  for(var i=0; i < result.data.length; i++){
                    arr.push({
                        id: result.data[i].id,
                        value: result.data[i].value,
                        code: result.data[i].code
                    })
                  }
                  this.setState({
                    product_type : arr
                  })
              })
              .catch(error => this.setState({ error: error.message }))
          }
          
          axios({                                                        //read all colors and color sku from db
            method: 'post',
            url: Url+'/color/readAllColor.php',
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            var arr = new Array();
            for(var i=0; i< res.data.length; i++)
            {
               arr.push({
                  color : res.data[i].color,
                  color_code : res.data[i].color_code
               })
            }
            this.setState({
               color_array : arr
            })
        });    
            if(this.state.selected_color_sku !== undefined)
            {
              axios({                                             // read complete product details from db
                  method: 'post',
                  url: Url+'/product/readProduct.php',
                  headers: { 'content-type': 'application/json' },
                  data: this.state.selected_color_sku
                })
                .then(result => {
                  this.setState({
                     group_id: result.data.group_id,
                     title: result.data.title,
                     product_id : result.data.product_type_id,
                     val_product_type: result.data.product_type_value,
                     collection: result.data.collection,
                     description: result.data.description,
                     start_date: result.data.start_date ? result.data.start_date : '0000-00-00',
                     launch_date: result.data.launch_date,
                     image_link : result.data.image_link,
                     website_link:result.data.website_link,
                     inspiration_link:result.data.inspiration_link,
                     product_image_inspiration:result.data.product_image_inspiration,
                     cost: result.data.cost,
                     mrp: result.data.mrp,
                     b2b_prices: result.data.b2b_prices,
                     standard_selling_price: result.data.standard_selling_price,
                     sizes_id: result.data.sizes_id
                  },()=>{
                    axios({
                        method: 'post',
                        url: Url+'/product/readSizeType.php',
                        headers: { 'content-type': 'application/json' },
                        data: this.state.product_id
                    }).then(res => {
                          var arr1 = []
                          var arr2 =[]
                          for(var i=0; i< res.data.length;i++)
                          {
                            arr1.push(res.data[i].sizes_name)
                            arr2.push(res.data[i].sizes)
                          }
                          this.setState({
                            sizes : arr1,
                            size_array : arr2
                          },()=>console.log(res.data))
                   })
                  })
                })
                .catch(error => this.setState({ error: error.message }));    
                     
            }
        }
        handleInputChange = event => {
            const target = event.target;
            const value =  target.value;
            const name = target.name;

            this.setState({
              [name]: value
            },() => {
               if(name === 'val_product_type'){
                axios({
                    method: 'post',
                    url: Url+'/product/readSizeType.php',
                    headers: { 'content-type': 'application/json' },
                    data: this.state.val_product_type
                }).then(res => {
                      var arr1 = []
                      var arr2 =[]
                      for(var i=0; i< res.data.length;i++)
                      {
                        arr1.push(res.data[i].sizes_name)
                        arr2.push(res.data[i].sizes)
                      }
                      this.setState({
                        sizes : arr1,
                        size_array : arr2
                      })
                    
                    });
               }
            });
        }
        toggle() {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen
            }));
        }
      render() {
         return (
            <div className='row' id="productmatrix">
                 <div className='col-12 col-sm-3' id="product_desc">
                 <Card>
                    <p id="card-header">Style Code</p>
                    <CardBody className="p-2" id="style-code-div">
                        <CardText id="style-code-p">
                          { this.props.code === undefined ? <Input type="text" id="selected_color_sku"
                           name="selected_color_sku" value={this.state.selected_color_sku} onChange={this.handleInputChange}  /> : this.state.selected_color_sku}
                           
                        </CardText> 
                        
                    </CardBody>
                 </Card>
                     <Card className="mt-3">
                        <p id="card-header">Overview</p>
                       <CardBody>
                        <CardText>
                            <Form>
                                <FormGroup row>
                                    <Label md={5}>Product Type:</Label>
                                        <Col md={7}>
                                        { this.props.code === undefined ?  
                                        <Select value={this.state.val_product_type} name="val_product_type" onChange={this.handleInputChange}>
                                            {this.state.product_type.map(function(item, key){  return (<MenuItem value={item.id}>{item.value}</MenuItem>)})}
                                        </Select> : this.state.val_product_type }
                                        </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label md={5}>Group Code:</Label>
                                    <Col md={7}>
                                        <Input type="text" id="group_id" name="group_id" value={this.state.group_id} onChange={this.handleInputChange}  />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                        <Label md={5}>Title:</Label>
                                        <Col md={7}>
                                            <Input type="text" id="title" name="title" value={this.state.title} onChange={this.handleInputChange}  />
                                        </Col>
                                </FormGroup>
                                <FormGroup row>
                                        <Label md={5}>Collection:</Label>
                                        <Col md={7}>
                                            <Input type="text" id="collection" name="collection" value={this.state.collection} onChange={this.handleInputChange}/>
                                        </Col>
                                </FormGroup>
                                {this.state.start_date != '0000-00-00' ? <FormGroup row>
                                        <Label md={5}>Start Date:</Label>
                                        <Col md={7}>
                                            <Input type="date" id="start_date" name="start_date" value={this.state.start_date} onChange={this.handleInputChange} />
                                        </Col>
                                </FormGroup> : null }
                                <FormGroup row>
                                        <Label md={5}>Launch Date:</Label>
                                        <Col md={7}>
                                            <Input type="date" id="launch_date" name="launch_date" value={this.state.launch_date} onChange={this.handleInputChange}/>
                                        </Col>
                                </FormGroup>
                          </Form>
                        </CardText>
                    </CardBody>
                </Card>
                     <Card className="mt-3">
                        <p id="card-header">Additional Info</p>
                       <CardBody>
                        <CardText>
                            <Form>
                                <FormGroup row>
                                        <Label md={5}>Website Link:</Label>
                                        <Col md={7}>
                                            <Input type="text" id="website_link" name="website_link" value={this.state.website_link} onChange={this.handleInputChange}  />
                                        </Col>
                                </FormGroup>
                                <FormGroup row>
                                        <Label md={5}>Inspiration Link:</Label>
                                        <Col md={7}>
                                            <Input type="text" id="inspiration_link" name="inspiration_link" value={this.state.inspiration_link} onChange={this.handleInputChange}/>
                                        </Col>
                                </FormGroup>
                                <FormGroup row>
                                        <Label md={5}>Product Image Inspiration Link:</Label>
                                        <Col md={7}>
                                            <Input type="text" id="product_image_inspiration" name="product_image_inspiration" value={this.state.product_image_inspiration}
                                                            onChange={this.handleInputChange}/>
                                        </Col>
                                </FormGroup>
                          </Form>
                        </CardText>
                    </CardBody>
                 </Card>
                 <div id="notes">
                    <p id="notes-heading">Description <span className="fa fa-pencil-square-o"></span></p>
                    <Input type="textarea" id="desc-notes" name="description" value={this.state.description} onChange={this.handleInputChange} />
                 </div>                      
                 </div>              
                    <div className='col-12 col-sm-9' id="product-desc-mid">
                          <ProductTab productDetails = {this.state} code ={this.props.code}/>
                    </div>
            </div>
        );
    }
}

export default ProductMatrix;   