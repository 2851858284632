import React , { Component } from 'react';
import { Nav, Navbar, NavbarToggler, Collapse, NavItem, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import AllProducts from './AllProductsComponent';
import Job from './JobComponent';
import ProductMatrix from './ProductMatrixComponent';
import Settings from './SettingsComponent';
import Material from './MaterialComponent';
import Vendor from './VendorComponent';
import Sales from './SalesComponent';
import FirstPage from './FirstPageComponent';
import CommonInventory from './CommonInventoryComponent';
import { Redirect } from 'react-router-dom';
import DropDown from './DropDown.js'


const loggedIn = sessionStorage.getItem('email')

class Home extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isNavOpen: false,
        home: '',
        
        username: sessionStorage.getItem('name'),
        email: sessionStorage.getItem('email')  
      }
      this.toggleNav = this.toggleNav.bind(this)
      this.handleLogout = this.handleLogout.bind(this)
      this._onSelect = this._onSelect.bind(this)
      this.openSettings = this.openSettings.bind(this)  
   }
    toggleNav() {
        this.setState({
          isNavOpen: !this.state.isNavOpen
        });
    }
    handleLogout(){
        sessionStorage.removeItem("email")
        window.location = "/signin";
    }
    openSettings(){

    }
    _onSelect(e){
        console.log(e.target.value)
    }
    render(){
        const RenderTab = () => {
            if(this.props.match.params.home === 'settings')
                return (
                    <Settings code={this.props.match.params.code}/>
                ); 
            else if(this.props.match.params.home === 'product')
            {
                if(this.props.match.params.code === undefined)
                {
                    return (<AllProducts /> );
                }
                else
                {
                    return ( <ProductMatrix code={this.props.match.params.code}/> );
                }
            }
            else if(this.props.match.params.home === 'new-product')
                return (
                    <ProductMatrix code={this.props.match.params.code}/> 
                );                                                      
            else if(this.props.match.params.home === 'job')
                return (
                    <Job code={this.props.match.params.code} />
                ); 
            else if(this.props.match.params.home === 'material')
                return (
                    <Material code={this.props.match.params.code} />
                );
            else if(this.props.match.params.home === 'vendor')
                return (
                    <Vendor code={this.props.match.params.code}/>
                ); 
            else if(this.props.match.params.home === 'sales')
                return (
                    <Sales code={this.props.match.params.code}/>
                );
            else if(this.props.match.params.home === 'inventory')
                return (
                    <CommonInventory />
                );
            else
                return (
                    <FirstPage />//home component
                );
    }
    
    if(loggedIn === null) {
        return (<Redirect to='/signin'/>);
      }
    else{
        return(
            <div className="container-fluid">
                <div className="row " id="header">
                    <h4 className='mt-2'>Berrylush</h4>
                    <div className='col-12 col-sm-11'>
                        <Navbar color="faded" light expand="sm" >                    
                            <NavbarToggler onClick={this.toggleNav} pl-0="true" />
                            <Collapse isOpen={this.state.isNavOpen} navbar>
                                <Nav navbar>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home'> Home</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/product'>Products</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/material'>Material</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/inventory'>Inventory</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/job'>Jobs</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/vendor'>Vendors</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/sales'>Sales</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-link header-link"  to='/home/settings'>Settings</NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                            <DropDown email={this.state.email} username={this.state.username} handleLogout={this.handleLogout} 
                            openSettings={this.openSettingss}/>
                        </Navbar>
                            
                    </div>
                </div>
                    <RenderTab />
        </div>
            
        );
      }
  }
    
}

export default Home;   