import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';

class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventory_arr :[],
        }
     }
    componentDidMount(){
         axios({
            method: 'post',
            url: Url+'/inventory/readForSku.php',
            headers: { 'content-type': 'application/json' },
            data : this.props.code
            })
            .then(result => {
                var arr = []
                for(var i=0; i<result.data.length; i++){
                    arr.push({
                        sku : result.data[i].sku,
                        bin_location : result.data[i].bin_location,
                        bin_type : result.data[i].bin_type,
                        inventory : result.data[i].inventory,
                        capacity : result.data[i].capacity,
                    })
                }
                this.setState({
                    inventory_arr : arr
                })
            })
            .catch(error => this.setState({ error: error.message }));
    }
    render(){
        return(
            <div className="p-3" id="inventory">
                        <Table className="mt-3">
                            <thead>
                                <tr>
                                    <td><strong>SKU</strong></td>
                                    <td><strong>Bin Location</strong></td>
                                    <td><strong>Bin Type</strong></td>
                                    <td><strong>Inventory</strong></td>
                                    <td><strong>Capacity</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.inventory_arr.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>{item.sku}</td>
                                        <td>{item.bin_location}</td>
                                        <td>{item.bin_type}</td>
                                        <td>{item.capacity}</td>
                                        <td>{item.inventory}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>
                </div>
            
        );
    }
}

export default Inventory;