import React, { Component } from 'react';
import { Button, Form, FormGroup, Col, Input,  Label, FormFeedback } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';

class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            email: '',
            touched: {
                password: false,
                email: false,
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
    handleSubmit=(event)=>{
        event.preventDefault()
          const form_data = {
            "email" : this.state.email,
            "password" : this.state.password,
          }
          axios({
            method: 'post',
            url: Url+'/login.php',
            headers: { 'content-type': 'application/json' },
            data: form_data
          }).then(result => {
                  if(result.data === 'empty')
                  {
                      alert("Invalid email or password")
                  }
                  else if(result.data['name'] !== undefined || result.data['email'] !== undefined || result.data['user_id'] !== undefined)
                  {  
                      this.onSetResult(result.data);
                  }
                  else{
                      alert(result.data)
                  }
            })
            .catch(error => this.setState({ error: error.message }));
    }
    handleBlur = (field) => (evt) => {
        this.setState({
          touched: { ...this.state.touched, [field]: true },
        })
    }
    validate(email, password) {

        const errors = {
            password: '',
            email: '',
        };
        
        if (this.state.touched.password && password.length < 1)
            errors.password = 'This field is required';
        
        if (this.state.touched.email && email.length < 1)
            errors.email = 'This field is required';
        

        return errors;
    }
    onSetResult(result){
        sessionStorage.setItem("user_id", result['user_id'])
        sessionStorage.setItem("name", result['name'])
        sessionStorage.setItem("email", result['email'])
        window.location = "/home"
    }
    render(){
        const errors = this.validate(this.state.email, this.state.password)
        return(
            <Form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                        <Label htmlFor="email" md={3}>EMAIL ID</Label>
                        <Col md={12} className="mt-2" >
                            <Input type="email" id="email" name="email"
                                                    value={this.state.email}
                                                    valid={errors.email === ''}
                                                    invalid={errors.email !== ''}
                                                    onBlur={this.handleBlur('email')}
                                                    onChange={this.handleInputChange} />
                            <FormFeedback>{errors.email}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="password" md={3}>PASSWORD</Label>
                        <Col md={12} className="mt-2">
                            <Input type="password" id="password" name="password"
                                                    value={this.state.password}
                                                    valid={errors.password === ''}
                                                    invalid={errors.password !== ''}
                                                    onBlur={this.handleBlur('password')}
                                                    onChange={this.handleInputChange} />
                            <FormFeedback>{errors.password}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                            <Col md={12} className="mt-4">
                                <Button type="submit" color="primary" id="login_btn">
                                    Sign In
                                </Button>
                            </Col>
                    </FormGroup> 
            </Form>
        )
    }
}

export default Signin;