import React, { Component } from 'react';
import { Input, Label, Col } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import PropTypes from "prop-types";

class TotalCost extends Component{
constructor(props) {
    super(props)
    this.state = {
        other_cost : 0,
        factory_overhead : 0
    }
    this.handleOnChange = this.handleOnChange.bind(this)
}
componentDidMount(){

}
handleOnChange (event){
    if (typeof this.props.getCost === 'function') {
        this.props.getCost(event.target)
    }
}
render(){
    return(
        <div className="p-3" id="totalcost">
             <div className="container-fluid">
                 <div className="row">
                     <div className="col-12 col-sm-5 offset-sm-3 mt-5">
                         <div className="d-flex p-1">
                            <div className="p-2 cost-label">Total Process Cost :</div>
                            <div className="p-2 cost-value">{this.props.total_process_cost}</div>
                         </div>
                         <div className="d-flex p-1">
                            <div className="cost-label p-2">Total Bom Cost :</div>
                            <div className="cost-value p-2">{this.props.total_bom_cost}</div>
                         </div>
                         <div className="d-flex p-1">
                            <div className="cost-label p-2">Other Cost :</div>
                            <div className="cost-value p-2"><input type="number" value={this.state.other_cost} name="other_cost" onChange={this.handleOnChange}/></div>
                         </div>
                         <div className="d-flex p-1">
                            <div className="cost-label p-2">Factory Overhead :</div>
                            <div className="cost-value p-2"><input type="number" value={this.state.factory_overhead} name="factory_overhead" onChange={this.handleOnChange}/></div>
                         </div>
                     </div>
                 </div>
             </div>
                     <div id="div2">
                       <p><strong>Cost: </strong><Input type="number" value={this.props.cost} name="cost" onChange={this.handleOnChange}/> </p>
                     </div>
                     <div id="div3">
                       <p><strong>B2B Price: </strong> <Input type="number" value={this.props.b2b_prices} name="b2b_prices" onChange={this.handleOnChange}/></p>
                     </div>
                     <div id="div4">
                        <p><strong>MRP: </strong><Input type="number" value={this.props.mrp} name="mrp" onChange={this.handleOnChange}/></p>
                     </div>
                     <div id="div5">
                        <p><strong>Standard Selling Price: </strong> <Input type="number" value={this.props.standard_selling_price} name="standard_selling_price" onChange={this.handleOnChange}/></p>
                     </div>
        </div>
    )
 }
}
TotalCost.propTypes = {
    cost : PropTypes.number,
    b2b_prices: PropTypes.number,
    handleChange: PropTypes.func,
}

export default TotalCost;
