import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import EditSales from './EditSalesComponent';
import UploadSales from './UploadSalesComponent';

class Sales extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        collapsed: false
    }
     this.toggleNavbar = this.toggleNavbar.bind(this)
     }
     toggleNavbar() {
      this.setState({
        collapsed: !this.state.collapsed
      });
    }
      render(){
        const RenderTab = () => {
          if(this.props.code == 'all-sales')
            return(<EditSales/>)
          else if(this.props.code == 'upload')
            return(<UploadSales />)
          else
            return(<EditSales/>)
         }
        return(
            <div className='row' id="all-sales">
                 <div className='col-12 col-sm-2 mt-3' id="all-sales-left"> 
                 <Navbar color="faded" light>
                          <NavbarBrand href="/" className="mr-auto"><strong>Sales</strong></NavbarBrand>
                            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                              <Collapse isOpen={!this.state.collapsed} navbar>
                                <Nav navbar>
                                  <NavItem>
                                    <NavLink href={`/home/sales/all-sales`}>All Sales</NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href={`/home/sales/upload/`}>Upload Excel</NavLink>
                                  </NavItem>
                                </Nav>
                              </Collapse>
                      </Navbar>
                 </div>
                 <div className='col-12 col-sm-10 pt-3' id="all-sales-right">
                      <RenderTab />
                 </div>
            </div>
        );
    }
}

export default Sales;