import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import CreateMaterial  from './CreateMaterialComponent';
import EditMaterial  from './EditMaterialComponent';
import BuyMaterial from './BuyMaterialComponent';
import ViewPurchase from './ViewPurchaseComponent';
import ViewSku from './ViewSkuComponent';
import UploadExcel from './UploadExcelComponent';
import AllMaterials from './AllMaterialsComponent';
import MaterialPage from './MaterialPageComponent';
import axios from "axios";
import {Url} from './UrlComponent';
var path = Url+'/material/';

class Material extends Component {
    constructor(props) {
      super(props);
      this.state = { 
          selectedTab : 'all-materials',
          materials : [],
          material_arr : [],
          vendor_arr : [],
          searchItem : ''
      }
      this.toggleNavbar = this.toggleNavbar.bind(this)
      this.openEditor = this.openEditor.bind(this)
      this.showAll = this.showAll.bind(this)
      this.handleInput = this.handleInput.bind(this)
      this.addMaterial = this.addMaterial.bind(this)
  }
  componentDidMount(){
      axios.get(Url+'/material/read_all_materials.php').then(result => {
      axios.get(Url+'/material/readImages.php').then(res => {
          var flag =0;
          var arr = []
          for(let i=0; i<result.data.length; i++){  // iterate over response and assign to array   
              for(let j=0; j < res.data.length; j++){
                  if(result.data[i].msku == res.data[j].msku){
                      arr.push({
                          imgSrc: path +res.data[j].image_loc,
                          msku:result.data[i].msku,
                          title:result.data[i].title,
                          description:result.data[i].description,
                          width:result.data[i].width,
                          color:result.data[i].color,
                          rate:result.data[i].rate,
                          opening_quantity:result.data[i].opening_quantity,
                          quantity:result.data[i].quantity,
                          vendor_name:result.data[i].vendor_name,
                          lifetime_quantity:result.data[i].lifetime_quantity,
                          checked : false
                      })
                      flag =1;
                      break
                  }
                  else{
                      flag =0
                  }
              }
              if(flag == 0){
                  arr.push({
                      imgSrc: path+'/images/no-image.png',
                      msku:result.data[i].msku,
                      title:result.data[i].title,
                      description:result.data[i].description,
                      width:result.data[i].width,
                      color:result.data[i].color,
                      rate:result.data[i].rate,
                      opening_quantity:result.data[i].opening_quantity,
                      quantity:result.data[i].quantity,
                      vendor_name:result.data[i].vendor_name,
                      lifetime_quantity:result.data[i].lifetime_quantity,
                      checked : false
                  })
              }
          }
              this.setState({
                  materials : arr
              })
              this.baseState =  JSON.stringify(this.state.materials)
          })
      })
  
      axios.get(Url+'/vendor/readAllVendors.php').then(res => {
        var arr1=[]
        for(var i=0; i<res.data.length; i++){
            arr1.push({
                id: res.data[i].vendor_id,
                value: res.data[i].vendor_name
            })
        }
        this.setState({ vendor_arr: arr1 })
      })
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }
  handleInput = (childData) =>{
     const {name, value} = childData
     var arr = [...JSON.parse(this.baseState)]
     arr = arr.filter(function(item){
     return item.msku.toLowerCase().search(
         value.toLowerCase()) !== -1;
     })
     this.setState({materials: arr})
     this.setState({
         [name] : value
     })
  }
  renderTab(){
    if(this.state.selectedTab == 'all-materials')
      return(<AllMaterials  materials={this.state.materials} openEditor={this.openEditor} handleInput={this.handleInput}
         searchItem={this.state.searchItem} addMaterial = {this.addMaterial}/>)
    else if(this.state.selectedTab == 'edit-material')
      return(<EditMaterial vendor_arr={this.state.vendor_arr} materials={this.state.materials} showAll={this.showAll}/>)
    else if(this.state.selectedTab == 'create-material')
      return(<CreateMaterial showAll={this.showAll}/>)
    else if(this.state.selectedTab == 'buy-material')
      return(<BuyMaterial />)
    else if(this.state.selectedTab == 'material-purchased')
      return(<ViewPurchase />)
    else if(this.state.selectedTab == 'material-in-use')
      return(<ViewSku />)
    else if(this.state.selectedTab == 'upload')
      return(<UploadExcel />)
    else if(this.state.selectedTab !== undefined )
      return(<MaterialPage msku = {this.props.code}/>)
    else
      return(<AllMaterials/>)
  
  }
  openEditor(){
     this.setState({
        selectedTab : 'edit-material'
     })
  }
  addMaterial=()=>{
    this.setState({
      selectedTab : 'create-material'
    })
  }
  showAll = () => {
    this.setState({
        selectedTab : 'all-materials'
    },()=>{
      window.location= '/home/material'
    })
}
  render(){
      return(
              <div className='row' id="all-materials">
                  <div className='col-12 col-sm-2' id="all-materials-left"> 
                      <div className="p-3" id="material-filter-col">

                      </div>
                  </div>
                  <div className='col-12 col-sm-10 mt-3' id="all-materials-right">
                        {this.renderTab()}
                  </div>
            </div>
      );
  }
}

export default Material;