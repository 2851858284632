import React, { Component } from 'react';
import { Button, Input, Label, Table, Col } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
var job_array = []
class CreateJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
        job_title: '',
        setup_date: '',
        ship_date: '',
        color_sku: [],
        selected_color_sku :'',
        order_quantity: [],
        job_array:[],
        sizes: [],
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputChangeII = this.handleInputChangeII.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }
  handleInputChange (event) {
    const target = event.target
    const value =  target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }
  handleInputChangeII= (item,key) => (event) => {
    const target = event.target
    const value =  target.value
    const name = target.name
    if(!job_array[key]){
        job_array.push({
          size : item,
          order_quantity : value
       })
    }
    else{
       job_array[key] = {
          size : item,
          order_quantity : value
       }
    }
    this.setState({
      job_array
    })
  }

  handleSubmit(event) {
      event.preventDefault();
      const form_data = {
        "method" : "create-form",
        "job_array" : this.state.job_array,
        "job_title" : this.state.job_title,
        "color_sku" : this.state.selected_color_sku,
        "setup_date" : this.state.setup_date,
        "ship_date" : this.state.ship_date,
      }
       axios({
          method: 'post',
          url: Url+'/job/create_job.php',
          headers: { 'content-type': 'application/json' },
          data: form_data
        })
        .then(result => {
          alert(result.data)
        })
          .catch(error => this.setState({ error: error.message }))
  }

  handleSelectChange(e) {
    let {name, value} = e.target;
    this.setState({
      [name]: value,
    },()=>{
        const form_data = {
           "color_sku" : value,
            "type" : "for-color-sku"
        }
        axios({
          method: 'post',
          url: Url+'/product/readSizes.php',
          headers: { 'content-type': 'application/json' },
          data: form_data
        }).then(res =>{
            this.setState({
               sizes : res.data
            })
        })
    });
  }
  componentDidMount() {
    axios.get(Url+'/product/readColorSku.php').then(res => {
      this.setState({ 
        color_sku: res.data
      });
    });
  }
  render(){
    return(
      <div className="container">
          <div className="row">
              <div className="col-12 col-sm-2">
                  <Label htmlFor="color_sku" md={12}><strong>Color SKU</strong></Label><br/>
                  <select id="color_sku" name="selected_color_sku" value={this.state.selected_color_sku} onChange={this.handleSelectChange}>
                            {this.state.color_sku.map(function(sku, key){  return (<option key={sku} value={sku}>{sku}</option>)})} 
                  </select>
              </div>
              <div className="col-12 col-sm-3">
                 <Label htmlFor="setup_date" md={12}><strong>Setup Date</strong></Label><br/>
                 <Input type="date" id="setup_date" name="setup_date" value={this.state.setup_date} onChange={this.handleInputChange}/> 
              </div>
              <div className="col-12 col-sm-3">
                 <Label htmlFor="ship_date" md={12}><strong>Ship Date</strong></Label><br/>
                 <Input type="date" id="ship_date" name="ship_date" value={this.state.ship_date} onChange={this.handleInputChange}/> 
              </div>
              <div className="col-12 col-sm-3">
                  <Label htmlFor="job_title" md={12}><strong>Remarks</strong></Label><br/>
                  <Input type="text" id="job_title" name="job_title" value={this.state.job_title} onChange={this.handleInputChange}/>
              </div>
          </div>
          <div className="row">
             <Table className="mt-3">
                  <thead>
                      <tr>
                          <td><strong>Size</strong></td>
                          <td><strong>Order Quantity</strong></td>
                      </tr>
                  </thead>
                  <tbody>
                      {this.state.sizes.map((item,key)=>(
                        <tr>
                            <td>{item}</td>
                            <td><Col md={5}>
                                  <Input type="number" id="order_quantity" name="order_quantity" value={this.state.order_quantity[key]} onChange={this.handleInputChangeII(item,key)}/>
                                </Col>
                            </td>
                        </tr>
                      ))}
                  </tbody>
             </Table>
          </div>
            <Button type="submit" color="primary" onClick={this.handleSubmit}> Submit</Button>
                    
      </div>
    );
  }
    
}

export default CreateJob;  