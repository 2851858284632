import React , { Component, useState } from 'react';
import { Button } from 'reactstrap';
import Pagination from "./Pagination";
import { Link } from 'react-router-dom';
import axios from "axios";
import {Url} from './UrlComponent';

var path = Url+'/product/';

class AllProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: true,
            products:[],
            activePage: 6,
            categories: [],
            categories_checkboxes: [],
            collection: [],
            collection_type: '',
            archive : true,
            sample_ready : true,
            currentProducts: [],
            currentPage: null,
            totalPages: null,
            currentCategories: [],
            showLabel : 'Show More'
        }; 
        this.toggleChange = this.toggleChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.submitData = this.submitData.bind(this)
        this.handleArchive = this.handleArchive.bind(this)
        this.handleSample = this.handleSample.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
        this.onPageChanged = this.onPageChanged.bind(this)
        this.getMoreCategories = this.getMoreCategories.bind(this)
    }
    handleArchive = (event) => {
       var temp_arr = []
       var products = this.state.products
       for(let i=0; i < products.length; i++){
          for (let [name, value] of Object.entries(products[i])) {
            if(name === 'checked' && value === true){
               temp_arr.push(products[i].style_code)
            }
          }
       }
       const form_data = {
          "product_arr" : temp_arr,
          "action" : "archive",
          "type" : "base-level"
       }
       axios({
          method: 'post',
          url: Url+'/product/bulkEdit.php',
          headers: { 'content-type': 'application/json' },
          data: form_data
       }).then(res=>{
           console.log(res.data)
       })
    }
    handleCheckbox = (item,key) => event => {
      var products = this.state.products
      for (let [name, value] of Object.entries(products[key])) {
         if(name === 'checked'){
            products[key][name]= !value
         }
      }
      this.setState({
         products : products,
      },()=>{
        if(item.checked === true){
          this.setState({
              sample_ready : false,
              archive : false,
          })
        }
        else{
          var flag =0
          for(let i=0; i < products.length; i++){
              for (let [name, value] of Object.entries(products[i])) {
                if(name === 'checked' && value === true){
                   flag =1
                   break
                }
             }
             if(flag === 1){
                break
             }
          }
          if(flag === 0){
              this.setState({
                sample_ready : true,
                archive : true,
              })
          }
        }
      })
    }
    handleSample = (event) => {
      var temp_arr = []
      var products = this.state.products
      for(let i=0; i < products.length; i++){
         for (let [name, value] of Object.entries(products[i])) {
           if(name === 'checked' && value === true){
              temp_arr.push(products[i].style_code)
           }
         }
      }
      const form_data = {
         "product_arr" : temp_arr,
         "action" : "sample_ready",
      }
      axios({
         method: 'post',
         url: Url+'/product/bulkEdit.php',
         headers: { 'content-type': 'application/json' },
         data: form_data
      }).then(res=>{
          console.log(res.data)
      })
    }
    toggleChange = (item, type) => event => {
       var categories =[]
       var collection =[]
       if(type == 'category'){
          categories = this.state.categories
          categories.map(function(item2,key){
            if(item == item2){
                item2.checked = !item2.checked
            }
          })
          this.setState({
             categories
          })
       }
       else if(type == 'collection'){
          collection = this.state.collection
          collection.map(function(item2,key){
              if(item == item2){
                 item2.checked = !item2.checked
              }
          })
          this.setState({
            collection
         })
       }
        var temp2_products =[]
        var products = [...JSON.parse(this.baseState)]
        var flag = 0
        categories.map(function(item2){
          if(item2.checked == true){
              var temp_products = products.filter(products => products.product_type === item2.id)
              temp2_products = [...temp2_products,...temp_products]
              flag = 1 
          }
        })
        if(temp2_products.length > 0)
        {
            collection.map(function(item2){
              if(item2.checked == true){
                var temp_products = temp2_products.filter(products => products.collection === item2.value)
                temp2_products = [...temp2_products,...temp_products]
                flag = 1 
              }
            })
        }
        else{
            collection.map(function(item2){
              if(item2.checked == true){
                var temp_products = products.filter(products => products.collection === item2.value)
                temp2_products = [...temp2_products,...temp_products]
                flag = 1 
              }
           })
        }
        
        if(flag === 0){
          this.setState({products:[...JSON.parse(this.baseState)]})
        }
        else{
          this.setState({products:[...temp2_products]})
        }
    } 
    handleSelect = (e) => {
        const {name, value} = e.target
        this.setState({
          [name]: value,
        },()=>{
              var products = [...JSON.parse(this.baseState)]
              if(value === ''){
                 products = [...JSON.parse(this.baseState)]
              }
              else{
                 products = products.filter(products => products.collection === value)
              }
              this.setState({products})
        })
    }
    submitData(){
      var arr = this.state.rows
      var product_array = []
      if(arr.length > 1){
        for(var i=1; i < arr.length; i++){
          product_array.push({
             product_type : arr[i][0],
             title : arr[i][1],
             descritpion : arr[i][2],
             collection : arr[i][3],
             color : arr[i][4],
             color_code : arr[i][5],
             size_type : arr[i][6],
             sizes : arr[i][7],
             launch_date : arr[i][8],
             website_link : arr[i][9],
             inspiration_link : arr[i][10],
             product_image_inspiration : arr[i][11],
          })
        }
        const form_data = {
           'product_array' : product_array
        }
        axios({
          method: 'post',
          url: Url+'/product/uploadExcel.php',
          headers: { 'content-type': 'application/json' }, 
          data: form_data
        }).then(res=>{
           console.log(res.data)
        })
      }
    }
    handleInput = (event) => {
        const {name,value} = event.target
        var arr = [...JSON.parse(this.baseState)]
        arr = arr.filter(function(item){
        return item.color_sku.toLowerCase().search(
            event.target.value.toLowerCase()) !== -1;
        })
        if(arr.length === 0){
          this.setState({
              products: [...JSON.parse(this.baseState)]
          })
           
        }
        else{
          this.setState({products: arr})
        }
        
        this.setState({
            [name] : value
        })
    }
    handleSearch = (event) => {
      if(event.keyCode === 13){
        axios({
          method: 'post',
          url: Url+'/product/searchProduct.php',
          headers: { 'content-type': 'application/json' },
          data: this.state.searchItem.toUpperCase()
        })
        .then(result => {
          if(result.data === "success"){
            window.location="/home/product/"+this.state.searchItem.toUpperCase()
          }
          else{
             alert("Product Not Found!")
          }
        })
        .catch(error => this.setState({ error: error.message }));
      }
        
    }
    componentDidMount(){
        axios.get(Url+'/product/readAll.php').then(res => {
          axios.get(Url+'/product/readAllImages.php').then(res2 => {
              var arr =[]
              var flag =0;
              for(var i=0; i < res.data.length; i++){
                 for(var j=0; j < res2.data.length; j++){
                    if(res.data[i].color_sku == res2.data[j].color_sku){
                      arr.push({
                        imgSrc: res2.data[j].image_loc,
                        color_sku : res.data[i].color_sku,
                        group_id : res.data[i].group_id,
                        product_type : res.data[i].product_type,
                        title : res.data[i].title,
                        description : res.data[i].description,
                        collection : res.data[i].collection,
                        cost : res.data[i].cost,
                        mrp : res.data[i].mrp,
                        b2b_prices : res.data[i].b2b_prices,
                        standard_selling_price : res.data[i].standard_selling_price,
                        start_date : res.data[i].start_date,
                        launch_date : res.data[i].launch_date,
                        checked : false,
                      })
                      flag =1;
                      break
                    }
                    else{
                      flag =0
                    }
                 }
                 if(flag == 0){
                  arr.push({
                    imgSrc : path+'/images/no-image.png',
                    color_sku : res.data[i].color_sku,
                    style_code : res.data[i].style_code,
                    product_type : res.data[i].product_type,
                    title : res.data[i].title,
                    description : res.data[i].description,
                    collection : res.data[i].collection,
                    cost : res.data[i].cost,
                    mrp : res.data[i].mrp,
                    b2b_prices : res.data[i].b2b_prices,
                    standard_selling_price : res.data[i].standard_selling_price,
                    start_date : res.data[i].start_date,
                    launch_date : res.data[i].launch_date,
                  })
                 } 
              }
              this.setState({ 
                  products: arr
              })
              this.baseState =  JSON.stringify(this.state.products)
          })
        }) 
        axios.get(Url+'/product/readCategories.php').then(res => {
            var arr = []
            for(let i=0; i<res.data.length; i++){
                arr.push({
                   id: res.data[i].id,
                   value: res.data[i].value,
                   code: res.data[i].code,
                   checked: false,
                })
            }
            this.setState({
               categories: arr
            },()=>{
               var currentCategories = arr.slice(0,5)
               this.setState({
                 currentCategories 
               })
            })
        })
        axios.get(Url+'/product/readCollection.php').then(res => {
          var arr = []
          for(let i=0; i<res.data.length; i++){
              arr.push({
                value:res.data[i],
                checked: false,
              })
          }
          this.setState({
             collection: arr
          })
      })     
    }
    onPageChanged = data => {
      const { products } = this.state
      const { currentPage, totalPages, pageLimit } = data;
  
      const offset = (currentPage - 1) * pageLimit;
      const currentProducts = products.slice(offset, offset + pageLimit);
  
      this.setState({ currentPage, currentProducts, totalPages });
    }
    getMoreCategories = ()=>{
      if(this.state.showLabel === "Show More"){
        this.setState({
          currentCategories : [...this.state.categories],
          showLabel : 'Show Less'
        })
      }
      else{
        this.setState({
          currentCategories : this.state.categories.slice(0,5),
          showLabel : 'Show More'
        })
      }
       
    }
    render(){
      const {
        products,
        currentProducts,
        currentPage,
        totalPages
      } = this.state
      const totalProducts = products.length
      if (totalProducts === 0) return null
      const ShowAllProducts = () =>
      {
          return(
          <div className="product-divs">
              <div className="row product-row">
                  <div className= "p-3"></div>
                  <div className="row product_div_heading">
                    <div className= "col-sm p-3 product-cell"><strong>Image</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>Color Sku</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>Style Code</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>Title</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>Collection</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>Cost</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>Mrp</strong></div>
                    <div className= "col-sm p-3 product-cell"><strong>B2B Prices</strong></div>
                  </div>
              </div>
                  {products.map((product, key) =>{
                    return (
                      <div key={key} className="row product-row-data">
                                <div className= "p-2">
                                    <label className="custom-checkbox">
                                      <input type="checkbox" onChange={this.handleCheckbox(product,key)} name={product.color_sku} defaultChecked={product.checked} />
                                      <span className="checkmark"></span>
                                    </label>
                                </div>
                             <Link className="product-a" to={`/home/product/${product.color_sku}`} params={{ color_sku: product.color_sku }}>                         
                                <div className="row product_div pr-3">
                                  <div className="col-sm p-2  product-cell"><img className="img-thumbnail" src={product.imgSrc} alt="Image"/></div> 
                                  <div className="col-sm p-2 product-cell">{product.color_sku}</div>
                                  <div className="col-sm p-2 product-cell">{product.style_code}</div> 
                                  <div className="col-sm p-2 product-cell">{product.title}</div>
                                  <div className="col-sm p-2 product-cell">{product.collection} </div>  
                                  <div className="col-sm p-2 product-cell">{product.cost}</div>
                                  <div className="col-sm p-2 product-cell">{product.mrp}</div>
                                  <div className="col-sm p-2 product-cell">{product.b2b_prices}</div>
                                </div>
                              </Link>
                          </div>
                    )
                })}
            </div>
          )             
      }
        return (
                <div className='row' id="all-products">
                    <div className='col-12 col-sm-2' id="all-products-left">
                        <div className="p-3" id="product-filter-col">
                          <p className="filter-label">Categories</p>
                          {this.state.currentCategories.map((item)=>(
                              <label className="custom-checkbox">{item.value}
                                  <input type="checkbox" onChange={this.toggleChange(item,'category')} name={item.id} defaultChecked={item.checked} />
                                  <span className="checkmark"></span>
                              </label>                  
                          ))}
                          <p className="more-label" onClick={this.getMoreCategories}>{this.state.showLabel}</p>
                        <br/>
                        <p className="filter-label">Collection</p>
                            {this.state.collection.map((item,id)=>(
                              <label className="custom-checkbox">{item.value}
                                  <input type="checkbox" onChange={this.toggleChange(item,'collection')} name={item.value} defaultChecked={item.checked} />
                                  <span className="checkmark"></span>
                              </label>             
                            ))}
                        
                        </div> 
                    </div>
                    <div className='col-12 col-sm-10 mt-3' id="all-products-right"> 
                          <h5><span className="count-display">Products</span> &nbsp; 
                          <span className="show-count-span">&nbsp; {this.state.products.length} results</span>
                          <span><Link to={`/home/${"new-product"}`}><Button className="new-product-btn" id="new-product-btn"><i class="fa fa-plus" aria-hidden="true"></i> Add</Button></Link></span></h5>
                        <div className="container-fluid mt-3" id="all-row-container">
                            <div className="row pl-2 product-button-div">
                                  <div className="col-12 col-sm-4">
                                    {/* <Button className="new-product-btn ml-2 p-1" id="sample-btn" onClick={this.handleSample} disabled={this.state.sample_ready}> <i class="fa fa-check" aria-hidden="true"></i>Sample Ready</Button>
                                    <Button className="new-product-btn ml-2 p-1"  onClick={this.handleArchive} disabled={this.state.archive}><i class="fa fa-archive" aria-hidden="true"></i> Archive</Button> */}
                                  </div>
                                  <div className="col-12 col-sm-3 p-2">
                                      <div className="form-group has-search">
                                          <span className="fa fa-search form-control-feedback"></span>
                                          <input className="form-control" type="text" placeholder="Search by color sku.." id="search-bar" name="searchItem" value={this.state.searchItem} onChange={this.handleInput} onKeyUp={this.handleSearch}/>
                                      </div>
                                      
                                  </div>
                                  <div className="col-12 offset-sm-2 col-sm-3 p-2" style={{textAlign:'right'}}>
                                      {/* <Pagination  totalRecords={totalProducts}  pageLimit={18}  pageNeighbours={1} onPageChanged={this.onPageChanged} /> */}
                                  </div>
                            </div>
                              <ShowAllProducts />
                        </div>
                    </div>
                </div>
          
          );
        
    }

}

export default AllProducts;
