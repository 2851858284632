import React, { Component } from 'react';
import Signin from "./SigninComponent";
import Signup from "./SignupComponent";

class UserAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link_type: 'signin',
            color1: '#424242',
            color2: '#424242',
        }
        this.handleLink = this.handleLink.bind(this)
    }
    handleLink=(link_type)=> (event)=>{
        if(link_type === 'signin'){
            this.setState({
                color1 : '#2196f3',
                color2: '#424242',
                link_type: 'signin',
            })
        }
        else{
            this.setState({
                color1 : '#424242',
                color2: '#2196f3',
                link_type: 'signup'
            })
        }
    }
    render(){
        return(
            <div className="container-fluid" id="main-page">
                    <h1 id="company-logo">Berrylush</h1>
                    <h6 id="company-subheading">Product Lifecycle Management Tool</h6>
                    <div className="row mt-3" id="login-row-div">
                        <div className="col-12 col-sm-6 offset-sm-3" id="login-col-div">
                            <div className="container-fluid pt-3 pl-5" id="login-form-div">
                                <button className="credentials" style={{color: this.state.color1}} onClick={this.handleLink('signin')}>Sign In</button> &nbsp; &nbsp;
                                 | &nbsp; &nbsp; <button className="credentials" style={{color: this.state.color2}} onClick={this.handleLink('signup')}>Sign up</button>
                                <div className="row" style={{height:'52vh', overflowY:'auto'}}>
                                    <div className="col-12 col-sm-8 offset-sm-1 pt-4">
                                        {this.state.link_type === 'signin'?<Signin /> : <Signup />}
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                    
                    
            </div>
        )
    }
}


export default UserAuthentication;