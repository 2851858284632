import React, { Component } from 'react';
import UserAuthentication from './UserAuthentication';
import Home from './HomePage';
import { Switch, Route, Redirect } from 'react-router-dom';

class Front extends Component {
  render() {
    return (
      <Switch>
          <Route exact path="/signin" component={UserAuthentication} />
          <Route exact path="/signup" component={UserAuthentication} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/home/:home" component={Home}/>
          <Route exact path="/home/:home/:code" component={Home}/>
          <Redirect to="/signin" />
     </Switch>
        
    );
  }
}

export default Front;
