import React, { Component } from 'react';
import { Button, Form, FormGroup, Col, Input, Label } from 'reactstrap';
import axios, { post } from "axios";
import {Url} from './UrlComponent';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const imageMaxSize = 1000000000 //bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item)=> {return item.trim()})
var temp = []
var image = []

class CreateMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
        image : null,
        img_array : [],
        isDragActive: false,
        msku: '',
        title: '',
        description: '',
        vendors: [],
        selected_vendor: '',
        fabric_types: ['Knitted', 'Woven'],
        selected_fabric_type: '',
        gsm: '',
        unit: '',
        width: '',
        weight: '',
        color: '',
        rate: '',
        quantity: '',
        message:'',
    }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleSizesNUnit = this.handleSizesNUnit.bind(this)
      this.handleOnDrop = this.handleOnDrop.bind(this)
      this.handleClose = this.handleClose.bind(this)
      this.fileUpload = this.fileUpload.bind(this)
    }
handleInputChange(event) {
  const target = event.target;
  const value =  target.value;
  const name = target.name;
  
  this.setState({
    [name]: value
  },()=>{
    var regex = /^[A-Za-z0-9 ]+$/
    if(name === 'msku' && !regex.test(value) && value !== ''){
        this.setState({
            error_msg : 'Special characters not allowed!'
        })
    }
  });
  this.setState({
      error_msg : ''
  })
}
    
handleSubmit(event) {
  if(this.state.msku !== '' && this.state.material_type !== '' && this.state.material_size !== '' && this.state.rate !== null && this.state.quantity !==null && this.state.selected_vendor !== null){
    const form_data = {
      "msku": this.state.msku,
      "title" : this.state.title,
      "description" : this.state.description,
      "material_type" : this.state.material_type,
      "material_size" : this.state.material_size,
      "material_unit" : this.state.material_size_array.unit,
      "color" : this.state.color,
      "rate" : this.state.rate,
      "quantity" : this.state.quantity,
      "vendor" : this.state.selected_vendor,
    }
    axios({
        method: 'post',
        url: Url+'/material/create.php',
        headers: { 'content-type': 'application/json' },
        data: form_data
    })
        .then(result => {
          if(result.data === 'successful')
          { 
              if(this.state.image != null){
                this.fileUpload(this.state.image[0], this.state.img_array[0].imgName, this.state.msku).then((response)=>{
                    // alert(response.data)
                    if(response.data === 'successful'){
                        this.setState({
                            success_msg : 'Material is created!',
                            msku: '',
                            title: '',
                            description: '',
                            material_type: '',
                            material_size: '',
                            unit: '',
                            color: '',
                            rate: '',
                            quantity: '',
                            selected_vendor: ''
                        })
                    }
                    else if(response.data === 'error'){
                        this.setState({
                          error_msg : 'Material is created. Error in uploading file. Try again!'
                        })
                    }
                    else if(response.data === 'file exists'){
                        this.setState({
                          error_msg : 'This file already exists!'
                        }) 
                    }
                }) 
              }
          }
          else{
              this.setState({
                  error_msg : result.data
              })
          }
        })
        .catch(error => this.setState({ error: error.message }))
  }
  else{
      this.setState({
          error_msg : 'Please enter correct details!'
      })
  }
}
fileUpload(file,img_name,msku){
    const url = Url+'/material/saveImage.php'
    const formData = new FormData()
    formData.append('file',file)
    formData.append('img_name',img_name)
    formData.append('msku',msku)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return  post(url, formData,config)
}

componentDidMount() {
  axios.get(Url+'/vendor/readAllVendors.php').then(res => {
      var j;
      var arrs = new Array();
      for(j=0; j<res.data.length; j++){  // iterate over response and assign to array                 
          arrs.push({
              id : res.data[j].vendor_id,
              name:res.data[j].vendor_name,
          })
      }
      this.setState({ 
        vendors: arrs,
    })
  })
}
handleSelectChange(e) {
  let {name, value} = e.target;
  this.setState({
      [name]: value,
  }, () => {
      axios.post(Url+'/material/read_material_size.php',{"material" : value}).then(res => {
        this.setState({ 
          material_size_array:{
              sizes: res.data['sizes'],
              unit: res.data['unit'],
            }
          })
        })
      })
      this.setState({
        error_msg : ''
     })
}
handleSizesNUnit(e) {
    let {name, value} = e.target;
    this.setState({
      [name]: value,
     });
     this.setState({
      error_msg : ''
   })
}
handleOnDrop = (acceptedFiles, rejectedFiles) => {
    if(rejectedFiles && rejectedFiles.length > 0){
      this.verifyFile(rejectedFiles)
    }
    if(acceptedFiles && acceptedFiles.length > 0){
       const isVerified = this.verifyFile(acceptedFiles)
       if(isVerified){
          // imageBase64Data
          const currentFile = acceptedFiles[0]
          image.push(acceptedFiles[0])
          this.setState({
             image 
          })
          const myFileItemReader = new FileReader()
          myFileItemReader.addEventListener("load", ()=>{
              temp.push({
                imgSrc: myFileItemReader.result,
                imgName: currentFile.name,
              })
            this.setState({
                img_array : temp
            })
          }, false)
          myFileItemReader.readAsDataURL(currentFile)
          
       }
    }
}
handleClose= (idx) =>(e) => {
    var img_array = [...this.state.img_array]
    img_array[idx] =  undefined
    this.setState({
        img_array
    })
}
verifyFile = (files) => {
    if(files && files.length > 0){
      const currentFile = files[0]
      const currentFileType = currentFile.type
      const currentFileSize = currentFile.size
      if(currentFileSize > imageMaxSize){
        alert("This file is not allowed. " + currentFileSize + " bytes is too large")
      }
      if(!acceptedFileTypesArray.includes(currentFileType)){
        alert("This file is not allowed. Only images are allowed.")
        return false
      }
      return true
    }
}
goBack = () =>{
   this.props.showAll()
}
render(){
      const img_array = this.state.img_array
      return(
        <div className="container pt-3 create">
          <h5 className="p-2">Add Material Details</h5>
          <div className="row">
              <div className="col-12 offset-sm-1 col-sm-6 pt-4">
                <Form>
                    <FormGroup row>
                        <Label htmlFor="msku" md={3}>MSKU</Label>
                          <Col md={9}>
                              <Input type="text" id="msku" name="msku" value={this.state.msku} onChange={this.handleInputChange} />
                          </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="title" md={3}>Title</Label>
                          <Col md={9}>
                              <Input type="text" id="title" name="title" value={this.state.title} onChange={this.handleInputChange}/>
                          </Col>
                    </FormGroup>
                   
                    <FormGroup row>
                      <Label htmlFor="fabric_material_type" md={3}>Type</Label>
                          <Col md={9}>
                              <Select value={this.state.selected_fabric_type} name="selected_fabric_type" onChange={this.handleSelectChange}>
                                  {this.state.fabric_types.map(function(item, key){  return (<MenuItem value={item}>{item}</MenuItem>)})}
                              </Select>
                          </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor="material_size" md={3}>Width</Label>
                          <Col md={9}>
                            <Input type="number" id="rate" name="rate" value={this.state.rate} onChange={this.handleInputChange}/>
                          </Col>
                    </FormGroup>
                    {this.state.selected_fabric_type === 'Knitted' ?
                    <FormGroup row>
                      <Label htmlFor="gsm" md={3}>GSM</Label>
                          <Col md={9}>
                            <Input type="number" id="gsm" name="gsm" value={this.state.gsm} onChange={this.handleInputChange}/>
                          </Col>
                    </FormGroup> : null}
                    {this.state.selected_fabric_type === 'Knitted' ?
                    <FormGroup row>
                      <Label htmlFor="weight" md={3}>Weight(in kg)</Label>
                          <Col md={9}>
                            <Input type="number" id="weight" name="weight" value={this.state.weight} onChange={this.handleInputChange}/>
                          </Col>
                    </FormGroup> : null}
                    <FormGroup row>
                      <Label htmlFor="color" md={3}>Color</Label>
                          <Col md={9}>
                              <Input type="text" id="color" name="color" value={this.state.color} onChange={this.handleInputChange} />
                            </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor="rate" md={3}> Rate</Label>
                          <Col md={9}>
                              <Input type="number" id="rate" name="rate" value={this.state.rate} onChange={this.handleInputChange}/>
                            </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor="quantity" md={3}> Quantity(in metre)</Label>
                          <Col md={9}>
                              <Input type="number" id="quantity" name="quantity" value={this.state.quantity} onChange={this.handleInputChange} readOnly/>
                             
                          </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor="vendor" md={3}> Vendor Name</Label>
                          <Col md={9}>
                              <Select value={this.state.selected_vendor} name="selected_vendor" onChange={this.handleInputChange}>
                                  {this.state.vendors.map(function(item, key){  return (<MenuItem value={item.id}>{item.name}</MenuItem>)})}
                              </Select>
                          </Col>
                    </FormGroup>
                    
                    
            </Form>
            </div>
                <div className="col-12 offset-sm-1 col-sm-4 pt-4">
                    <div className="mat-images-div">
                      <p id="mat-image-p">Material Image</p>
                        {img_array[0] !== undefined ? <img src={img_array[0].imgSrc} width="100%" height="100%"/> :
                        <Dropzone onDrop={this.handleOnDrop} accept={acceptedFileTypes} maxSize={imageMaxSize} >
                                    {({getRootProps, getInputProps, isDragActive}) => {
                                            return (
                                                <div
                                                    {...getRootProps()}
                                                    className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                                    >
                                                    <input {...getInputProps()} name="file[]" />
                                                    {
                                                        isDragActive ?
                                                        '':
                                                        <p className="ml-5">Add Image here</p>
                                                    }
                                                </div>
                                            )
                                    }}
                            </Dropzone>}
                        </div>
                        <div id="description">
                           <p id="mat-desc-p">Description</p>
                              <Input type="textarea"  name="description" value={this.state.description}  onChange={this.handleInputChange} />
                        </div>
                </div>
                
            </div>
            <div className="row mt-3">
                <Col md={{size: 2, offset: 2}}><span style={{color : 'green', fontWeight:'600'}}>{this.state.message}</span></Col>
                <Col md={{size: 1, offset: 4}}>
                    <Button type="button" className="back" onClick={this.goBack}>
                        Back
                    </Button>
                </Col>
                <Col md={{size: 1}} onClick={this.handleSubmit}>
                    <Button type="submit" className="save-vendor ml-3">
                        Submit
                    </Button>
                </Col>
            </div>
        </div>
      );

      }
}

export default CreateMaterial;  