import React, { Component } from 'react';
import { Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import CreateJob  from './CreateJobComponent';
import EditJob from './EditJobComponent';
import UploadJob from './UploadJobComponent';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import { CSVLink } from "react-csv";
import axios from "axios";
import {Url} from './UrlComponent';

class Job extends Component {
    constructor(props) {
      super(props);
      this.state = { 
          which_type:'',
          rows: [],
          cols: [],
       }
       this.onRadiochange = this.onRadiochange.bind(this)
       this.fileHandler = this.fileHandler.bind(this)
     }
    onRadiochange = (event) => {
      this.setState({
        which_type: event.target.value,
      })
    }
    fileHandler = (event) => {
      let fileObj = event.target.files[0];
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else{
          this.setState({
            cols: resp.cols,
            rows: resp.rows
          });
        }
      })     
    }
    render(){
      const RenderTab = () => {
        if(this.props.code == 'all-jobs')
          return(<EditJob/>)
        else if(this.props.code == 'create-job')
          return(<CreateJob />)
        else if(this.props.code == 'update-job')
          return(<EditJob />)
        else if(this.props.code == 'upload')
          return(<UploadJob />)
        else
          return(<EditJob/>)
       }
        return(
            <div className='row' id="all-jobs">
                 <div className='col-12 col-sm-2 mt-3' id="all-jobs-left"> 
                 <Navbar color="faded" light>
                      <NavbarBrand href="/" className="mr-auto"><strong>Jobs</strong></NavbarBrand>
                          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                              <Collapse isOpen={!this.state.collapsed} navbar>
                                  <Nav navbar>
                                    <NavItem>
                                      <NavLink href={`/home/job/all-jobs`}>All Jobs</NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink href={`/home/job/create-job`}>Create</NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink href={`/home/job/update-job`}>Update</NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink href={`/home/job/upload`}>Upload Excel</NavLink>
                                    </NavItem>
                                  </Nav>
                              </Collapse>  
                  </Navbar>
                 </div>
                 <div className='col-12 col-sm-10 pt-3 ' id="all-jobs-right">
                     <RenderTab />
                 </div>
                
            </div>
        );
    }
}

export default Job;