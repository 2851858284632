import React, { Component } from 'react';
import { Button, Form, FormGroup, Col, Input,  Label, FormFeedback } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
var today = new Date()
class BuyMaterial extends Component{
    constructor(props) {
        super(props);
        this.state = {
            materials:[{}],
            msku:'',
            title:'',
            quantity:'',
            date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            rate: null,
            total_amount: null,
            error_msg:'',
            success_msg:'',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
  
        this.setState({
          [name]: value
        },()=>{
            if(name === 'rate' || name === 'quantity'){
                this.setState({
                    total_amount : this.state.rate * this.state.quantity
                })
            }
        });
    }
    handleSelectChange(event){
       const value = event.target.value;
       this.setState({
           msku : value
       },()=>{
           var material = this.state.materials;
            for (const [key, value2] of Object.entries(material)) {
                if(value2.msku === value){
                    this.setState({
                        title : value2.title,
                        rate : value2.rate
                    })
                }
            }
       })
    }
    componentDidMount(){
        axios({
            method: 'post',
            url: Url+'/material/readMaterial.php',
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            var arr = [];
            for(var i=0; i < res.data.length; i++){
                arr.push({
                    msku: res.data[i].msku,
                    title: res.data[i].title,
                    rate: res.data[i].rate
                })
            }
            this.setState({
                materials: arr
            })
        })
    }
    handleSubmit(event) {
        event.preventDefault();
        if(this.state.msku !== '' && this.state.date !== '' && this.state.quantity !== null && this.state.rate !== null)
        {
            const form_data = {
                "msku": this.state.msku,
                "date" : this.state.date,
                "quantity" : this.state.quantity,
                "rate" : this.state.rate,
                "total_amount" : this.state.total_amount,
              };
              axios({
                method: 'post',
                url: Url+'/purchase/buyMaterial.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
              })
                .then(result => {
                    // alert(result.data)
                    if(result.data === 'successful'){
                        this.setState({
                            success_msg : 'Material is bought'
                        })
                    }
                    else{
                        this.setState({
                            error_msg : result.data
                        })
                    }
                })
                .catch(error => this.setState({ error: error.message }));
        }
        else{
            this.setState({
                error_msg : 'Please fill in all the required details!'
            })
        }
    }
    render(){
        return(
            <div className="container mt-3">
                <div className="row">
                    <div className="col-12 offset-sm-1 col-sm-6 pt-4">
                            <Form>
                                <FormGroup row>
                                    <Label htmlFor="msku" md={3}>MSKU</Label>
                                    <Col md={9}>
                                        <Select value={this.state.msku} name="msku" onChange={this.handleSelectChange}>
                                            {this.state.materials.map(function(item, key){  return (<MenuItem value={item.msku}>{item.msku}</MenuItem>)})}
                                        </Select>
                                    {/* <select id="msku" name="msku" value={this.state.msku} onChange={this.handleSelectChange}>
                                        <option>Select MSKU</option>
                                        {this.state.materials.map(function(item, key){  return (<option key={key} value={item.msku}>{item.msku}</option>)})} 
                                    </select> */}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="title" md={3}>Title</Label>
                                    <Col md={9}>
                                        {this.state.title}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="date" md={3}>Date</Label>
                                    <Col md={9}>
                                        <Input type="date" id="date" name="date" value={this.state.date} onChange={this.handleInputChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="quantity" md={3}> Quantity</Label>
                                    <Col md={9}>
                                        <Input type="number" id="quantity" name="quantity" value={this.state.quantity} onChange={this.handleInputChange} />
                                        
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="rate" md={3}> Rate</Label>
                                    <Col md={9}>
                                        <Input type="number" id="rate" name="rate" value={this.state.rate} onChange={this.handleInputChange} />
                                        
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="total_amount" md={3}> Total Amount</Label>
                                    <Col md={9}>
                                        {this.state.total_amount}                                         
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    {this.state.error_msg !== '' ? <p id="error_msg">{this.state.error_msg}</p> : null}
                                    {this.state.success_msg !== '' ? <p id="success_msg">{this.state.success_msg}</p> : null}
                                    <Button type="button" color="primary" id="purchase-submit-btn" onClick={this.handleSubmit}>Submit </Button>
                                </FormGroup>
                            </Form>
                        </div>
                        <div className="offset-sm-1 col-sm-4 pt-4">
                            <div className="mat-images_div">
                                
                            </div>
                       </div>
                    </div>
            </div>
        );
    }
}

export default BuyMaterial;