import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';

class DropDown extends Component {
    constructor(props){
        super(props)
        this.state = {
            dropDown : false,
            email : '',
            username : ''
        }
        this.showDropDown = this.showDropDown.bind(this)
        this.handleLogout = this.handleLogout.bind(this)  
    }
    showDropDown(){
        this.setState({
            dropDown : !this.state.dropDown
        })
    }
    handleLogout(){
        this.props.handleLogout()
    }
    openSettings(){

    }
    render() { 
        return (
            <div>
                <Image src={require('../assets/notify.png')} thumbnail style={{width: '35px',height:'35px', opacity:'0.8', border: 'none',background:'none'}}/>

                <Image src={require('../assets/user.png')} thumbnail style={{cursor:'pointer', width: '37px',height:'37px',  border: 'none',background:'none'}} onClick={this.showDropDown}/>
                {/* <Button id="logout" onClick={this.handleLogout}> Logout </Button>{' '} */}
                <div id="company-dropdown" style={{display : this.state.dropDown ? 'block': 'none'}}>
                    <div id="dropdown-div-1">
                        <Image src={require('../assets/user-icon.png')} thumbnail style={{width: '60px',height:'60px', border: 'none',opacity: '0.3', background:'none'}} />
                        <div style={{fontSize : '16px', fontWeight: '600', color:'#616161'}}>{this.props.username}</div>
                        <div>{this.props.email}</div>
                    </div>
                    <hr style={{marginBottom: '0px'}} />
                    <div className="p-2 dropdown-options" onClick={this.handleLogout}>Logout</div>
                    <div className="p-2 dropdown-options" onClick={this.openSettings}>Settings</div>
                </div>
            </div>
          );
    }
}
 
export default DropDown;