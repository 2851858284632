import React, { Component } from 'react';
import { Button, Form, FormGroup, Col, Input, Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
class ViewPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = { 
             purchases: [],
             status: '',
             status_array: ['In Progress', 'On Hold', 'Completed', 'Cancelled'],
             purchased_material_map : new Map(),
             error_msg:'',
             success_msg:'', 
        }
           this.handleChange = this.handleChange.bind(this)
           this.handleSubmit = this.handleSubmit.bind(this)
           this.filterList = this.filterList.bind(this)
    }
    componentDidMount(){
        axios({
            method: 'post',
            url: Url+'/purchase/readPurchase.php',
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            var arr = [];
            for(var i=0; i < res.data.length; i++){
                if(res.data[i].status === 'Completed'){
                    arr.push({
                        purchase_id: res.data[i].purchase_id,
                        msku: res.data[i].msku,
                        quantity: res.data[i].quantity,
                        date: res.data[i].date,
                        rate: res.data[i].rate,
                        total_amount: res.data[i].total_amount,
                        status: res.data[i].status,
                        check: true,
                    })
                }
                else{
                    arr.push({
                        purchase_id: res.data[i].purchase_id,
                        msku: res.data[i].msku,
                        quantity: res.data[i].quantity,
                        date: res.data[i].date,
                        rate: res.data[i].rate,
                        total_amount: res.data[i].total_amount,
                        status: res.data[i].status,
                        check: false,
                    })
                }
            }
            this.setState({
                purchases: arr
            })
            this.baseState =  JSON.stringify(this.state.purchases)
        })
    }
    handleSubmit(){
        var purchased_material_arr = []
        var purchase_id_arr = []
        if(this.state.purchased_material_map.size > 0){
            for (let [key, value] of this.state.purchased_material_map){
                purchased_material_arr.push({
                    purchase_id : value.get("purchase_id"),
                    date : value.get("date") ? value.get("date") : '',
                    quantity : value.get("quantity") ? value.get("quantity"):0,
                    rate : value.get("rate") ? value.get("rate"):0,
                    total_amount : value.get("total_amount") ? value.get("total_amount"):0,
                    status : value.get("status") ? value.get("status"):'',
                })
                if(value.get("check") === true){
                    purchase_id_arr.push(value.get("purchase_id"))
                }
            }
            const form_data = {
                "purchase_arr" : purchased_material_arr,
            }
            console.log(purchased_material_arr)
            // axios({
            //     method: 'post',
            //     url: Url+'/purchase/updatePurchase.php',
            //     headers: { 'content-type': 'application/json' },
            //     data: form_data
            //   }).then(res =>{
            //         alert(res.data)
            //         if(res.data === 'Updated and added to inventory'){
            //             var purchases = [...this.state.purchases]
            //             for(var j=0; j < purchase_id_arr.length; j++){
            //                 for(var i=0; i < purchases.length; i++){
            //                     if( purchase_id_arr[j] == purchases[i]['purchase_id']){
            //                         purchases[i]['check'] = true
            //                     }
            //                 }
            //             }
            //             this.setState({
            //                 purchases,
            //                 purchased_material_map : new Map()
            //             })
            //         }
            //   })
        }
        else{
            alert("No changes detected!")
        }
    }
    filterList(event){
        var arr = [...JSON.parse(this.baseState)]
        arr = arr.filter(function(item){
        return item.msku.toLowerCase().search(
            event.target.value.toLowerCase()) !== -1;
        })
        this.setState({purchases: arr})
    }
    handleChange = (item,idx) => (event) => {
        const {name, value} = event.target
        var purchases = [...this.state.purchases]
        var arr=this.state.purchased_material_map
        var map1 = new Map()
        if(arr.has(idx)){
            map1= arr.get(idx)
        }
        map1.set("purchase_id",parseInt(item.purchase_id))
        if(name === 'status' && value === 'Completed'){
            map1.set("check",true)
        }
        else{
            map1.set("check",false)
        }
        Object.keys(purchases[idx]).forEach(function (item) {
            if(item === name){
                purchases[idx][item] = value
                map1.set(name,value)
                if(name === 'rate'){
                    purchases[idx]['total_amount'] = value * purchases[idx]['quantity']
                    map1.set('total_amount',purchases[idx]['total_amount']) 
                }
                else if(name === 'quantity'){
                    purchases[idx]['total_amount'] = value * purchases[idx]['rate']
                    map1.set('total_amount',purchases[idx]['total_amount']) 
                }
            }
        })
        this.setState({
            purchases
        })
        arr.set(idx,map1)
        this.setState({
            purchased_material_map : arr,
        })
    }
    render(){
        return(
            <div className="container-fluid" id="purchased-div">
                <div className="row">
                    <div className="col-12 offset-sm-1 col-sm-3"><input type="text" placeholder="Search" onChange={this.filterList}/></div>
                    <div className="col-12 offset-sm-4 col-sm-3 "><Button type="button" className="ml-2 edit-btns" color="primary" onClick={this.handleSubmit}> Save</Button></div>               
                </div>
                <div className="row" >
                    <Table className="table table-hover mt-4">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td><strong>MSKU</strong></td>
                                    <td><strong>Date</strong></td>
                                    <td><strong>Quantity</strong></td>
                                    <td><strong>Rate</strong></td>
                                    <td><strong>Total Amount</strong></td>
                                    <td><strong>Status</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.purchases.map((item, key)=> (
                                   <tr>
                                        <td>{key+1}</td>
                                        <td>
                                            {item.msku}  
                                        </td>
                                        <td>
                                            {item.check ? item.date :<Input type="date" id="date" name="date" value={item.date} onChange={this.handleChange(item, key)}/> }
                                        </td>
                                        <td>
                                            {item.check ? item.quantity : <Input type="number" id="quantity" name="quantity" value={item.quantity} onChange={this.handleChange(item, key)} /> }
                                        </td>
                                        <td>
                                            {item.check ? item.rate :<Input type="number" id="rate" name="rate" value={item.rate} onChange={this.handleChange(item, key)}/> }
                                        </td>
                                        <td>
                                            {item.total_amount} 
                                        </td>
                                        <td>
                                            {item.check ? item.status :
                                            <Select value={item.status} name="status" onChange={this.handleChange(item, key)}>
                                            {this.state.status_array.map(function(item2, key2){  return (<MenuItem value={item2}>{item2}</MenuItem>)})}
                                            </Select> }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                    </Table>
                </div>
                {this.state.error_msg !== '' ? <p id="error_msg">{this.state.error_msg}</p> : null}
                {this.state.success_msg !== '' ? <p id="success_msg">{this.state.success_msg}</p> : null}
            </div>
        );
    }

}

export default ViewPurchase; 