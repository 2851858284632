import React, { Component } from 'react';
import axios from "axios";
import {Url} from './UrlComponent';
import { Table} from 'reactstrap';

var path = Url+'/product/';

class ViewSku extends Component {
    constructor(props) {
        super(props)
        this.state = {
            material_arr : [],
        }
        
    }
    componentDidMount(){
        axios({
            method: 'post',
            url: Url+'/bom/viewSku.php',
            headers: { 'content-type': 'application/json' },
          }).then(res =>{
                // var arr =[]
                // for(let i=0; i<res.data.length;i++){
                //     arr.push({
                //         bom_id : res.data[i].bom_id,
                //         msku : res.data[i].msku,
                //         style_code : res.data[i].style_code,
                //         sku : res.data[i].sku,
                //         usage_quantity : res.data[i].usage_quantity,
                //         cost : res.data[i].cost
                //     })
                // }
                // this.setState({
                //     material_arr : arr
                // })
                var arr = []
                var arr2 = []
                var img_arr2 = []
                var color_sku_array = []
                for(let i=0; i<res.data.length; i++){
                    arr.push({
                        msku : res.data[i].msku,
                        color_sku : res.data[i].color_sku
                    })
                    color_sku_array.push(...res.data[i].color_sku)
                }
                console.log(arr)
                this.setState({
                    material_arr : arr
                },()=>{
                    const form_data = {
                        "color_sku_arr" : color_sku_array
                    }
                    axios({
                        method: 'post',
                        url: Url+'/product/readImages.php',
                        headers: { 'content-type': 'application/json' },
                        data : form_data
                    }).then(res2=>{
                        
                        var msku = ''
                        for(let j=0; j<res2.data.length; j++){
                            var flag =0
                            for(let k=0; k<arr.length; k++){
                                msku = arr[k].msku
                                if(arr[k].color_sku.find(sku => sku === res2.data[j].color_sku)){
                                    img_arr2.push({
                                        imageSrc : path + res2.data[j].image_loc,
                                        color_sku : res2.data[j].color_sku
                                    })
                                    flag = 1
                                    break
                                }
                                else{
                                    flag = 0
                                }
                            }
                            if(flag === 0){
                                img_arr2.push({
                                    imageSrc : path+'/images/no-image.png',
                                    color_sku : res2.data[j].color_sku
                                })
                            }
                            arr2.push({
                                msku : msku,
                                color_sku_arr : img_arr2
                            })
                        }
                        console.log(arr2)
                    })
                })
          })
    }
    render(){
        return(
            <div className="pr-3 pl-3">
                <div className="row product_div_heading pr-3">
                    <div className="row pr-3">
                        <div className= "col-sm p-3 product-cell"><strong>MSKU</strong></div>
                        <div className= "col-sm p-3 product-cell"><strong>Color Sku</strong></div>
                    </div>
                </div>
                <div>

                </div>
                
                    {/* <Table>
                        <thead>
                            <tr>
                                <td><strong>MSKU</strong></td>
                                <td><strong>Style Code</strong></td>
                                <td><strong>SKU</strong></td>
                                <td><strong>Usage Quantity</strong></td>
                                <td><strong>Cost</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                             {this.state.material_arr.map((item,key)=>(
                                 <tr>
                                     <td>{item.msku}</td>
                                     <td>{item.style_code}</td>
                                     <td>{item.sku}</td>
                                     <td>{item.usage_quantity}</td>
                                     <td>{item.cost}</td>
                                 </tr>
                             ))}
                        </tbody>
                    </Table> */}
            </div>
        )
    }
}

export default ViewSku;