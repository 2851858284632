import React , { Component } from 'react';
import { Button, Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from "prop-types";

class Matrix extends Component {
    constructor(props) {
        super(props)
        this.state = {
            size_type : '',
            all_sizes : [],
            isSizeSelected: false,
            checkStatusArray: [],
            selectedSizes: [],
        }
      this.getSizeRange = this.getSizeRange.bind(this)
    }
    getSizeRange=(event)=>{
        this.setState({
          size_type: event.target.value,
          isSizeSelected: !this.state.isSizeSelected
        },()=>{
            var sizes = [...this.props.productDetails.sizes]
            var size_array = [...this.props.productDetails.size_array]
            let i = sizes.indexOf(this.state.size_type)
            this.setState({
                all_sizes : size_array[i]
            },()=>{
              var checkStatusArray = []
                for(let j=0; j < this.state.all_sizes.length; j++){
                    checkStatusArray.push({
                       size : this.state.all_sizes[j],
                       check: false
                    })
                }
                this.setState({
                   checkStatusArray
                })
            })
        })
        
    }
    onCheck=(sizeObject,idx)=>(e)=>{
        const {name, checked} = e.target
        var selectedSizes = [...this.state.selectedSizes]

        // set state of checkbox in matrix
        let checkStatusArray= this.state.checkStatusArray
        if(checkStatusArray[idx].size === sizeObject.size)
        {
            checkStatusArray[idx].check = checked;
            selectedSizes.push(checkStatusArray[idx].size)
        }
        this.setState({
            checkStatusArray: checkStatusArray,
            selectedSizes : selectedSizes
        })
      if (typeof this.props.getSelectedSizes === 'function') {
        this.props.getSelectedSizes(selectedSizes, this.state.size_type)
      }
    }
    getInputColor=(e)=>{                
        if (typeof this.props.getInputColor === 'function') {
            this.props.getInputColor(e.target)
        }
    }
    getColorCode=(e)=>{
        if (typeof this.props.getColorCode === 'function') {
            this.props.getColorCode(e.target)
        }
    }  
  render(){
       return(
          <div id="table_div">
              <div className="row pt-3" id="matrix-div">
                 <div className="col-12 col-sm-3 offset-sm-9">
                       <InputLabel htmlFor="age-simple">Select Size range</InputLabel>
                       <Select value={this.state.size_type}  id="size-select" name="size_type" onChange={this.getSizeRange}>
                            {this.props.productDetails.sizes.map(function(item, key){  return (<MenuItem value={item}>{item}</MenuItem>)})}
                       </Select>
                 </div>
              </div>
              <div className="row" id="color-size-matrix">
                {this.state.isSizeSelected ?<Table className="table table-hover mt-2 ml-3">
                    <thead>
                        <tr>
                            <td>Color </td>
                            <td>Color Code</td>
                            {this.state.all_sizes.map(function(size, key){
                                return (<td key={key}>{size}</td>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                       <tr>
                            <td><input type="text" name="inputColor" value={this.props.inputColor} className="form-control" onChange={this.getInputColor}/></td>
                            <td>
                                <Select  value={this.props.colorCode}  id="color" name="colorCode" onChange={this.getColorCode}>
                                    {this.props.productDetails.color_array.map(function(item, key){  return (<MenuItem value={item.color_code}>{item.color}</MenuItem>)})}
                                </Select>
                            </td>
                              {this.state.checkStatusArray.map((item,ide)=>{
                                  return(<td key={ide}>
                                    <input key={ide} type="checkbox" name={item.size} defaultChecked={item.check}  onChange={this.onCheck(item,ide)} className="form-check-input ml-1"/>
                                    </td>)
                              })} 
                       </tr>
                    </tbody>
                </Table> : null}           
              </div> 
          </div>           
        );
    }
}

Matrix.propTypes = {
  size_type : PropTypes.string,
  inputColor : PropTypes.string,
  colorCode : PropTypes.string,
  selectedSizes : PropTypes.array,
  checkStatusArray : PropTypes.array,
  getInputColor: PropTypes.func,
  getColorCode: PropTypes.func,
  getSelectedSizes : PropTypes.func,
  onCheck : PropTypes.func
}
export default Matrix;