import React, { Component } from 'react';
import { Button, Form, FormGroup, Col, Input,  Label, FormFeedback } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            password: '',
            passwordII:'',
            email: '',
            contact: '',
            role: '',
            touched: {
                name: false,
                password: false,
                email: false,
                contact: false,
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }
    
    handleSubmit(event) {
          event.preventDefault()
          const form_data = {
            "name" : this.state.name,
            "password" : this.state.password,
            "email" : this.state.email,
            "contact" : this.state.contact,
            "role" : this.state.role,
          };
            axios({
                method: 'post',
                url: Url+'/signup.php',
                headers: { 'content-type': 'application/json' },
                data: form_data
            })
                .then(result => {
                    alert(result.data)
                    window.location = "/home"
                })
                .catch(error => this.setState({ error: error.message }));
    }
    handleBlur = (field) => (evt) => {
        this.setState({
          touched: { ...this.state.touched, [field]: true },
        });
      }
    validate(name, password, email, contact) {

        const errors = {
            name: '',
            password: '',
            email: '',
            contact: '',
        };
        if (this.state.touched.name && name.length < 1)
            errors.name = 'This field is required';

        if (this.state.touched.password && password.length < 1)
            errors.password = 'This field is required';
        
        if (this.state.touched.email && email.length < 1)
            errors.email = 'This field is required';

        if (this.state.touched.contact && contact.length < 1)
            errors.contact = 'This field is required';


        return errors;
    }
    render(){
        const errors = this.validate(this.state.name, this.state.password, this.state.email, this.state.contact);
        return(
                 <Form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                        <Label htmlFor="name" md={3}>NAME</Label>
                        <Col md={12} className="mt-2">
                            <Input type="text" id="name" name="name"
                                                    value={this.state.name}
                                                    valid={errors.name === ''}
                                                    invalid={errors.name !== ''}
                                                    onBlur={this.handleBlur('name')}
                                                    onChange={this.handleInputChange}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="role" md={3}>ROLE</Label>
                        <Col md={12} className="mt-2">
                            <Input type="text" id="role" name="role"
                                                    value={this.state.role}
                                                    onChange={this.handleInputChange}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="email" md={3}>EMAIL ID</Label>
                        <Col md={12} className="mt-2">
                            <Input type="email" id="email" name="email"
                                                value={this.state.email}
                                                valid={errors.email === ''}
                                                invalid={errors.email !== ''}
                                                onBlur={this.handleBlur('email')}
                                                onChange={this.handleInputChange}  />
                            <FormFeedback>{errors.email}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="contact" md={4}>CONTACT NO.</Label>
                          <Col md={12} className="mt-2">
                              <Input type="contact" id="contact" name="contact"
                                                    value={this.state.contact}
                                                    valid={errors.contact === ''}
                                                    invalid={errors.contact !== ''}
                                                    onBlur={this.handleBlur('contact')}
                                                    onChange={this.handleInputChange} />
                              <FormFeedback>{errors.contact}</FormFeedback>
                           </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="password" md={3}>PASSWORD</Label> 
                          <Col md={12} className="mt-2">
                              <Input type="password" id="password" name="password"
                                                    value={this.state.password}
                                                    valid={errors.password === ''}
                                                    invalid={errors.password !== ''}
                                                    onBlur={this.handleBlur('password')}
                                                    onChange={this.handleInputChange}/>
                              <FormFeedback>{errors.password2}</FormFeedback>
                           </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="password" md={6}> CONFIRM PASSWORD</Label> 
                          <Col md={12} className="mt-2">
                              <Input type="password" id="password2" name="passwordII"
                                                    value={this.state.passwordII}
                                                    onChange={this.handleInputChange}/>
                           </Col>
                    </FormGroup>
                    <FormGroup row>
                           <Col md={12} className="mt-4">
                                <Button type="submit" color="primary" id="signup_btn">
                                  Sign Up
                                </Button>
                            </Col>
                    </FormGroup>
                </Form>
            
        )
    }
}

export default Signup;