import React, { Component } from 'react';
import { Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import CreateVendor  from './CreateVendorComponent';
import EditVendor from './EditVendorComponent';
import UploadVendor from './UploadVendorComponent';
import axios from "axios";
import {Url} from './UrlComponent';
import AllVendors from './AllVendorsComponent';

class Vendor extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        collapsed: false,
        selectedTab : 'all-vendors',
        vendors : [],
        poc : [],
        vendor_arr:[],
        supplier:false,
        job_work: false,
        filteredData :[],
        searchItem: ''
     }
      this.addVendor = this.addVendor.bind(this)
      this.toggleChange = this.toggleChange.bind(this)
      this.handleInput = this.handleInput.bind(this)
     }
    componentDidMount(){
      axios.get(Url+'/vendor/read_all_vendors.php').then(result => {
        var i;
        var arr = new Array();
        for(i=0; i<result.data.length; i++){  // iterate over response and assign to array
          arr.push({
              vendor_id:result.data[i].vendor_id,
              vendor_name:result.data[i].vendor_name,
              selected_vendor_type:result.data[i].vendor_type,
              selected_vendor_work:result.data[i].vendor_work,
              company:result.data[i].company,
              address:result.data[i].address,
              contact_person:result.data[i].contact_person,
              mobile_no:result.data[i].mobile_no,
              whatsapp_no:result.data[i].whatsapp_no,
              poc:result.data[i].poc,
              vendor_loc:result.data[i].vendor_loc,
            })
          }
          this.setState({ 
            vendors: arr,
            filteredData: arr
          })
          this.baseState =  JSON.stringify(this.state.vendors)
        })
        axios.get(Url+'/vendor/readPocType.php').then(res => {
          var k;
          var arr2 = new Array();
          for(k=0; k<res.data.length; k++){  // iterate over response and assign to array                 
               arr2.push(res.data[k].poc_name)
          }
          this.setState({ 
              poc: arr2,
           })
         })
        axios.get(Url+'/vendor/readVendorWork.php').then(res => {
          var j;
          var arrs = new Array();
          for(j=0; j<res.data.length; j++){  // iterate over response and assign to array                 
              arrs.push(res.data[j].value)
          }
          this.setState({ 
              vendor_arr: arrs,
           })
         })
    }
    addVendor=()=>{
        this.setState({
          selectedTab : 'create-vendor'
        })
    }
    openEditor = () =>{
        this.setState({
            selectedTab : 'edit-vendor'
        })
    }
    showAll = () => {
        this.setState({
          selectedTab : 'all-vendors'
      },()=>{
         window.location= '/home/vendor'
      })
    }
    renderTab(){
        if(this.state.selectedTab == 'all-vendors')
            return(<AllVendors vendors={this.state.vendors} addVendor={this.addVendor} openEditor={this.openEditor} 
              handleInput={this.handleInput} searchItem={this.state.searchItem}/>)
          else if(this.state.selectedTab == 'create-vendor')
            return(<CreateVendor showAll={this.showAll}/>)
          else if(this.state.selectedTab == 'edit-vendor' )
            return(<EditVendor vendor_arr ={this.state.vendor_arr} poc ={this.state.poc} showAll={this.showAll}/>)
          else if(this.state.selectedTab == 'upload')
            return(<UploadVendor />)
          else
            return(<AllVendors/>)
    }
    toggleChange = (event) =>{
       const {name, checked} = event.target
       if(checked === true & name === 'supplier'){
          this.setState({
              supplier : true,
              job_work : false
          },()=>{
            var arr1 = [...JSON.parse(this.baseState)]
            arr1 = arr1.filter(function(item){
              return item.selected_vendor_type === 'Supplier';
            })
            if(arr1.length === 0){
              this.setState({
                  vendors: [...JSON.parse(this.baseState)]
              })
            }
            else{
              this.setState({
                vendors: arr1,
              })
            }
          })
       }
       else if(name === 'job_work' & checked === true){
        this.setState({
              supplier : false,
              job_work : true
          },()=>{
              var arr1 = [...JSON.parse(this.baseState)]
              arr1 = arr1.filter(function(item){
                return item.selected_vendor_type === 'Job Work';
              })
              if(arr1.length === 0){
                this.setState({
                    vendors: [...JSON.parse(this.baseState)]
                })
              }
              else{
                this.setState({
                  vendors: arr1,
                })
              }
          })
        }
       else if(checked === false){
          this.setState({
             [name] : false,
             vendors: [...JSON.parse(this.baseState)]
          })
       }
    }
    handleInput = (childData) => {
      const {name,value} =childData
      var arr = [...JSON.parse(this.baseState)]
      arr = arr.filter(function(item){
      return item.vendor_name.toLowerCase().search(
          value.toLowerCase()) !== -1;
      })
      if(arr.length === 0){
        this.setState({
            vendors: [...JSON.parse(this.baseState)],
        })
         
      }
      else{
        this.setState({vendors: arr})
      }
      
      this.setState({
          [name] : value
      })
  }
    render(){
        return(
            <div className='row' id="all-vendors">
                 <div className='col-12 col-sm-2' id="all-vendors-left">
                    <div className="p-3" id="vendor-filter-col">
                       <p className="filter-label">Vendor Type</p>
                          <label className="custom-checkbox">Supplier
                              <input type="checkbox" onChange={this.toggleChange} name='supplier' defaultChecked={this.state.supplier} checked={this.state.supplier}/>
                              <span className="checkmark"></span>
                          </label>
                          <label className="custom-checkbox">Job Work
                              <input type="checkbox" onChange={this.toggleChange} name='job_work' defaultChecked={this.state.job_work} checked={this.state.job_work}/>
                              <span className="checkmark"></span>
                          </label>
                    </div>
                 </div>
                 <div className='col-12 col-sm-10 mt-3' id="all-vendors-right">
                     {this.renderTab()}
                </div>
            </div>
        );
    }
}

export default Vendor;