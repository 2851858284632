import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from "axios";
import {Url} from './UrlComponent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class CommonInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventory_arr :[],
            bin_loc_array : [],
            selected_bin_loc : '',
            bin_type_array : [{type : 'Primary',checked : false}, {type : 'Overflow', checked : false}],
            sku_type_array : [{type : 'SKU', checked : false}, {type : 'MSKU', checked : false}]
         }
         this.filterList = this.filterList.bind(this)
         this.toggleChange = this.toggleChange.bind(this)
         this.handleInputChange = this.handleInputChange.bind(this)
    }
    
    componentDidMount(){
      axios({
        method: 'get',
        url: Url+'/inventory/readAll.php',
        headers: { 'content-type': 'application/json' },
        })
        .then(result => {
            var arr = []
            var sku_arr = []
            var msku_arr = []
            for(let i=0; i<result.data.length; i++){
                if(result.data[i].sku_type === 'SKU'){
                    sku_arr.push(result.data[i].sku)
                }
                else if(result.data[i].sku_type === 'MSKU'){
                    msku_arr.push(result.data[i].sku)
                }
            }
            const form_data = {
                "msku_arr" : msku_arr,
                "sku_arr" : sku_arr
            }
            console.log(form_data)
            
            axios({
                method: 'post',
                url: Url+'/inventory/readInvImages.php',
                headers: { 'content-type': 'application/json' },
                data : form_data
            }).then(res=>{
                // for(var i=0; i<result.data.length; i++){
                //     arr.push({
                //         sku : result.data[i].sku,
                //         sku_type : result.data[i].sku_type,
                //         bin_location : result.data[i].bin_location,
                //         bin_type : result.data[i].bin_type,
                //         inventory : result.data[i].inventory,
                //         capacity : result.data[i].capacity,
                //     })
                // }
                // console.log(res.data)
            })
            
            this.setState({
                inventory_arr : arr
            })
            this.baseState =  JSON.stringify(this.state.inventory_arr)
            this.baseState2 =  JSON.stringify(this.state.inventory_arr)
        })
        .catch(error => this.setState({ error: error.message }))
        
        axios({
            method: 'get',
            url: Url+'/inventory/readBinLoc.php',
            headers: { 'content-type': 'application/json' },
        }).then(res=>{
            var arr = []
            for(let i=0; i < res.data.length; i++){
                arr.push(res.data[i])
            }
            this.setState({
                bin_loc_array : arr
            })
        })
    }
    filterList(event){
        var arr = [...JSON.parse(this.baseState)]
        arr = arr.filter(function(item){
        return item.sku.toLowerCase().search(
            event.target.value.toLowerCase()) !== -1;
        });
        this.setState({inventory_arr: arr});
    }
    toggleChange = (item, type) => event =>{
        var inventory_arr = [...JSON.parse(this.baseState2)]
        var temp_arr = []
        var temp2_arr = []
        var flag = 0
        if(type === 'bin-type'){
            var bin_type_array = this.state.bin_type_array
            bin_type_array.map(function(item2,key){
                if(item == item2){
                    item2.checked = !item2.checked
                }
            })
            this.setState({
                bin_type_array
            })
            bin_type_array.map(function(item2){
                if(item2.checked == true){
                    temp_arr = inventory_arr.filter(inventory => inventory.bin_type === item2.type)
                    temp2_arr = [...temp2_arr,...temp_arr]
                    flag = 1 
                }
            })
        }
        else if(type === 'sku-type'){
            var sku_type_array = this.state.sku_type_array
            sku_type_array.map(function(item2,key){
                if(item == item2){
                    item2.checked = !item2.checked
                }
            })
            this.setState({
                sku_type_array
            })
            sku_type_array.map(function(item2){
                if(item2.checked == true){
                    temp_arr = inventory_arr.filter(inventory => inventory.sku_type === item2.type)
                    temp2_arr = [...temp2_arr,...temp_arr]
                    flag = 1 
                }
            })
        }
        
        if(flag === 0){
            this.setState({
                inventory_arr : [...JSON.parse(this.baseState)]
            })
            this.baseState2 = [...JSON.parse(this.baseState)]
        }
        else{
            this.setState({
                inventory_arr : temp2_arr
            })
            this.baseState2 =  JSON.stringify(this.state.inventory_arr)
        }
        
    }
    handleInputChange = (event)=>{

    }
    render(){
            return(
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-2 mt-3">
                            <p><strong>Type</strong></p>
                            {this.state.sku_type_array.map((item, key)=>(<React.Fragment><input type="checkbox" onChange={this.toggleChange(item,'sku-type')} defaultChecked={item.checked}/>&nbsp; {item.type}<br/></React.Fragment>))}                            
                            <br/>
                            <p><strong>Bin Type</strong></p>
                            {this.state.bin_type_array.map((item, key)=>(<React.Fragment><input type="checkbox" onChange={this.toggleChange(item,'bin-type')} defaultChecked={item.checked}/>&nbsp; {item.type}<br/></React.Fragment>))}
                            <br/>
                            <p><strong>Bin Location</strong></p>
                            <Select value={this.state.selected_bin_loc} name="selected_bin_loc" onChange={this.handleInputChange}>
                                  {this.state.bin_loc_array.map(function(item, key){  return (<MenuItem value={item}>{item}</MenuItem>)})}
                            </Select>
                        </div>
                        <div className="col-12 col-sm-10" id="inventory-div">
                            <div className="row mt-3 pl-5">
                                <div className="col-12 col-sm-3"><input type="text" placeholder="Search" onChange={this.filterList} id="search-bar"/></div>
                            </div>
                            <div className="row m-1 mt-2" id="inventory-table">
                                <Table borderless>
                                    <thead>
                                        <tr>
                                            <td><strong>Image</strong> </td>
                                            <td><strong>SKU/MSKU</strong> </td>
                                            <td><strong>SKU Type</strong></td>
                                            <td><strong>Bin Location</strong></td>
                                            <td><strong>Bin Type</strong></td>
                                            <td><strong>Capacity</strong></td>
                                            <td><strong>Inventory</strong> </td>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            {this.state.inventory_arr.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td></td>
                                                    <td>{item.sku}</td>
                                                    <td>{item.sku_type}</td>
                                                    <td>{item.bin_location}</td>
                                                    <td>{item.bin_type}</td>
                                                    <td>{item.capacity}</td>
                                                    <td>{item.inventory}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                </Table>
                            </div>
                        </div> 
                     </div>
                </div>              
                       
            );
    }
}

export default CommonInventory;