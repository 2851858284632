import React, { Component } from 'react';
import axios, { post } from "axios";
import { Url } from './UrlComponent';
import ReactDataGrid from "react-data-grid";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import { CSVLink } from "react-csv";

class UploadSales extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            rows: [],
            cols: [],
            excel:'',
        }
        this.fileHandler = this.fileHandler.bind(this)
        this.submitData = this.submitData.bind(this)
    }
    fileHandler = (event) => {
        let fileObj = event.target.files[0]
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err)           
          }
          else{
              var rows = resp.rows
              var new_row = []
              for(let i=1; i<rows.length; i++){
                  new_row.push({
                      color_sku : rows[i][0],
                      title : rows[i][1],
                      setup_date : rows[i][2],
                      ship_date : rows[i][3],
                      size : rows[i][4],
                      order_quantity : rows[i][5],
                      cut_quantity : rows[i][6],
                      fabrication_quantity : rows[i][7],
                      packing_quantity : rows[i][8],
                      status : rows[i][9],
                  })
              }
            this.setState({
              cols: resp.cols,
              rows: new_row,
              excel: 'sales'
            })
          }
        })      
    }
    submitData(){
        var arr = this.state.rows
        var order_array = []
        var sales_array = []
        var total_quantity = 0
        var total_amount = 0
         for(var i=1; i<arr.length; i++){
            if(arr[i][0] !== undefined && arr[i+1][0]){
               total_quantity = arr[i][6]
               total_amount = total_quantity * arr[i][7]
            }
            else{
               total_quantity +=arr[i][6]
               total_amount +=arr[i][6]*arr[i][7]
            }
            console.log(total_amount)
            console.log(total_quantity) 
        }
        // order_array({
                //    name : arr[i][1],
                //    address : arr[i][2],
                //    platform : arr[i][3],
                // })
    }
    render(){
        const defaultColumnProperties = {
            resizable: true,
            width: 120
        }
        const column = [
            ["Channel", "Name", "Address", "Platform", "Date", "SKU", "Quantity", "Rate"]]
        const csvData = [
            { key: "color_sku", name: "Color SKU", editable: true },
            { key: "title", name: "Remarks", editable: true },
            { key: "setup_date", name: "Setup Date", editable: true },
            { key: "ship_date", name: "Ship Date", editable: true },
            { key: "size", name: "Size", editable: true },
            { key: "order_quantity", name: "Order Quantity", editable: true },
            { key: "cut_quantity", name: "Cut Quantity", editable: true },
            { key: "fabrication_quantity", name: "Fabrication Quantity", editable: true },
            { key: "packing_quantity", name: "Packing Quantity", editable: true },
            { key: "status", name: "Status", editable: true },
            ].map(c => ({ ...c, ...defaultColumnProperties }))
        return(
            <div>
                <CSVLink data={column}>Download Sample CSV file </CSVLink><input type="file" onChange={this.fileHandler} style={{"padding":"10px"}} placeholder="Upload Excel"/>
                    {this.state.excel == 'sales' ?<ReactDataGrid columns={csvData}  rowGetter={i => this.state.rows[i]}  rowsCount={ this.state.rows.length} 
                    enableCellSelect={true} minHeight={500} onColumnResize /> : null}
            </div>
        )
    }
}

export default UploadSales